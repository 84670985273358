import {
  LABELED_X_AXIS_HEIGHT,
  LABELED_Y_AXIS_WIDTH,
  LEGEND_HEIGHT,
  X_AXIS_LABEL_OFFSET,
  Y_AXIS_LABEL_OFFSET,
} from 'constants/charts';

import { Box } from '@mui/material';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { Dimensions, ChartAxisLabels } from 'types/common/charts';

import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';
import { tickFormatter } from '../helpers/tick-formatter';
import { chartStyles } from '../styles';

export interface LineStyle {
  strokeDasharray?: string;
  strokeWidth?: number;
}

interface Props<T> {
  data: T[];
  legend?: string[];
  lineDataKey: string;
  lineDataKeyNextItem?: string;
  secondLineDataKey?: string;
  barDataKey?: string;
  secondBarDataKey?: string;
  xDataKey: string;
  labels?: ChartAxisLabels;
  dimensions: Dimensions;
  tooltipBeforeSymbol?: string | string[];
  tooltipAfterSymbol?: string | string[];
  formatDate?: boolean;
  barSize?: number;
  customColors?: string[];
  lineStyle?: LineStyle;
  rightYAxisKey?: string;
  rightBarYAxisKey?: string;
  hideLegend?: boolean;
}

const defaultColors = ['#00B2FF', '#F7B500', '#6E759F', '#dea300'];

export function BarLineChart<T extends Record<string, any>>({
  data,
  legend,
  xDataKey,
  dimensions,
  labels = {},
  lineDataKey,
  lineDataKeyNextItem,
  secondLineDataKey,
  barDataKey,
  secondBarDataKey,
  tooltipBeforeSymbol,
  tooltipAfterSymbol,
  formatDate,
  barSize = 20,
  customColors,
  lineStyle = { strokeDasharray: '4', strokeWidth: 3 },
  rightYAxisKey,
  rightBarYAxisKey,
  hideLegend,
}: Props<T>) {
  const colors = customColors ? customColors : defaultColors;
  const mapPayload = legend?.map((name, index) => ({
    value: name,
    color: colors[index],
  }));
  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ResponsiveContainer width="98%" aspect={0}>
        <ComposedChart data={data}>
          <XAxis
            dataKey={xDataKey}
            height={labels.x ? LABELED_X_AXIS_HEIGHT : undefined}
            tickLine={false}
            tickFormatter={formatDate && tickFormatter}
          >
            {labels.x && (
              <Label
                value={labels.x}
                position="insideBottom"
                offset={X_AXIS_LABEL_OFFSET}
                style={chartStyles.xAxisLabel}
              />
            )}
          </XAxis>

          <YAxis axisLine={false} width={labels.y ? LABELED_Y_AXIS_WIDTH : undefined} tickLine={false}>
            {labels.y && (
              <Label
                value={labels.y}
                position="insideLeft"
                angle={-90}
                offset={Y_AXIS_LABEL_OFFSET}
                style={chartStyles.yAxisLabel}
              />
            )}
          </YAxis>

          {rightYAxisKey ? (
            <YAxis yAxisId="right" orientation="right" axisLine={{ stroke: colors[3] }} tick={{ fill: colors[3] }} />
          ) : (
            ''
          )}

          {rightBarYAxisKey ? (
            <YAxis yAxisId="right" orientation="right" axisLine={{ stroke: colors[3] }} tick={{ fill: colors[3] }} />
          ) : (
            ''
          )}

          <Tooltip
            isAnimationActive={false}
            content={props => (
              <CustomTooltip
                {...props}
                customPayload={mapPayload}
                tooltipBeforeSymbol={tooltipBeforeSymbol}
                tooltipAfterSymbol={tooltipAfterSymbol}
                showDate={false}
              />
            )}
          />

          {!hideLegend ? (
            <Legend
              payload={mapPayload} //.filter(entry => !excludeLegendValues.includes(entry.value))}
              verticalAlign="top"
              align="center"
              height={LEGEND_HEIGHT}
              iconType="circle"
              formatter={!mapPayload?.length && legendFormatter()}
            />
          ) : (
            ''
          )}

          <Bar dataKey={barDataKey} barSize={barSize} fill={colors[0]} />
          {secondBarDataKey && <Bar dataKey={secondBarDataKey} barSize={barSize} fill={colors[2]} />}
          <Line
            type="linear"
            dataKey={lineDataKey}
            stroke={colors[1]}
            dot={false}
            strokeWidth={lineStyle.strokeWidth}
            //strokeDasharray={lineStyle.strokeDasharray}
            strokeDasharray={undefined}
          />
          {lineDataKeyNextItem ? (
            <Line type="monotone" dataKey={lineDataKeyNextItem} stroke={colors[1]} dot={false} strokeWidth={4} />
          ) : (
            ''
          )}
          {secondLineDataKey && (
            <Line
              type="monotone"
              dataKey={secondLineDataKey}
              stroke={colors[2]}
              dot={false}
              strokeWidth={lineStyle.strokeWidth}
              strokeDasharray={undefined}
              //strokeDasharray={lineStyle.strokeDasharray}
            />
          )}

          {rightYAxisKey ? (
            <Line yAxisId="right" name={rightYAxisKey} type="monotone" dataKey={rightYAxisKey} stroke={colors[3]} />
          ) : (
            ''
          )}

          {rightBarYAxisKey ? (
            <Bar dataKey={rightBarYAxisKey} barSize={barSize} fill={colors[0]} yAxisId="right" />
          ) : (
            ''
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

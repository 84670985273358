import { onboardingApi, vennFlowApi } from 'api/api';
import { Response } from 'types/api/api';
import {
  AddUserRequest,
  UploadRequest,
  AllBrandInfo,
  Company,
  GetUsersByCompanyIdResult,
  LeadMappingsPayload,
} from 'types/companies/companies';

const getCompany = (companyId: string) => onboardingApi.get<Response<Company>>(`/Companies/${companyId}`);

const updateCompany = (companyData: Company) => onboardingApi.post<Response<string>>('/Companies', companyData);

const saveLead = (leadData: LeadMappingsPayload) =>
  vennFlowApi.post<Response<string>>('/GoogleAnalyticsLeads/SaveGA4LeadMappings', leadData);

const getAllBrandInfo = (brandId: string) =>
  onboardingApi.get<Response<AllBrandInfo[]>>(`ClientSystemAccess/get-all-by-client-brand-id/${brandId}`);

const getUsersByCompanyId = (companyId: string) =>
  onboardingApi.get<Response<GetUsersByCompanyIdResult>>(`Companies/GetUsers/${companyId}`);

const addUser = (companyId: string, data: AddUserRequest) =>
  onboardingApi.post(`Companies/AddNewUser/${companyId}`, data);

const UploadCSV = (data: UploadRequest) =>
  //  onboardingApi.post(`${data.clientId}/${data.brandId}/targets/${data.STOREIDENTIFIER}`, data);
  onboardingApi.post('Companies/UploadTargetCSV', data);

//Companies/UploadTargetCSV
//

const deleteUser = (companyId: string, userId: string) =>
  onboardingApi.delete(`Companies/DeleteUser/${companyId}/${userId}`);

const getA4Leads = (ClientId: string, BrandId: string, StoreId: string, storeIdentifier: string) =>
  vennFlowApi.get<Response<AllBrandInfo[]>>(
    `GoogleAnalyticsLeads/GA4Leads?ClientId=${ClientId}&BrandId=${BrandId}&storeIdentifier=${storeIdentifier}`,
  );

export const companiesApi = {
  getCompany,
  updateCompany,
  getAllBrandInfo,
  getUsersByCompanyId,
  addUser,
  deleteUser,
  UploadCSV,
  getA4Leads,
  saveLead,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverviewData } from 'types/overview/overview';

import { OverviewSlice } from './types';

const initialState: OverviewSlice = {
  overviewData: null,
  loading: true,
};

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    processGetOverviewData: state => {
      state.loading = true;
    },
    getOverviewDataSuccess: (state, action: PayloadAction<OverviewData>) => {
      state.overviewData = action.payload;
      state.loading = false;
    },
    getOverviewDataFailure: state => {
      state.loading = false;
    },
  },
});

import { onboardingApi, vennFlowApi } from 'api/api';
import { usersApi } from 'api/users';
import jwtDecode from 'jwt-decode';
import { AppDispatch } from 'store/store';
import { UserFromToken, ChangePasswordRequest } from 'types/users/users';

import { userSlice } from './slice';

export const initialize = (accessToken: string) => async (dispatch: AppDispatch) => {
  vennFlowApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  onboardingApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  try {
    const { UserId }: UserFromToken = jwtDecode(accessToken);
    const { data: user } = await usersApi.getUser(UserId);

    vennFlowApi.defaults.params = { clientId: user.clientIdData };

    dispatch(userSlice.actions.initializeSuccess({ user, accessToken }));
  } catch (error) {
    dispatch(userSlice.actions.initializeFailed());
  }
};

export const changePassword =
  (body: ChangePasswordRequest, onSuccess: () => void, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.processChangePassword());
    try {
      await usersApi.changePassword(body);

      dispatch(userSlice.actions.changePasswordSuccess());
      onSuccess();
    } catch (error) {
      dispatch(userSlice.actions.changePasswordFailed());
      onFailure();
    }
  };

export const getSystemAccessIdByClientId = (clientId: string) => async () => {
  const { data } = await usersApi.getSystemAccessIdByClientId(clientId);

  return data.result;
};

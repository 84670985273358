import {
  LABELED_X_AXIS_HEIGHT,
  LABELED_Y_AXIS_WIDTH,
  LEGEND_HEIGHT,
  X_AXIS_LABEL_OFFSET,
  Y_AXIS_LABEL_OFFSET,
} from 'constants/charts';

import './bubble-chart.css';

import { Box } from '@mui/material';
import { FC } from 'react';
import {
  ScatterChart as REScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  Legend,
} from 'recharts';
import { ChartAxisLabels, Dimensions } from 'types/common/charts';
import { CampaignsBubbleChartData } from 'types/marketing-analyzer/marketing-analyzer';

import { mapBubbleChartData } from './helpers/adapters';
import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';
import { chartStyles } from '../styles';

export interface BubbleChartClickEvent {
  payload: CampaignsBubbleChartData;
}

interface Props {
  data: CampaignsBubbleChartData[];
  xDataKey: string;
  yDataKey: string;
  zDataKey: string;
  labels?: ChartAxisLabels;
  dimensions: Dimensions;
  onClick?: (id: string, name:string) => void;
  legend?: string[];
}
const defaultColors = ['#00B2FF', '#F7B500', '#6E759F', '#dea300'];

export const BubbleChart: FC<Props> = ({ data, xDataKey, yDataKey, zDataKey, labels = {}, dimensions, onClick, legend }) => {
  const readyData = mapBubbleChartData(data);

  const handleBubbleClick = (data: CampaignsBubbleChartData) => {
    onClick(data.id, data.name);
  };
  
  const mapPayload = legend?.map((name, index) => ({
    value: name,
    color: defaultColors[index],
  }));

  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ResponsiveContainer width="100%">
        <REScatterChart>
          <CartesianGrid stroke="#E2E7E7" strokeDasharray="5 10" />
          <XAxis
            dataKey={xDataKey}
            name={xDataKey}
            type="number"
            height={labels.x ? LABELED_X_AXIS_HEIGHT : undefined}
            tickLine={false}
          >
            {labels.x && (
              <Label
                value={labels.x}
                position="insideBottom"
                offset={X_AXIS_LABEL_OFFSET}
                style={chartStyles.xAxisLabel}
              />
            )}
          </XAxis>
          <YAxis
            dataKey={yDataKey}
            name={yDataKey}
            type="number"
            width={labels.y ? LABELED_Y_AXIS_WIDTH : undefined}
            axisLine={false}
            tickLine={false}
          >
            {labels.y && (
              <Label
                value={labels.y}
                position="insideLeft"
                angle={-90}
                offset={Y_AXIS_LABEL_OFFSET}
                style={chartStyles.yAxisLabel}
              />
            )}
          </YAxis>
          <ZAxis dataKey={zDataKey} name={zDataKey} type="number" range={[300, 1500]} />
          <Legend
            verticalAlign="top"
            align="center"
            height={LEGEND_HEIGHT}
            iconType="circle"
            formatter={legendFormatter()}
          />
          <Tooltip
            cursor={{ strokeDasharray: '5 10' }}
            isAnimationActive={false}
            content={props => <CustomTooltip {...props} customPayload={mapPayload} showDate/>}
          />
          {readyData.map(d => (
            <Scatter className='dataPoint' key={d.name} name={d.name} data={d.data} fill={d.fill} onClick={e => handleBubbleClick(e) }/>
          ))}
        </REScatterChart>
      </ResponsiveContainer>
    </Box>
  );
};

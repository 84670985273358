import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EcommDashboardResponse } from 'types/shopify/shopify';

import { ShopifySlice } from './types';

const initialState: ShopifySlice = {
  dashboardData: null,
  loading: true,

};

export const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: { processGetOverviewData: state => {
    state.loading = true;
  },
  getOverviewDataSuccess: (state, action: PayloadAction<EcommDashboardResponse>) => {
    state.dashboardData = action.payload;
    state.loading = false;
  },
  getOverviewDataFailure: state => {
    state.loading = false;
  },},
});



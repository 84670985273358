import { vennFlowApi } from 'api/api';
import { Product, ProfitabilityResponse } from 'types/profitability/profitability';

const getProduct = (clientId: string) => vennFlowApi.get(`/Products/Products?ClientId=${clientId}`);

const getBreakeven = (Product: string, fromDate: string, toDate: string, clientId: string) =>
  vennFlowApi.get<{ product: ProfitabilityResponse }>(
    `/Breakeven/GetBreakeven?Product=${Product}&FromDate=${fromDate}&ToDate=${toDate}&clientId=${clientId}`,
  );

export const profitabilityApi = {
  getProduct,
  getBreakeven,
};

//Breakeven/GetBreakeven

import { Button, Card, Grid, MenuItem } from '@mui/material';
import { Select } from 'components/select';
import { useField, useFormikContext } from 'formik';
import { useSelector } from 'hooks';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { dashboardsSelectors } from 'store/entities/dashboards';
import { marketingAnalyzerSelectors } from 'store/entities/marketing-analyzer';
import { revenueSpendSelectors } from 'store/entities/revenue-spend';
import { userSelectors } from 'store/entities/user';

import { revenueSpendStyles as S } from '../../style';

interface Props {
  onClick: (name: string) => void;
}
export const TiktokFiltersClicks: FC<Props> = ({ onClick }) => {
  const { submitForm, values } = useFormikContext();
  const [{ value }] = useField('markets');
  const markets = useSelector(marketingAnalyzerSelectors.markets);
  const brands = useSelector(marketingAnalyzerSelectors.brands);
  const navigate = useNavigate();

  const newbrands = [
    {
      id: 1,
      name: 'Impressions',
    },
    {
      id: 2,
      name: 'Clicks',
    },
    {
      id: 3,
      name: 'Ecommerce value',
    },
    {
      id: 4,
      name: 'Ad Spend',
    },
    {
      id: 5,
      name: 'Conversion',
    },
  ];

  // const redirect = (name: string) => {
  //   navigate(`/tiktok-dashboard/${name}`);
  // };
  return (
    <Card>
      <S.FiltersGroup p={2.5} display="flex">
        <Grid container display="flex" justifyContent="space-between" spacing={2} xs={12}>
          <Grid
            sx={{ gridColumnGap: '8px' }}
            style={{ paddingBottom: '0px', paddingTop: '10px' }}
            item
            xs={2}
            display="flex"
          >
            <Select name="markets" label="Clicks" full >
              {newbrands.map(brand => (
                <MenuItem key={brand.id} value={brand.id} onClick={() => onClick(brand.name)}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

        
        </Grid>
      </S.FiltersGroup>
    </Card>
  );
};


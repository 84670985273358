import { UserState } from './types';

const user = (state: UserState) => state.user.user;

const clientIdData = (state: UserState) => state.user.user.clientIdData;

const accessToken = (state: UserState) => state.user.accessToken;

const isInitialized = (state: UserState) => state.user.isInitialized;

const isAuthenticated = (state: UserState) => state.user.isAuthenticated;

const isMarketingOnly = (state: UserState) => state.user.isMarketingOnly;

const changePasswordLoading = (state: UserState) => state.user.changePasswordLoading;

export const userSelectors = {
  user,
  clientIdData,
  isInitialized,
  isAuthenticated,
  isMarketingOnly,
  accessToken,
  changePasswordLoading,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketingOverviewData, Overview, OverviewDetail } from 'types/marketing/overview';

import { MarketingOnlyDetailSlice, MarketingOverviewSlice } from './types';

const initialState: MarketingOverviewSlice = {
  overviewData: null,
  loading: true,
  periodTiles: null,
};

const initialStateDetail: MarketingOnlyDetailSlice = {
  overviewDetailData: null,
  loading: true,
};



export const marketingoverviewSlice = createSlice({
  name: 'marketing-overview',
  initialState,
  reducers: {
    processGetOverviewData: state => {
      state.loading = true;
    },
    getOverviewDataSuccess: (state, action: PayloadAction<Overview>) => {
      state.overviewData = action.payload;
      state.loading = false;
    },
    getOverviewDataFailure: state => {
      state.loading = false;
    },
  },
});

// GetMarketingOnlyDetail code
export const marketingonlydetailSlice = createSlice({
  name: 'marketing-only-detail',
  initialState: initialStateDetail,
  reducers: {
    processGetDetailData: state => {
      state.loading = true;
    },
    getDetailDataSuccess: (state, action: PayloadAction<OverviewDetail>) => {
      state.overviewDetailData = action.payload;
      state.loading = false;
    },
    getDetailDataFailure: state => {
      state.loading = false;
    },
  },
});
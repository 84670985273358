import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { AppInit } from 'components/app-init';
import { useDispatch, useSelector } from 'hooks';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'router';
import { store } from 'store';
import { initialize, userSelectors, userSlice } from 'store/entities/user';
import ThemeProviderWrapper from 'theme/ThemeProvider';

const AppContent = () => {
  const dispatch = useDispatch();

  const isInitialized = useSelector(userSelectors.isInitialized);
  const accessToken = useSelector(userSelectors.accessToken);

  useEffect(() => {
    if (accessToken) {
      dispatch(initialize(accessToken));
    } else {
      dispatch(userSlice.actions.initializeFailed());
    }
  }, []);

  return isInitialized ? <Router /> : <AppInit />;
};

export const App = () => (
  <SnackbarProvider>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate persistor={persistStore(store)}>
          <BrowserRouter>
            <ThemeProviderWrapper>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                <AppContent />
              </LocalizationProvider>
            </ThemeProviderWrapper>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </SnackbarProvider>
);

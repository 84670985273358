import { Typography, TableRow, TableCell } from '@mui/material';
import { data } from '@shopify/app-bridge/actions/Modal';
import { calculateTotal, CompareTableProgressCell } from 'components/compare-table';
import { FC } from 'react';
import { TiktokLeadTableData } from 'types/Tiktok/Tiktok';
import { formatNumber } from 'utils/numbers';

interface Props {
  currencyLabel:string;
  data: TiktokLeadTableData[];
}

const gettotal = (key: string, obj: any) => {
  const dt = obj;

  let sum = 0;
  for (let i = 0; i < dt.length; i++) {
    sum += obj[i][key].value;
  }
  return sum;
};

//  const redirect = (name: string) => {
//     navigate(`/tiktok-dashboard/${name}`);
//    };
export const TiktokTableTotalRow: FC<Props> = ({currencyLabel, data }) => (
  <TableRow hover >
    <TableCell>
      <Typography noWrap variant="h5">
        Total
      </Typography>
    </TableCell>

    <CompareTableProgressCell 
    currencyLabel={currencyLabel}
    sign='currency'
    value={formatNumber(gettotal('ad_cost', data))} label={''} />

    <CompareTableProgressCell value={formatNumber(gettotal('conversions', data))} label={''} />
    <CompareTableProgressCell value={formatNumber(gettotal('video_views', data))} label={''} />
    <CompareTableProgressCell value={formatNumber(gettotal('video_play_actions', data))} label={''} />

    <CompareTableProgressCell value={formatNumber(gettotal('comments', data))} label={''} />
  </TableRow>
);

import { ThemeProvider } from '@mui/material';
import React, { useState } from 'react';

import { themeCreator } from './base';
// import {StylesProvider} from "@mui/system";

export const ThemeContext = React.createContext((_themeName: string): void => {});

const ThemeProviderWrapper = (props: any) => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    // <StylesProvider injectFirst>
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
    // </StylesProvider>
  );
};

export default ThemeProviderWrapper;

import { vennFlowApi, onboardingApi } from 'api/api';
import {
  CLientLoadStateData,
  GetMarketingOnlyDetailRequest,
  GetMarketingOverviewRequest,
  MarketingOverviewData,
  Overview,
  OverviewDetail,
  PyramidUserMapper,
  loadDataRequest,
} from 'types/marketing/overview';

const getMarketingOverviewData = (params: GetMarketingOverviewRequest) =>
  vennFlowApi.get<{ marketing_overview: Overview }>('/MarketingOnlyOverview/marketingOnlyOverview', { params });

// GetMarketingOnlyDetail code
const getMarketingOnlyDetailData = (params: GetMarketingOnlyDetailRequest) =>
  vennFlowApi.get<{ marketing_detail: OverviewDetail }>('/MarketingOnlyDetailView/DetailView', { params });

const getPriorityLandingPageData = (params: GetMarketingOverviewRequest) =>
  vennFlowApi.get<{ marketing_overview: Overview }>('/MarketingOnlyOverview/priorityLandingPageData', { params });

//  vennFlowApi.get<{ overview: MarketingOverviewData }>('/MarketingOnlyOverview/marketingOnlyOverview', { params });

//Overview

const getCLientloadStateData = (params: loadDataRequest) =>
  onboardingApi.get<{ result: CLientLoadStateData }>('/Companies/GetClientLoadState', { params });

const getPyramidUserData = (userId: string) =>
  onboardingApi.get<{ result: PyramidUserMapper[] }>(`/clientMapper/GetPyramidUserMapperByUserId/${userId}`);

//CLientLoadState
export const marketingoverviewApi = {
  getMarketingOverviewData,
  getCLientloadStateData,
  getPriorityLandingPageData,
  getPyramidUserData,
};

export const marketingonlydetailApi = {
  getMarketingOnlyDetailData,
};

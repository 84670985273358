import { Box, styled, Typography } from '@mui/material';

const ProgressGroup = styled(Box)`
  position: relative;
  flex-grow: 1;
  width: 70%;
  margin-right: 10px;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
`;

const ProgressBar = styled(Box)`
  height: 1.5rem;
  padding: 3px 5px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: 0px 5px 5px 0px;
`;

const CustomColourProgressBar = styled(Box)`
  height: 1.5rem;
  padding: 3px 5px;
  border-radius: 0px 5px 5px 0px;
`;

const ProgressLabel = styled(Typography)`
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 1.75;
`;

export const compareTableStyles = {
  ProgressGroup,
  ProgressBar,
  ProgressLabel,
  CustomColourProgressBar
};

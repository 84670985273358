import { vennFlowApi } from 'api/api';
import { GetShoppingJourneyRequest, ShoppingJourney, ShoppingJourneyFilter } from 'types/shopping-journey/shopping-journey';

// const getShoppingJourney = (params: GetShoppingJourneyRequest) =>
//   vennFlowApi.get<ShoppingJourney>('/ShoppingJourney/shopping', { params });

const shoppingJourneyGet = (params: GetShoppingJourneyRequest) =>
  vennFlowApi.get<ShoppingJourney>('/ShoppingJourney/GetShoppingJourney', { params });

const shoppingJourneyFilterGet = (params: GetShoppingJourneyRequest) =>
  vennFlowApi.get<ShoppingJourneyFilter>('/ShoppingJourney/GetShoppingJourneyFilter', { params });

export const shoppingJourneyApi = {
  shoppingJourneyGet,
  shoppingJourneyFilterGet
};

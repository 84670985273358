// import { PublicRoute } from 'components/routes';
import { SuspenseLoader } from 'components/suspense-loader';
import { Suspense, lazy } from 'react';

import { routes } from './routes';

const Loader = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

// Account
// const Login = Loader(lazy(() => import('../pages/auth/Login')));
const AuthRedirect = Loader(lazy(() => import('../pages/auth/auth-redirect')));

export const accountRoutes = [
  // {
  //   path: routes.account.login.url,
  //   element: (
  //     <PublicRoute>
  //       <Login />
  //     </PublicRoute>
  //   ),
  // },
  {
    path: routes.account.auth.url,
    element: <AuthRedirect />,
  },
];

import { Box } from '@mui/material';
import { FC } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { Dimensions } from 'types/common/charts';

import { GeoCircle } from './components/geo-circle';
import geography from './helpers/geography.json';

interface Props<T> {
  data: T[];
  dimensions: Dimensions;
  hiddenLabels?: boolean;
}

export const GeoChart: FC<Props<Record<string, any>>> = ({ dimensions, data, hiddenLabels = false }) => {
  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ComposableMap projectionConfig={{ rotate: [-10, 0, 0] }}>
        <Geographies geography={geography}>
          {({ geographies }) =>
            geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} fill="#009AFD54" opacity={0.33} />)
          }
        </Geographies>
        {data.map(({ cityCode, lng, lat, population }) => (
          <Marker key={cityCode} coordinates={[lng, lat]}>
            <GeoCircle value={+population} hiddenLabel={hiddenLabels} />
          </Marker>
        ))}
      </ComposableMap>
    </Box>
  );
};

import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Tooltip,
  Typography,
  colors,
  getTooltipUtilityClass,
  styled,
} from '@mui/material';
import { Heading } from 'components/heading';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useToast } from 'hooks';
import { useEffect, useState, useMemo, CSSProperties } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { marketdashboardsSelectors, dashboardsSelectors, dashboardsSlice } from 'store/entities/dashboards';
import { getMarkets, getObjectives, marketingAnalyzerSelectors } from 'store/entities/marketing-analyzer';
import { getBreakeven, getProductData } from 'store/entities/overview/actions';
import { shoppingJourneySelectors } from 'store/entities/shoping-journey';
import { getShoppingJourney, shoppingJourneyFilterGet } from 'store/entities/shoping-journey/actions';
import { userSelectors } from 'store/entities/user';
import { Source } from 'types/shopping-journey/shopping-journey';
import { dateFormmating } from 'utils/dateFormatting';
import { getOptionsId } from 'utils/get-markets-id';
import { currencyLabel, formatNumber } from 'utils/numbers';

import { WeeklyOverviewFiltersForm } from './product-profitability-filters-form/product-profitability-filters-form';

import './style.css';

interface Product {
  id: string;
  name: string;
}

export default function ProductProfitability() {
  const initialFilters = useSelector(marketingAnalyzerSelectors.filters);
  const user = useSelector(userSelectors.user);

  const date = useSelector(marketdashboardsSelectors.date);
  const marketsValue = useSelector(dashboardsSelectors.markets);
  const dispatch = useDispatch();
  const { errorToast } = useToast();
  const { clientId } = useSelector(userSelectors.user);

  const [productsData, setProductsData] = useState<Product[]>([]);
  const marketList = useSelector(marketingAnalyzerSelectors.markets);
  const objectives = useSelector(marketingAnalyzerSelectors.objectives);
  const [isFirstLoad, setFirstLoad] = useState(true);
  const shoppingJourney = useSelector(shoppingJourneySelectors.shoppingJourney);
  const endDate = useSelector(dashboardsSelectors.endDate);
  const compareStartDate = useSelector(dashboardsSelectors.compareStartDate);
  const compareEndDate = useSelector(dashboardsSelectors.compareEndDate);
  const { brandList, brandStoreList } = useSelector(userSelectors.user);
  const [topMarketsSource, setTopMarketsSource] = useState<Source>('market');

  const isAllObjectives = (ids: string[]) => {
    //remove 1 from objectives because we ignore the all value
    return ids.length >= objectives.length - 1 ? true : false;
  };

  const form = useFormik({
    initialValues: {
      ...initialFilters,
      date,
      products: marketsValue,
      defaultProduct: {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'All',
      },
    },
    onSubmit: values => {},
  });

  const { values, setFieldValue } = form;

  const dedupeList = (arr: any[]) => {
    if (arr === null || arr === undefined) return [];

    const uniqueVals = arr
      // .map(i => i.productNameOriginal)
      .map((item, index) => ({
        id: item.productId,
        name: item.productNameOriginal,
      }))
      .filter((value, index, currentVal) => currentVal.indexOf(value) === index);

    return uniqueVals.sort();
  };

  const removeDuplicatesFromArray = (obj: Product[]): Product[] => {
    const uniqueArray: Product[] = [];
    const idTracker: Record<string, boolean> = {}; // Tracks unique ids
    const nameTracker: Record<string, number> = {}; // Tracks the count of each name

    obj.forEach(product => {
      if (!idTracker[product.id]) {
        idTracker[product.id] = true;
        let uniqueName = product.name;
        // Check if the name has been encountered before
        if (nameTracker[product.name]) {
          // If yes, append a number to make it unique
          const count = nameTracker[product.name] + 1;
          uniqueName = `${product.name} (${count})`;
        }
        nameTracker[product.name] = nameTracker[product.name] ? nameTracker[product.name] + 1 : 1;
        uniqueArray.push({ ...product, name: uniqueName });
      }
    });

    return uniqueArray;
  };

  useEffect(() => {
    dispatch(dashboardsSlice.actions.setDashboardsMarkets(values.markets));
  }, [values.markets]);

  // useEffect(() => {
  //   setFieldValue('products', {
  //     id: '00000000-0000-0000-0000-000000000000',
  //     name: 'All',
  //   });
  // }, [productsData]); // Empty dependency array means the effect runs once after the initial render

  useEffect(() => {
    dispatch(getMarkets());
    dispatch(getObjectives());
  }, []);

  useEffect(() => {
    if (marketList && !marketsValue.length) {
      setFieldValue(
        'products',
        marketList.filter(market => market.name === 'All'),
      );
    }
  }, [marketList]);

  useEffect(() => {
    if (objectives && !initialFilters.campaignObjectives.length)
      setFieldValue(
        'campaignObjectives',
        objectives.filter(objective => objective.name !== 'All').map(objective => objective.id),
      );
  }, [objectives, setFieldValue]);

  const applyClick = async (fromdate: Date, todate: Date, product: string) => {
    try {
      
      if(fromdate > todate) {
        errorToast('Start date is after end date!');
        return;
      }
      
      const allSelected = product == '' || product == null;
      
      const stringy = allSelected
        ? JSON.stringify([])
        : JSON.stringify(product);

      const json: Product[] = JSON.parse(stringy);
      const namesString = json.map(product => product.id).join(', ');

      product = namesString;
    } catch {
      console.log('err');
    }

    const breakeven: any = await dispatch(getBreakeven(product, dateFormmating(fromdate, true),  dateFormmating(todate), clientId, errorToast));
    
    if (breakeven?.total_profitability) {
      breakeven.total_profitability = [...breakeven.total_profitability].sort(
        (a, b) => a.metric_sort_order - b.metric_sort_order,
      );
      setTotalProfitability(breakeven.total_profitability);
    } else {
      setTotalProfitability([]);
    }
    if (breakeven?.avg_profitability) {
      breakeven.avg_profitability = [...breakeven.avg_profitability].sort(
        (a, b) => a.metric_sort_order - b.metric_sort_order,
      );
      setAvgProfitability(breakeven.avg_profitability);
    } else {
      setAvgProfitability([]);
    }
  };

  const rowColour = (val: string) => {
    switch (val.toLowerCase()) {
      case 'total revenue':
        return 'totalCostRow';
      case 'total costs':
        return 'totalCostRow';
      case 'calculated profit':
        return 'calculatedProfitRow';
      case 'mer':
        return 'merRow';
      case 'mer (break even)':
        return 'merBERow';
      case 'cpo':
        return 'cpoRow';
      case 'cpo (break even)':
        return 'cpoBERow';
      default:
        return '';
    }
  };

  const [totalProfitability, setTotalProfitability] = useState([]);
  const [avgProfitability, setAvgProfitability] = useState([]);
  // Sort total_profitability array based on metric_sort_order
  const sortedTotalProfitability = [...totalProfitability].sort((a, b) => a.metric_sort_order - b.metric_sort_order);

  const sortedAvgProfitability = [...avgProfitability].sort((a, b) => a.metric_sort_order - b.metric_sort_order);

  const ShouldShowCurrencySymbol = (val: string) => {
    const currencyWords: string[] = [
      'revenue',
      'cost',
      'costs',
      'profit',
      'cogs',
      'fees',
      'tax',
      'returns',
      'spend',
      'discounts',
      'sales',
    ];
    for (let i = 0; i < currencyWords.length; i++) {
      if (val.toLowerCase().includes(currencyWords[i])) return currencyLabel(user.brandStoreList[0].StoreCurrency);
    }
    return '';
  };

  const tooltip = (val: string) => {
    switch (val) {
      case 'MER':
        return 'Marketing Efficiency Ratio';
      case 'MER (Break Even)':
        return 'Marketing Efficiency Ratio';
      case 'CPO':
        return 'Cost per order is the average amount that your brand spends to drive any customer to purchase a product or service';
      case 'CPO (Break Even)':
        return 'Cost per order is the average amount that your brand spends to drive any customer to purchase a product or service';
      default:
        break;
    }
  };

  const toolTipView = (tooltip: string) => {
    if (tooltip === undefined || tooltip === '') {
      return <></>;
    } else {
      return (
        <Typography style={{ display: 'flex', alignSelf: 'flex-end', float: 'right' }}>
          <Tooltip title={tooltip}>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              <InfoIcon />
            </div>
          </Tooltip>
        </Typography>
      );
    }
  };

  const TotalValTable = () => {
    if (totalProfitability !== null && totalProfitability.length > 0) {
      return (
        <table className="mainTable">
          <thead>
            <tr style={{ borderBottom: '1px solid #c0c2c5' }}>
              <th>{''}</th>
              <th style={{ textAlign: 'center' }} className="subTitle">
                <h5>Financials</h5>
              </th>
              <th style={{ textAlign: 'center' }} className="subTitle">
                <h5>Cost %</h5>
              </th>
            </tr>
          </thead>
          <tbody id="totalProfTBody">
            {totalProfitability?.map((item, index) => (
              <tr key={index + 1} className={rowColour(item.metric_name)}>
                <td className="titleColumn">
                  <b style={{ float: 'left' }}>{`${item.metric_name}`}</b>
                  {toolTipView(tooltip(item.metric_name))}
                </td>
                <td className="valueColumn">{`${ShouldShowCurrencySymbol(item.metric_name)}${
                  item.metric_value == undefined ? '' : formatNumber(item.metric_value)
                }`}</td>
                <td className="valueColumn">{`${
                  item.cost_pct_total == undefined ? '-' : formatNumber(item.cost_pct_total) + '%'
                }`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <Grid style={{ padding: '2em', textAlign: 'center', alignContent: 'center' }}>
          <Typography variant="h4">No Data</Typography>
        </Grid>
      );
    }
  };

  const AvgTable = () => {
    if (avgProfitability !== null && avgProfitability.length > 0) {
      return (
        <table className="mainTable">
          <thead>
            <tr style={{ borderBottom: '1px solid #c0c2c5' }}>
              <th>{''}</th>
              <th style={{ textAlign: 'center' }} className="subTitle">
                <h5>Financials</h5>
              </th>
            </tr>
          </thead>
          <tbody id="avgProfTBody">
            {avgProfitability?.map(row => (
              <tr key={row.metric_name} className={rowColour(row.metric_name)}>
                <td className="titleColumnAvg">
                  <b style={{ float: 'left' }}>{`${row.metric_name}`}</b>
                  {toolTipView(tooltip(row.metric_name))}
                </td>
                <td className="valueColumnAvg">
                  {ShouldShowCurrencySymbol(row.metric_name)}
                  {formatNumber(row.avg_metric_value)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <Grid style={{ padding: '2em', textAlign: 'center', alignContent: 'center' }}>
          <Typography variant="h4">No Data</Typography>
        </Grid>
      );
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const product = await dispatch(getProductData(clientId, errorToast));

  //       // Do something with the fetched data if needed
  //     } catch (error) {
  //       // Handle errors here
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []); // Empty dependency array means the effect runs once after the initial render


  return (
    <>
      <Helmet>
        <title>Product Profitability</title>
      </Helmet>

      <FormikProvider value={form}>
        <Container maxWidth="xl" id="productprof-container">
          <Grid container direction="row" justifyContent="left" alignItems="start" spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h1">Product Profitability</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              {/* <WeeklyOverviewFiltersForm applyClick={applyClick} label="Day" /> */}
              <WeeklyOverviewFiltersForm applyClick={applyClick} label="Day" />
            </Grid>
            <Grid item lg={2} />
            <Grid item lg={5} xs={12}>
              <Card>
                <Box p={2.5} textAlign="center" style={{ padding: 0 }}>
                  <Typography variant="h3" style={{ backgroundColor: '#0a2435', color: 'white', padding: '.1em' }}>
                    Total Value
                  </Typography>
                </Box>

                <Divider />

                <TotalValTable />
              </Card>
            </Grid>

            <Grid item lg={3} xs={12}>
              <Card style={{ border: '1px solid #f0f1f6' }}>
                <Box p={2.5} textAlign="center" style={{ padding: 0 }}>
                  <Typography style={{ backgroundColor: '#0a2435', color: 'white', padding: '.1em' }} variant="h3">
                    Avg. Order Value
                  </Typography>
                </Box>
                <Divider />

                <AvgTable />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </FormikProvider>
    </>
  );
}

import { Response } from 'types/api/api';
import { GetCurrentPyramidResult, Pyramid, PyramidMenuItems } from 'types/pyramid/pyramid';

import { pyramidApiBase, pyramidApi2Token } from '../api';

// Check for PyramidAccessToken in local settings or use the environment variable as a fallback
const getPyramidAuthToken = () => localStorage.getItem('pyramidToken') || process.env.REACT_APP_PYRAMID_API2_TOKEN;

const headers = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin':'*',
  // 'Access-Control-Allow-Methods':'*'
  // paToken: getPyramidAuthToken(), // Uncomment if needed for additional auth in headers
  // Pyramidembeddedauth: '<Your Auth Token>',
};

const getPyramidMenuItems = (folderId: string) =>
  pyramidApiBase.post<Response<PyramidMenuItems[]>>(
    'content/getFolderItems',
    {
      auth: getPyramidAuthToken(),
      folderId: folderId,
    },
    { headers },
  );

export const pyramidApi = {
  getPyramidMenuItems,
};

import { companiesApi } from 'api/companies';
import { AppDispatch } from 'store/store';
import { AddUserRequest, Company, LeadMappingsPayload, UploadRequest } from 'types/companies/companies';

import { companySlice } from './slice';

export const getCompany = (params: string) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.processGetCompanyData());

  try {
    const { data } = await companiesApi.getCompany(params);

    dispatch(companySlice.actions.companyDataSuccess(data.result));
  } catch (error) {
    dispatch(companySlice.actions.companyDataFailure());
  }
};

export const updateCompany =
  (params: Company, externalId: string, onSuccess: () => void, onFailure: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(companySlice.actions.processGetCompanyData());

    try {
      await companiesApi.updateCompany(params);

      dispatch(getCompany(externalId));
      onSuccess();
    } catch (error) {
      dispatch(companySlice.actions.companyDataFailure());
      onFailure();
    }
  };

export const CreateLeadMappings =
  (params: LeadMappingsPayload, onSuccess: () => void, onFailure: () => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await companiesApi.saveLead(params);
      return data;
      onSuccess();
    } catch (error) {
      onFailure();
    }
  };

export const getAllBrandInfo = (brandId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.processGetBrandData());
  try {
    const { data } = await companiesApi.getAllBrandInfo(brandId);
    dispatch(companySlice.actions.getBrandDataSuccess(data.result));
  } catch (error) {
    dispatch(companySlice.actions.getBrandDataFailure());

    onFailure();
  }
};

export const getGA4Leads =
  (clientId: string, brandId: string, storeId: string, storeIdentifier: string, onFailure: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await companiesApi.getA4Leads(clientId, brandId, storeId, storeIdentifier);
      return data;
    } catch (error) {
      onFailure();
    }
  };

export const UploadCSV =
  (request: UploadRequest, onSuccess: () => void, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(companySlice.actions.processGetBrandData());
    try {
      const { data } = await companiesApi.UploadCSV(request);
      dispatch(companySlice.actions.getBrandDataSuccess(data.result));
      onSuccess();
    } catch (error) {
      dispatch(companySlice.actions.getBrandDataFailure());

      onFailure();
    }
  };

export const getUsersByCompanyId = (companyId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.processUsers());

  try {
    const { data } = await companiesApi.getUsersByCompanyId(companyId);

    dispatch(companySlice.actions.getUsersSuccess(data.result.users));
  } catch (error) {
    dispatch(companySlice.actions.usersFailure());

    onFailure();
  }
};

export const addUser =
  (companyId: string, data: AddUserRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(companySlice.actions.processUsers());

    try {
      await companiesApi.addUser(companyId, data);

      const { data: usersData } = await companiesApi.getUsersByCompanyId(companyId);

      dispatch(companySlice.actions.getUsersSuccess(usersData.result.users));
    } catch (error) {
      dispatch(companySlice.actions.usersFailure());

      onFailure();
    }
  };

export const deleteUser =
  (companyId: string, userId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(companySlice.actions.processUsers());

    try {
      await companiesApi.deleteUser(companyId, userId);

      const { data: usersData } = await companiesApi.getUsersByCompanyId(companyId);

      dispatch(companySlice.actions.getUsersSuccess(usersData.result.users));
    } catch (error) {
      dispatch(companySlice.actions.usersFailure());

      onFailure();
    }
  };

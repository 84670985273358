import { PickersDay, PickersDayProps } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { compareAsc, isSameDay, isWithinInterval, lastDayOfWeek, startOfWeek } from 'date-fns';
import { useField } from 'formik';
import { useEffect, useState, FC } from 'react';

import { datePickerSelectStyles as S } from './styles';

interface Props {
  name: string;
  label?: string;
  full?: boolean;
  variant?: 'default' | 'month';
  inputFormat?: string;
  showSelectPeriod: boolean;
  customDate?: Date;
}

export const DatePicker: FC<Props> = ({
  name,
  label,
  full,
  showSelectPeriod = false,
  customDate,
  variant = 'default',
  inputFormat,
}) => {
  const [{ value }, , { setValue }] = useField({ name });
  //  const date = useSelector(dashboardsSelectors.date);

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    //selected datte is in value
    const selectedDate = new Date(value);
    //then minus 7 days to get the same day from week before
    const prevWeek = new Date(new Date().setTime(new Date(value).getTime() - 7 * 24 * 60 * 60 * 1000));

    //get the start of the week for current week
    const startCur = startOfWeek(value, { weekStartsOn: 1 });

    //get the start of the week for prvious  week
    const startPrev = startOfWeek(prevWeek, { weekStartsOn: 1 });

    const dayIncludeCur = isWithinInterval(date, { start: startCur, end: selectedDate });
    const dayIncludePrev = isWithinInterval(date, { start: startPrev, end: prevWeek });

    const isSelectedDay = isSameDay(date, selectedDate);

    const isFirstDayCurrentWeek = isSameDay(date, startCur);
    const isLastDayCurrentWeek = isSameDay(date, selectedDate);

    const isFirstDayPrevWeek = isSameDay(date, startPrev);
    const isLastDayPrevWeek = isSameDay(date, prevWeek);

    return (
      <S.datePickerSelectPeriod
        {...pickersDayProps}
        disableMargin
        isSelectedDay={isSelectedDay}
        isCurrentWeekMemberDate={dayIncludeCur}
        isPrevWeekMemberDate={dayIncludePrev}
        isEndDateForCurrentWeek={isLastDayCurrentWeek}
        isStartDateForCurrentWeek={isFirstDayCurrentWeek}
        isEndDateForPrevWeek={isLastDayPrevWeek}
        isStartDateForPrevWeek={isFirstDayPrevWeek}
      />
    );
  };

  if (!showSelectPeriod) {
    return (
      <FormControl fullWidth={full}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            minDate={new Date().setFullYear(2019)}
            maxDate={new Date()}
            label={label}
            value={customDate ? customDate : value}
            inputFormat="dd/MM/yyyy"
            onChange={date => setValue(date)}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
    );
  } else {
    return (
      <FormControl fullWidth={full}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            maxDate={new Date()}
            renderInput={params => <TextField {...params} />}
            label="Week-to-date Period"
            value={customDate ? customDate : value}
            renderDay={renderWeekPickerDay}
            inputFormat="dd/MM/yyyy"
            onChange={date => setValue(date)}
          />
        </LocalizationProvider>
      </FormControl>
    );
  }
};

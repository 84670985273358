import { createSlice } from '@reduxjs/toolkit';

import { SnapchatSlice } from './types';

const initialState: SnapchatSlice = {};

export const snapchatSlice = createSlice({
  name: 'snapchat',
  initialState,
  reducers: {},
});

import { SuspenseLoader } from 'components/suspense-loader';
import { useSelector } from 'hooks';
import ProductProfitability from 'pages/product/profitability/product-profitability';
import ShopifySalesDashboard from 'pages/shopify-dashboard/shopify-sales';
import TiktokClickDashboard from 'pages/tiktok-dashboard/tiktok-click-dashboard';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { routes } from './routes';

const Loader = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const CustomerSegment = Loader(lazy(() => import('../pages/customer-segment/customer-segment')));

const GoogleRedirect = Loader(lazy(() => import('../pages/google-redirect/google-redirect')));
const GoogleGA4Redirect = Loader(lazy(() => import('../pages/google-redirect/googlega4-redirect')));

const FacebookRedirect = Loader(lazy(() => import('../pages/facebook-redirect/facebook-redirect')));

const TiktokRedirect = Loader(lazy(() => import('../pages/tiktok-redirect/tiktok-redirect')));

const SnapchatRedirect = Loader(lazy(() => import('../pages/snapchat-redirect/snapchat-redirect')));

const MetaOrganicRedirect = Loader(lazy(()=> import('../pages/metaorganic-redirect/metaorganic-redirect')));

const ShopifyStore = Loader(lazy(() => import('../pages/shopify-store/shopify-store')));

const OverallTrendPerformance = Loader(
  lazy(() => import('../pages/overall-trend-performance/overall-trend-performance')),
);

const Overview = Loader(lazy(() => import('../pages/overview/overview')));

const MarketingOverview = Loader(lazy(() => import('../pages/marketing/overview/marketing-overview')));
const MarketingOverviewDetail = Loader(lazy(() => import('../pages/marketing/overview/marketing-overview-detail')));

const PaidMarketingOverview = Loader(lazy(() => import('../pages/marketing/paidmarketing/paidmarketing')));

const PaidMarketingPerformance = Loader(
  lazy(() => import('../pages/paid-marketing-performance/paid-marketing-performance/paid-marketing-performance')),
);

const PaidMarketingPerformanceChannel = Loader(
  lazy(
    () =>
      import(
        '../pages/paid-marketing-performance/paid-marketing-performance-channel/paid-marketing-performance-channel'
      ),
  ),
);

const OrganicSocial = Loader(lazy(() => import('../pages/SocialOrganicOverview/OrganicSocial')));

const ProductPerformance = Loader(lazy(() => import('../pages/overall-trend-performance/overall-trend-performance')));

const RevenueSpend = Loader(lazy(() => import('../pages/revenue-spend/revenue-spend')));
const Brain = Loader(lazy(() => import('../pages/brain/brain')));
const Dataloading = Loader(lazy(() => import('../pages/dataloading/dataloading')));

const ChannelAnalyzer = Loader(lazy(() => import('../pages/marketing-analyzer/channel-analyzer/channel-analyzer')));
const CampaignAnalyzer = Loader(lazy(() => import('../pages/marketing-analyzer/campaign-analyzer/campaign-analyzer')));

const ShoppingJourney = Loader(lazy(() => import('../pages/shopping-journey/shopping-journey')));
const Pyramid = Loader(lazy(() => import('../pages/pyramid/pyramid')));
const MultiPyramid = Loader(lazy(() => import('../pages/multipyramid/multipyramid')));
const PyramidProdSalesAnalysis = Loader(
  lazy(() => import('../pages/pyramidprodsalesanalysis/pyramidprodsalesanalysis')),
);
const PyramidSpendManager = Loader(lazy(() => import('../pages/pyramidspendmanager/pyramidspendmanager')));
const AnalyticContent = Loader(lazy(() => import('../pages/pyramidmenutree/pyramidmenutree')));

const PyramidWidget = Loader(lazy(() => import('../pages/pyramidwidget/pyramidwidget')));

const CustomerJourneyMarketingOnly = Loader(
  lazy(() => import('../pages/customer-journey/marketing-only/customer-journey-marketing-only')),
);

const CampaignsMarketingOnly = Loader(lazy(() => import('../pages/campaigns/marketing-only/campaigns-marketing-only')));

const TiktokDashboard = Loader(lazy(() => import('../pages/tiktok-dashboard/tiktok-dashboard')));

const Status404 = Loader(lazy(() => import('../pages/server-status/Status404')));

export const dashboardsRoutes = [
  {
    path: routes.goggleRedirect.url,
    element: <GoogleRedirect />,
  },
  {
    path: routes.gogglega4Redirect.url,
    element: <GoogleGA4Redirect />,
  },
  {
    path: routes.facebookRedirect.url,
    element: <FacebookRedirect />,
  },
  {
    path: routes.tiktokRedirect.url,
    element: <TiktokRedirect />,
  },
  {
    path: routes.metaOrganicRedirect.url,
    element: <MetaOrganicRedirect />,
  },
  {
    path: routes.snapchatRedirect.url,
    element: <SnapchatRedirect />,
  },
  {
    path: routes.shopifyStore.url,
    element: <ShopifyStore />,
  },
  {
    path: routes.marketingoverview.url,
    element: <MarketingOverview />,
  },

  {
    path: routes.marketingoverviewdetail.url,
    element: <MarketingOverviewDetail />,
  },
  {
    path: routes.shopifydashboard.url,
    element: <ShopifySalesDashboard />,
  },

  {
    path: routes.paidmarketingoverview.url,
    element: <PaidMarketingOverview />,
  },
  {
    path:routes.socialOrganicOverview.url,
    element: <OrganicSocial />,
  },
  {
    path: routes.overview.url,
    element: <Overview />,
  },
  {
    path: routes.paidMarketingPerformance.url,
    children: [
      {
        path: routes.paidMarketingPerformance.url,
        element: <PaidMarketingPerformance />,
      },
      {
        path: routes.paidMarketingPerformance.channel.url,
        element: <PaidMarketingPerformanceChannel />,
      },
    ],
  },
  {
    path: '',
    //element: <Navigate to={ IsMarketingOnly ? routes.marketingoverview.url : routes.overview.url } replace />,
    element: <Navigate to={routes.marketingoverview.url} replace />,
  },
  {
    path: routes.marketingAnalyzer.url,
    children: [
      {
        path: '',
        element: <Navigate to={routes.marketingAnalyzer.channel.url} replace />,
      },
      {
        path: routes.marketingAnalyzer.channel.url,
        element: <ChannelAnalyzer />,
      },
      {
        path: routes.marketingAnalyzer.campaign.url,
        element: <CampaignAnalyzer />,
      },
    ],
  },
  {
    path: routes.shoppingJourney.url,
    element: <ShoppingJourney />,
  },

  {
    path: routes.pyramid.url,
    element: <Pyramid />,
  },
  {
    path: routes.multipyramid.url,
    element: <MultiPyramid />,
  },
  {
    path: routes.analyticcontent.url,
    element: <AnalyticContent />,
  },

  {
    path: routes.pyramidwidget.url,
    element: <PyramidWidget />,
  },
  {
    path: routes.pyramidprodsalesanalysis.url,
    element: <PyramidProdSalesAnalysis />,
  },
  {
    path: routes.pyramidspendmanager.url,
    element: <PyramidSpendManager />,
  },
  {
    path: routes.overallTrendPerformance.url,
    element: <OverallTrendPerformance />,
  },
  {
    path: routes.revenueSpend.url,
    element: <RevenueSpend />,
  },
  {
    path: routes.brain.url,
    element: <Brain />,
  },
  {
    path: routes.dataloading.url,
    element: <Dataloading />,
  },
  {
    path: routes.customerSegment.url,
    element: <CustomerSegment />,
  },
  // {
  //   path: routes.productPerformance.url,
  //   element: <ProductPerformance />,
  // },
  {
    path: routes.customerJourney.marketingOnly.url,
    element: <CustomerJourneyMarketingOnly />,
  },
  {
    path: routes.campaigns.marketingOnly.url,
    element: <CampaignsMarketingOnly />,
  },
  {
    path: routes.tiktokDashboard.url,
    children: [
      {
        path: routes.tiktokDashboard.url,
        element: <TiktokDashboard />,
      },
      {
        path: routes.tiktokDashboard.channel.url,
        element: <TiktokClickDashboard />,
      },
    ],
  },

  {
    path: routes.productProfitability.url,
    element: <ProductProfitability />,
  },

  
  {
    path: '*',
    element: <Status404 />,
  },
];

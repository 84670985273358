import { Typography, TableRow, TableCell, Avatar } from '@mui/material';
import { CompareTableProgressCell, calculateCellProgress } from 'components/compare-table';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { TiktokLeadTableData } from 'types/Tiktok/Tiktok';
import { formatNumber } from 'utils/numbers';

interface Props {
  currencyLabel:string;
  row: TiktokLeadTableData; //ShopifLeadTableData;
  data: TiktokLeadTableData[]; //ShopifLeadTableData[];
  onClick?: (name: string) => void;
}

export const TiktokLeadTableRow: FC<Props> = ({ currencyLabel, onClick, row, data }) => {
  const navigate = useNavigate();
  const redirect = (name: string) => {
    //navigate('/tiktok-dashboard/ad spend');
    onClick(name);
  };
  return (
    <TableRow hover>
      <TableCell onClick={() => redirect(row.campaign == null ? row.campaign_name : row.campaign)}>
        {row.campaign == null ? row.campaign_name : row.campaign}
      </TableCell>

      <CompareTableProgressCell
        currencyLabel={currencyLabel}
        sign='currency'
        value={formatNumber(row.ad_cost.value)}
        label={row.ad_cost.prevCompare}
        progress={calculateCellProgress({ row, data, key: 'ad_cost' })}
        withProgress
      />
      <CompareTableProgressCell
        value={formatNumber(row.conversions.value)}
        label={row.conversions.prevCompare}
        progress={calculateCellProgress({ row, data, key: 'conversions' })}
        withProgress
      />
      <CompareTableProgressCell
        value={formatNumber(row.video_views.value)}
        label={null}
        progress={calculateCellProgress({ row, data, key: 'video_views' })}
        withProgress
      />
      <CompareTableProgressCell
        value={formatNumber(row.video_play_actions.value)}
        label={row.video_play_actions.value}
        progress={calculateCellProgress({ row, data, key: 'video_play_actions' })}
        withProgress
      />

      <CompareTableProgressCell
        value={formatNumber(row.comments.value)}
        label={row.comments.value}
        progress={calculateCellProgress({ row, data, key: 'comments' })}
        withProgress
      />
    </TableRow>
  );
};

import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const EmptyState: FC<Props> = ({ children }) => {
  return (
    <Box padding="3rem" display="flex" justifyContent="center">
      <Typography display="block" variant="h3" justifyContent="center" textAlign="center">
        {children}
      </Typography>
    </Box>
  );
};

import { DashboardsState, MarketingOverviewDashboardsState } from './types';

const date = (state: DashboardsState) => state.dashboards.date;
const endDate = (state: DashboardsState) => state.dashboards.endDate;
const compareStartDate = (state: DashboardsState) => state.dashboards.compareStartDate;
const compareEndDate = (state: DashboardsState) => state.dashboards.compareEndDate;

const markets = (state: DashboardsState) => state.dashboards.markets;

const channels = (state: DashboardsState) => state.dashboards.channels;

const marketoverviewdate = (state: MarketingOverviewDashboardsState) => state.marketoverviewdashboards.date;

export const dashboardsSelectors = {
  date,
  endDate,
  compareStartDate,
  compareEndDate,
  markets,
  channels
};

export const marketdashboardsSelectors = {
  date,
  markets,
  channels
};

import { LEGEND_HEIGHT, LABELED_X_AXIS_HEIGHT } from 'constants/charts';

import { Box, useTheme } from '@mui/material';
import {
  LineChart as RELineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Label,
  ComposedChart,
  Area,
  ZAxis,
} from 'recharts';
import { Dimensions } from 'types/common/charts';
import { formatNumber, formatNumberscomma } from 'utils/numbers';

import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';
import { tickFormatter } from '../helpers/tick-formatter';

interface lineojb {
  name: string;
  stroke: string;
}

export interface Props<T> {
  data: T[];
  legend?: string[];
  areaKey: string;
  dimensions: Dimensions;
  tooltipBeforeSymbol?: string | string[];
  lineArray?: lineojb[];
  xkey?: string;
  hideLegend?:boolean;
  uppercaseLegend?: boolean;
  formatDate?:boolean;

}

const defaultColors = ['#00B2FF', '#F7B500', '#6E759F', '#dea300'];

export function AreaLineChart<T extends Record<string, any>>({
  data,
  legend,
  dimensions,
  areaKey,
  tooltipBeforeSymbol,
  lineArray,
  xkey,
  hideLegend,
  uppercaseLegend,
  formatDate
}: Props<T>) {
  const theme = useTheme();
  const colors = defaultColors;
  const mapPayload = legend?.map((name, index) => ({
    value: name,
    color: colors[index],
  }));
  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ResponsiveContainer width="100%" aspect={0}>
        <ComposedChart data={data}>
          <Tooltip
            isAnimationActive={false}
            content={props => <CustomTooltip {...props} customPayload={mapPayload} tooltipBeforeSymbol={tooltipBeforeSymbol} showDate />}
          />
          <defs>
            <linearGradient id="gradientColorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.colors.warning.main} stopOpacity={1} />
              <stop offset="95%" stopColor={theme.colors.warning.lighter} stopOpacity={0.0} />
            </linearGradient>
          </defs>

          {!hideLegend && (
            <Legend
              payload={mapPayload}
              verticalAlign="top"
              align="center"
              height={LEGEND_HEIGHT}
              iconType="circle"
              formatter={legendFormatter({ uppercase: uppercaseLegend })}
            />
          )}

          <XAxis dataKey={xkey} tickLine={false} height={LABELED_X_AXIS_HEIGHT} tickFormatter={formatDate && tickFormatter} />
          <YAxis tickFormatter={formatNumberscomma}/>

          <Area
            type="monotone"
            dataKey={areaKey}
            fill="url(#gradientColorValue)"
            stroke={theme.colors.warning.main}
            strokeWidth={4}
          />

          {lineArray.map((line, i )=> (
            <Line type="monotone" key={line.name} dataKey={line.name} stroke={colors[i]} />
          ))}

          <ZAxis type="number" range={[75]} />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

import { styled, Box } from '@mui/material';

interface HeaderWrapperProps {
  sidebarWidth: string;
}

export const HeaderWrapper = styled(Box, { shouldForwardProp: prop => prop !== 'sidebarWidth' })<HeaderWrapperProps>(
  ({ theme, sidebarWidth }) => `
          height: ${theme.header.height};
          color: ${theme.header.textColor};
          padding: ${theme.spacing(0, 3)};
          background-color: ${'alpha(theme.header.background, 0.95)'};
          backdrop-filter: blur(3px);
          justify-content: space-between;
          width: 100%;
          @media (min-width: ${theme.breakpoints.values.lg}px) {
              margin-left: ${sidebarWidth};
              width: auto;
          }
  `,
);

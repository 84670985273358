import { onboardingApi } from 'api/api';
import { Response } from 'types/api/api';
import { ChangePasswordRequest, GetAccessSystemIdResult, User } from 'types/users/users';

const getUser = (userId: string) => onboardingApi.get<User>(`/users/${userId}`);

const changePassword = (data: ChangePasswordRequest) => onboardingApi.post('/users/ChangePassword', data);

const getSystemAccessIdByClientId = (clientId: string) =>
  onboardingApi.get<Response<GetAccessSystemIdResult[]>>(`ClientSystemAccess/get-by-client-id/${clientId}`);

export const usersApi = {
  getUser,
  changePassword,
  getSystemAccessIdByClientId,
};

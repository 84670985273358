import max from 'lodash/max';
import sumBy from 'lodash/sumBy';
import { percentage } from 'utils/numbers';

interface MetricParam<T> {
  row: T;
  data: T[];
  key: keyof T;
}

interface CalculateTotalParams<T> {
  data: T[];
  key: keyof T;
}

export const calculateCellProgress = <T extends Record<string, any>>({ row, data, key }: MetricParam<T>) => {
  const metric = row[key];
  const maxColumnMetric = max(data.map(d => d[key]));

  return Math.round(percentage(metric, maxColumnMetric as number));
};

export const calculateTotal = <T>({ data, key }: CalculateTotalParams<T>) => {
  if (!data || data.length === 0 || !key) {
    return 0;
  }
  const sum = sumBy(data, key as string);
  return Number(sum == (undefined || null) ? 0 : sum.toFixed(2));
};

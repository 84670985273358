import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganicSocialSummary } from 'types/OrganicSocial/OrganicSocial';

import { OrganicSocialSlice } from './types';

const initialState: OrganicSocialSlice = {
  organicSocialData: null,
  loading: true,
};

export const organicSocialSlice = createSlice({
  name: 'organicSocial',
  initialState,
  reducers: {
    processGetOrganicSocialData: state => {
      state.loading = true;
    },
    getOrganicSocialDataSuccess: (state, action: PayloadAction<OrganicSocialSummary>) => {
      state.organicSocialData = action.payload;
      state.loading = false;
    },
    getOrganicSocialDataFailure: state => {
      state.loading = false;
    },
  },
});

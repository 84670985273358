import {
  LABELED_Y_AXIS_WIDTH,
  LABELED_X_AXIS_HEIGHT,
  LEGEND_HEIGHT,
  X_AXIS_LABEL_OFFSET,
  Y_AXIS_LABEL_OFFSET,
} from 'constants/charts';

import { Box, Paper, Stack, Typography, styled, useTheme } from '@mui/material';
import {
  ResponsiveContainer,
  BarChart as REBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Label,
} from 'recharts';
import { ChartAxisLabels, Dimensions } from 'types/common/charts';
import { formatNumber, formatNumberscomma } from 'utils/numbers';

import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';
import { tickFormatter } from '../helpers/tick-formatter';
import { chartStyles } from '../styles';

interface Props<T> {
  data: T[];
  domain: string[];
  xDataKey: string;
  labels?: ChartAxisLabels;
  dimensions: Dimensions;
  stack?: boolean;
  hideYAxis?: boolean;
  hideXAxis?: boolean;
  hideLegend?: boolean;
  tooltipBeforeSymbol?: string | string[];
  uppercaseLegend?: boolean;
  formatDate?: boolean;
  formatNumberswithCommas?: boolean;
  formatNumberswithSuffix?: boolean;
  barSize?: number;
  customColors?: string[];
  hidetooltip?: boolean;
  legend?: string[];
}

export function BarChart<T extends Record<string, any>>({
  data,
  xDataKey,
  dimensions,
  labels = {},
  domain,
  stack,
  hideXAxis,
  hideYAxis,
  hideLegend,
  hidetooltip = false,
  tooltipBeforeSymbol = '',
  uppercaseLegend,
  formatDate,
  formatNumberswithCommas = false,
  formatNumberswithSuffix = false,

  barSize = 20,
  customColors,
  legend,
}: Props<T>) {
  const theme = useTheme();

  const colors = customColors
    ? customColors
    : [theme.colors.warning.main, '#00A3FF', theme.colors.secondary.main, theme.colors.info.main];
  const stackId = stack ? 'a' : null;

  const mapPayload = legend?.map((name, index) => ({
    value: name,
    color: colors[index],
  }));
  const formatWithSuffix = (number: any) => {
    if (number === null) {
      return ''; // or any other default value you prefer
    }

    const absNumber = Math.abs(number);
    if (absNumber >= 1e6) {
      return (number / 1e6).toFixed(1) + 'm';
    } else if (absNumber >= 1e3) {
      return Math.round(number / 1e3) + 'k';
    } else {
      return number.toString();
    }
  };
  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ResponsiveContainer width="98%" aspect={0}>
        <REBarChart data={data} margin={{ left: 10 }}>
          <CartesianGrid stroke="#E2E7E7" strokeDasharray="5 10" vertical={false} />

          {!hideXAxis && (
            <XAxis
              dataKey={xDataKey}
              height={labels.x ? LABELED_X_AXIS_HEIGHT : undefined}
              tickLine={false}
              tickFormatter={formatDate && tickFormatter}
            >
              {labels.x && (
                <Label
                  value={labels.x}
                  position="insideBottom"
                  offset={X_AXIS_LABEL_OFFSET}
                  style={chartStyles.xAxisLabel}
                />
              )}
            </XAxis>
          )}

          {!hideYAxis && (
            <YAxis
              axisLine={false}
              width={labels.y ? LABELED_Y_AXIS_WIDTH : undefined}
              tickLine={false}
              // tickFormatter={formatNumberswithCommas ? formatNumberscomma : formatDate && formatNumberscomma}
              tickFormatter={
                formatNumberswithCommas
                  ? value => formatNumberscomma(value)
                  : formatNumberswithSuffix
                  ? formatWithSuffix
                  : undefined
              }
            >
              {labels.y && (
                <Label value={labels.y} position="right" angle={-90} offset={0} style={chartStyles.yAxisLabel} />
              )}
            </YAxis>
          )}

          {!hidetooltip && (
            <Tooltip
              isAnimationActive={false}
              content={props => (
                <CustomTooltip
                  {...props}
                  customPayload={mapPayload}
                  tooltipBeforeSymbol={tooltipBeforeSymbol}
                  showDate
                />
              )}
            />
          )}

          {!hideLegend && (
            <Legend
              payload={mapPayload}
              verticalAlign="top"
              align="center"
              height={LEGEND_HEIGHT}
              iconType="circle"
              formatter={legendFormatter({ uppercase: uppercaseLegend })}
            />
          )}

          {domain.map((group, index) => (
            <Bar key={group} dataKey={group} stackId={stackId} fill={colors[index]} barSize={barSize} />
          ))}
        </REBarChart>
      </ResponsiveContainer>
    </Box>
  );
}

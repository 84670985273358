import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Container, Grid, Typography, Button, Card, Tooltip, Link, CardHeader, CardContent } from '@mui/material';
import { useDispatch, useSelector, useToast } from 'hooks';
import { useEffect, FC, useState } from 'react';
import { brainSlice } from 'store/entities/brain';
import { getBrainData } from 'store/entities/brain/action';
import { userSelectors } from 'store/entities/user';
import { getRandomArrayItem } from 'utils/getRandomArrayItems'
import { formatIfNumber } from 'utils/numbers';

interface Props {
}

export const DidYouKnow: FC<Props> = () => {
    const dispatch = useDispatch();
    const { errorToast } = useToast();
    const user = useSelector(userSelectors.user);
    
    const insightLoading = useSelector(state=> state.brainSlice.loading);
    const insightData = useSelector(state => state.brainSlice.brainData);
    const [insights, setInsights] = useState(new Array())


    useEffect(() => {
        //we only do request to get the data if we do not have it loaded in state (so we do not have wasted api calls and data transfer)
        if(!insightData)
        {
            dispatch(
                getBrainData(
                {
                    ClientId: user.clientIdData,
                    GetLatestOnly:false
                },
                errorToast,
                ),
            );
        }
    },[]);
    
    
    useEffect(() => {
      if(insightData !== null)
      {
          setInsights(getRandomArrayItem(insightData, 1));
      }
    },[insightData]);


    const showNewInsights = () => {
        if(insightData !== null)
        {
            setInsights(getRandomArrayItem(insightData, 1));
        }
    }
    

const isEmptyString =(val:string) => {
    if(val == '')
        return 'Data Not Available';
    else
        return formatIfNumber(val);
}

  return (
    <Container>
        <Grid container spacing={1} sx={{ display:'flex', flexDirection:'column', alignItems:'stretch'}}>
            <Card elevation={3}>
                <CardHeader 
                    action={
                    <Tooltip title='Click to review another insight' placement='top' arrow><RefreshIcon onClick={showNewInsights} fontSize='medium' style={{cursor:'pointer'}} /></Tooltip>
                    } 
                    title={<Typography sx={{fontSize:'22px', fontWeight:'bold', mt:-1.25}} ><Link href='/brain' style={{cursor:'pointer'}}>Did you Know?</Link></Typography>}
                    subheader={<Typography variant='h6'>{insights[0]?.question}</Typography>}
                />
                <CardContent>
                    <Typography variant='body2' sx={{mt:-2}}>{isEmptyString(insights[0]?.value ?? '')}</Typography>
                </CardContent>                
            </Card>
        </Grid>
    </Container>
  );
};
import { vennFlowApi } from 'api/api';
import { ExportRequestData,PowerBIExport, ExportResponseData, CogsTemplateExport } from 'types/exportcsv/exportrequest';

const getFactRetailSales = (params: ExportRequestData) =>
  vennFlowApi.get('/Export/FactRetailSales', { params });

const getFactAdsPerformance = (params: ExportRequestData) =>
  vennFlowApi.get('/Export/FactAdsPerformance', { params });

const getPowerBIExport = (data: PowerBIExport) =>
  vennFlowApi.post('/Export/PowerBIExport', data, {responseType: 'arraybuffer'});


const getCogsTemplateExport = (data: CogsTemplateExport) =>
  vennFlowApi.get('/Export/GenerateCogsTemplate', {data});


//CLientLoadState
export const dataexportApi = {
  getPowerBIExport,
  getFactRetailSales,
  getFactAdsPerformance,
  getCogsTemplateExport
};

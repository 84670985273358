import { pyramidApi } from 'api/pyramid';
import { AppDispatch } from 'store/store';

import { pyramidSlice } from './slice';

export const getPyramid = (folderId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(pyramidSlice.actions.processGetPyramid());
  try {
    const { data } = await pyramidApi.getPyramidMenuItems(folderId);
    //dispatch(pyramidSlice.actions.getPyramidSuccess(data.result));
    return data;
  } catch {
    dispatch(pyramidSlice.actions.getPyramidFailure());
    onFailure();
  }
};

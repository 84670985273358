import { LEGEND_HEIGHT } from 'constants/charts';

import { Box } from '@mui/material';
import { FC } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, Label } from 'recharts';
import { Dimensions, DoughnutChartData } from 'types/common/charts';

import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';

const colors = ['#00B2FF', '#F7B500', '#6E759F', '#5569ff', '#57CA22', '#44DEC5', '#0A2435'];

interface DoughnutChartProps {
  data: DoughnutChartData[];
  dimensions: Dimensions;
  tooltipBeforeSymbol?: string;
  tooltipAfterSymbol?: string;
  customColors?: string[];
  islegend?: boolean;
}

export const DoughnutChart: FC<DoughnutChartProps> = ({
  data,
  dimensions,
  tooltipBeforeSymbol,
  tooltipAfterSymbol,
  customColors,
  islegend = true,
}) => {
  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ResponsiveContainer width="100%" aspect={0}>
        <PieChart>
          <Tooltip
            isAnimationActive={false}
            content={props => (
              <CustomTooltip
                tooltipAfterSymbol={tooltipAfterSymbol}
                tooltipBeforeSymbol={tooltipBeforeSymbol}
                {...props}
                showDate
              />
            )}
          />

          {customColors ? (
            <Pie
              legendType="none"
              data={data == null ? [] : data}
              dataKey="value"
              nameKey="name"
              paddingAngle={1}
              minAngle={1}
              cx="50%"
              cy="50%"
              outerRadius="80%"
              innerRadius="50%"
              label={({ name, value }) => `${name} (${value}%)`}
            >
              {data == null
                ? []
                : data.map((_entry, index) => <Cell key={`cell-${index}`} fill={customColors[index]} />)}
            </Pie>
          ) : (
            <Pie
              data={data == null ? [] : data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius="80%"
              innerRadius="50%"
            >
              {data == null ? [] : data.map((_entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} />)}
            </Pie>
          )}

          {islegend ? (
            <Legend
              verticalAlign="top"
              align="center"
              height={LEGEND_HEIGHT}
              iconType="circle"
              formatter={legendFormatter()}
            />
          ) : (
            ''
          )}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

export const BaseLayout: FC<Props> = ({ children }) => (
  <Box flex="1" height="100%">
    {children || <Outlet />}
  </Box>
);

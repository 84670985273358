import { OverallTrendState } from './types';

const overallPerformanceData = (state: OverallTrendState) => state.overallTrend.overallTrendData;

const loading = (state: OverallTrendState) => state.overallTrend.loading;

export const overallTrendSelectors = {
  overallPerformanceData,
  loading,
};

import { vennFlowApi } from 'api/api';
import {
  Country,
  DailyDrillDownData,
  GetDailyDrillDownRequest,
  GetPaidMarketingDataRequest,
  PaidMarketingData,
  GetPaidMarketingOverviewDataRequest,
  MarketingOnlyPaidMarketingData,
} from 'types/paid-marketing-performance/paid-marketing-performance';

const getCountries = () => vennFlowApi.get<{ countries: Country[] }>('PaidMarketing/Countries');

const getPaidMarketingData = (params: GetPaidMarketingDataRequest) =>
  vennFlowApi.get<{ paidMarketingPerformance: PaidMarketingData }>('PaidMarketing/PaidMarketingPerformance', {
    params,
  });
const getPaidMarketingOverviewData = (params: GetPaidMarketingOverviewDataRequest) =>
  vennFlowApi.get< MarketingOnlyPaidMarketingData>(
    '/MarketingOnlyPaidMarketing/MarketingOnlyPaidMarketing',
    {
      params,
    },
  );

const getChannelTableData = (params: GetDailyDrillDownRequest) =>
  vennFlowApi.get<{ dailyDrilldown: DailyDrillDownData }>('PaidMarketing/DailyDrillDown', { params });

export const paidMarketingApi = {
  getCountries,
  getPaidMarketingData,
  getPaidMarketingOverviewData,
  getChannelTableData,
};

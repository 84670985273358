import { revenueSpendApi } from 'api/revenue-spend/revenue-spend';
import { AppDispatch } from 'store/store';
import { GetRevenueSpendData } from 'types/revenue-spend/revenue-spend';

import { revenueSpendSlice } from './slice';

export const getRevenueSpendData =
  (params: GetRevenueSpendData, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(revenueSpendSlice.actions.processGetRevenueSpendData());
    try {
      const { data } = await revenueSpendApi.getRevenueSpendData(params);

      dispatch(revenueSpendSlice.actions.getRevenueSpendDataSuccess(data.revenueSpend));

      return data;
    } catch {
      dispatch(revenueSpendSlice.actions.getRevenueSpendDataFailure());

      onFailure();
    }
  };

import InfoIcon from '@mui/icons-material/Info';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Typography, Box, Tooltip } from '@mui/material';
import { fontSize } from '@mui/system';
import { AreaScatterChart } from 'components/charts';
import { CSSProperties, FC, ReactNode } from 'react';
import { AreaScatterData } from 'types/common/charts';

import { statisticsCardStyles as S } from './styles';

type Sign = 'currency' | 'percentage';
type Variant = 'positive' | 'negative';

interface StatisticsCardProps {
  currencyLabel?: string;
  name: string;
  subText?: string;
  value: number | string;
  fontSize?: string;
  sign?: Sign;
  description: string;
  subDescription?: string;
  sx?: CSSProperties;
  data?: AreaScatterData[];
  customChart?: ReactNode;
  dataKey?: string;
  variant?: Variant;
  footer?: string;
  height?: number;
  titleUppercase?: boolean;
  tooltip?: string;
}

export const StatisticsCard: FC<StatisticsCardProps> = ({
  currencyLabel = null,
  name,
  value,
  subText = '',
  fontSize = null,
  sign,
  description,
  subDescription,
  sx = {},
  data,
  customChart,
  dataKey = 'spend',
  variant = 'positive',
  footer,
  height,
  titleUppercase = false,
  tooltip = null,
}) => {
  const descSplit = description?.split('\n') ?? null;
  return (
    <S.StatisticsGroup sx={sx} style={{ position: 'relative', display: 'block', border: '2px solid #f0f1f6' }}>
      <Box height={height}>
        <Typography display="inline" variant="h4" mt={0.5} textTransform={titleUppercase ? 'uppercase' : 'capitalize'}>
          {name}
        </Typography>
        <Typography style={{ position: 'absolute', top: 2, right: 2 }}>
          {tooltip != null ? (
            <Tooltip title={tooltip} placement="top-end">
              <InfoIcon htmlColor="#fad674" />
            </Tooltip>
          ) : (
            ''
          )}
        </Typography>
        <Typography variant="subtitle1" mt={0.7}>
          {subText}
        </Typography>
        <Typography
          fontSize={fontSize}
          variant="h4"
          mt={0.9}
          textTransform={titleUppercase ? 'uppercase' : 'capitalize'}
          color={theme => (variant === 'positive' ? theme.colors.success.main : theme.colors.error.main)}
        >
          {sign === 'currency' && currencyLabel}
          {value}
          {sign === 'percentage' && '%'}
        </Typography>
        <Typography display="inline" variant="subtitle2" m={0.9}>
          {descSplit === null || descSplit[0] === null ? description : descSplit[0]}
          <br />
          {descSplit === null || descSplit[1] === null ? '' : descSplit[1]}
        </Typography>
        <Typography variant="subtitle2" m={0.9}>
          {subDescription}
        </Typography>
      </Box>

      {data && <AreaScatterChart data={data} dataKey={dataKey} dimensions={{ height: '70px', width: '100%' }} />}
      {!data && customChart}
      {footer && (
        <Typography variant="subtitle2" m={0.5} pb={1.5}>
          {footer}
        </Typography>
      )}
    </S.StatisticsGroup>
  );
};

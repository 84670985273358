import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LogoLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  display: flex;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const LogoGroup = styled(Box)`
  width: 52px;
  height: 38px;
  margin-top: 4px;
  transform: scale(0.8);
`;

export const logotypeStyles = {
  LogoLink,
  LogoGroup,
};

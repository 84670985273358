import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExportResponseData } from 'types/exportcsv/exportrequest';

import { DataExportSlice } from './types';

const initialState: DataExportSlice = {
  files: null,
  loading: true,
};

export const dataexportSlice = createSlice({
  name: 'data-export',
  initialState,
  reducers: {
    processGetExportData: state => {
      state.loading = true;
    },
    getExportDataSuccess: (state, action: PayloadAction<ExportResponseData>) => {
      state.files = action.payload;
      state.loading = false;
    },
    getExportDataFailure: state => {
      state.loading = false;
    },
  },
});

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { brainMessage } from 'types/brain/brain';
import { Brand, Market } from 'types/marketing-analyzer/marketing-analyzer';

import { DashboardsSlice, MarketOverviewDashboardsSlice } from './types';

const get7DaysAgoDate = (): Date => {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  return sevenDaysAgo;
};
const initialState: DashboardsSlice = {
  date: moment().startOf('week').toDate(),
  endDate: new Date(),
  compareStartDate: moment(moment().startOf('week').toDate()).subtract(1, 'week').toDate(), //new Date(),
  compareEndDate: moment(new Date()).subtract(1, 'week').toDate(), // new Date(),
  markets: [],
  channels: []
};

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setDashboardsDate: (state, action: PayloadAction<Date>) => {
      state.date = action.payload;
    },
    setDashboardsMarkets: (state, action: PayloadAction<Market[]>) => {
      state.markets = action.payload;
    },
    setDashboardsChannels: (state, action: PayloadAction<Brand[]>) => {
      state.channels = action.payload;
    },
    setDashboardsEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload;
    },
    setDashboardsCompareStartDate: (state, action: PayloadAction<Date>) => {
      state.compareStartDate = action.payload;
    },
    setDashboardsCompareEndDateDate: (state, action: PayloadAction<Date>) => {
      state.compareEndDate = action.payload;
    },
  },
});

export const marketingoverviewdashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setDashboardsDate: (state, action: PayloadAction<Date>) => {
      state.date = action.payload;
    },
    setDashboardsMarkets: (state, action: PayloadAction<Market[]>) => {
      state.markets = action.payload;
    },
    setDashboardsChannels: (state, action: PayloadAction<Brand[]>) => {
      state.channels = action.payload;
    },
  },
});

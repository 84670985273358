import { FormControl, FormHelperText, InputLabel, Select as MuiSelect } from '@mui/material';
import { useField } from 'formik';
import { FC, ReactNode } from 'react';

interface Props {
  sx?: object;
  name: string;
  label?: string;
  multiple?: boolean;
  message?: string;
  full?: boolean;
  children: ReactNode;
}

export const Select: FC<Props> = ({ name, label, multiple, message, full, children, sx }) => {
  const [{ value, onChange }] = useField({ name });

  return (
    <FormControl fullWidth={full}>
      {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}

      <MuiSelect
        sx={sx}
        id={name}
        name={name}
        labelId={`${name}-label`}
        label={label}
        value={value}
        onChange={onChange}
        multiple={multiple}
      >
        {children}
      </MuiSelect>

      {message && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
};

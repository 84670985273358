import { Box } from '@mui/material';
import { Logotype } from 'components/logotype';
import { FC } from 'react';

export const AppInit: FC = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    top="0"
    bottom="0"
    left="0"
    right="0"
    position="fixed"
    width="100%"
    height="100%"
  >
    <Logotype />
  </Box>
);

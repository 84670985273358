import { LEGEND_HEIGHT } from 'constants/charts';

import { Box } from '@mui/material';
import { FC } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Dimensions, DoughnutChartData } from 'types/common/charts';

import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';


const labeline = true;

const COLORS = ['#008000', '#D3D3D3', '#FFBB28', '#FF8042'];

interface HalfDoughNutChartProps {
  data: DoughnutChartData[];
  dimensions: Dimensions;
  tooltipBeforeSymbol?: string;
}

export const HalfDoughNutChart: FC<HalfDoughNutChartProps> = ({ data, dimensions, tooltipBeforeSymbol }) => {
  return (
    <Box width={dimensions.width} height={dimensions.height}>
      <ResponsiveContainer width="100%" aspect={0}>
      <PieChart width={2000} height={350}>
                    <Pie
                      data={data}
                      cx={235}
                      cy={200}
                      labelLine={labeline}
                      startAngle={180}
                      endAngle={0}
                      innerRadius={60}
                      outerRadius={130}
                      fill="#8884d8"
                      paddingAngle={4}
                      dataKey="value"
                      label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        value,
                        index
                      }) => {
                        const RADIAN = Math.PI / 180;
                        // eslint-disable-next-line
                        const radius = 25 + innerRadius + (outerRadius - innerRadius);
                        // eslint-disable-next-line
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        // eslint-disable-next-line
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
              
                        return (
                          <text
                            x={x}
                            y={y}
                            fill='#8884d8'
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline='central'
                          >
                           {value}
                          </text>
                        );
                      }}
                    >
                      {data.map((_entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
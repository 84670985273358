import { TableContainer, Table, TableBody } from '@mui/material';
import { CompareTableHeader } from 'components/compare-table';
import { FC } from 'react';
import { ShopifLeadTableData } from 'types/marketing-analyzer/marketing-analyzer';

import { ShopifyLeadTableRow } from './shopify-table-row';
import { ShopifyTableTotalRow } from './shopify-table-total-row';

interface Props {
  currencyLabel:string;
  data: ShopifLeadTableData[];
  columns: string[];
}

export const ShopifyLeadTable: FC<Props> = ({ currencyLabel, data, columns }) => (
  <TableContainer>
    <Table size="small">
      <CompareTableHeader columns={columns} />
      <TableBody>
        {data.map((row, index) => (
          <ShopifyLeadTableRow currencyLabel={currencyLabel}  key={`overview-table-row-${index}`} row={row} data={data} />
        ))}

<ShopifyTableTotalRow currencyLabel={currencyLabel} data={data} />

      </TableBody>
    </Table>
  </TableContainer>
);

import { PyramidState } from './types';

const pyramid = (state: PyramidState) => state.pyramid.pyramid;

const loading = (state: PyramidState) => state.pyramid.loading;

const selectedPyramidId = (state: PyramidState) => state.pyramid.defaultSelectedPyramidId;

export const pyramidSelectors = {
  pyramid,
  loading,
  selectedPyramidId,
};

interface MapLineChartDataParams<T> {
  data: T[];
  xDataKey: keyof T;
  yDataKey: keyof T;
  groupBy: keyof T;
}

export const mapLineChartData = <T extends Record<string, any>>({
  data,
  xDataKey,
  yDataKey,
  groupBy,
}: MapLineChartDataParams<T>) =>
  data.reduce((dataset, d) => {
    const domain = d[groupBy];
    const existingRecord = dataset.find(record => record[xDataKey] === d[xDataKey]);
    const newRecord = { ...existingRecord, [xDataKey]: d[xDataKey], [domain]: d[yDataKey], 'originalDate': d['originalDate']};
    const restRecords = dataset.filter(record => record[xDataKey] !== d[xDataKey]);

    return [...restRecords, newRecord];
  }, [] as Record<keyof T, any>[]);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShoppingJourney, ShoppingJourneyFilter, ShoppingJourneyFilters } from 'types/shopping-journey/shopping-journey';

import { ShoppingJourneyFilterSlice, ShoppingJourneySlice } from './types';



const initialState: ShoppingJourneySlice = {
  shoppingJourney: null,
  shoppingJourneyLoading: true,
  filters: {
    markets: [],
    campaignObjectives: [],
  },
};

const intialStateFilter: ShoppingJourneyFilterSlice = {
  ShoppingJourneyFilter: null,
  ShoppingJourneyFilterLoading: true,
  filters:{
    markets:[],
    campaignObjectives:[]
  }
}

export const shoppingJourneySlice = createSlice({
  name: 'shopping-journey',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<ShoppingJourneyFilters>) => {
      state.filters = action.payload;
    },
    processGetShoppingJourney: state => {
      state.shoppingJourneyLoading = true;
    },
    shoppingJourneySuccess: (state, action: PayloadAction<ShoppingJourney>) => {
      state.shoppingJourney = action.payload;
      state.shoppingJourneyLoading = false;
    },
    shoppingJourneyFailure: state => {
      state.shoppingJourneyLoading = false;
    },
  },
});

export const shoppingJourneyFilterSlice = createSlice({
  name:'shopping-journey-filter',
  initialState: intialStateFilter,
  reducers:{
    setFilterFilters: (state, action: PayloadAction<ShoppingJourneyFilters>) => {
      state.filters = action.payload;
    },
    processGetShoppingFilterJourney: state => {
      state.ShoppingJourneyFilterLoading = true;
    },
    shoppingJourneyFilterSuccess: (state, action: PayloadAction<ShoppingJourneyFilter>) => {
      state.ShoppingJourneyFilter = action.payload;
      state.ShoppingJourneyFilterLoading= false;
    },
    shoppingJourneyFilterFailure: state => {
      state.ShoppingJourneyFilterLoading = false;
    },
  },

});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverallTrendPerformanceData } from 'types/overall-trend-performance/overall-trend-performance';

import { OverallTrendSlice } from './types';

const initialState: OverallTrendSlice = {
  overallTrendData: null,
  loading: true,
};

export const overallTrendSlice = createSlice({
  name: 'overallTrend',
  initialState,
  reducers: {
    processGetOverallTrendData: state => {
      state.loading = true;
    },
    getOverallTrendDataSucces: (state, action: PayloadAction<OverallTrendPerformanceData>) => {
      state.overallTrendData = action.payload;
      state.loading = false;
    },
    getOverallTrendDataFailure: state => {
      state.loading = false;
    },
  },
});

import { facebookApi } from 'api/facebook';
import { AppDispatch } from 'store/store';
import { GetSystemAccessIdParams, LLOrganicTokenParams } from 'types/connectors/connectors';
import { MetaOrganicMetadata, SelectedFeatures } from 'types/connectors/facebook-data';

export const getInstallLink = (isForOrganic: boolean = false) => async () => {
  const { data } = await facebookApi.getInstallLink(isForOrganic);

  return data.result;
};

export const getAdAccount = (systemAccessId: string) => async () => {
  const { data } = await facebookApi.getAdAccount(systemAccessId);

  return data.result.map((d: any) => ({ id: d.id, value: d.name }));
};

export const getSystemAccessId = (params: GetSystemAccessIdParams) => async () => {
  const { data } = await facebookApi.getSystemAccessId(params);

  return data.result;
};

export const saveSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeatures) =>
  async () => {
    const { data } = await facebookApi.saveSelection(clientId, clientBrandId, clientBrandStoreId, selectedFeatures);

    return data.result;
  };

  //meta organic specifici
export const processLLOrganicAT = (params: LLOrganicTokenParams) => async() => {
  const {data} = await facebookApi.processLLOrganicAT(params);

  return data.result;
};

 export const getOrganicAccount = (systemAccessId: string) => async () => {
    const { data } = await facebookApi.getOrgnaicAccount(systemAccessId);

    return data.result.map((d: any) => ({ id: d.id, value: d.name,  accessToken:d.accessToken, username: d.username, instaId: (d.instagramBusinessAccount?.id ?? '') }));
  };

  export const SaveMetaOrganicFeatures = (  clientId: string,
    clientBrandId: string,
    clientBrandStoreId: string,
    selectedFeatures: MetaOrganicMetadata) => async() =>{
        const {data} = await facebookApi.SaveMetaOrganicFeatures(clientId, clientBrandId, clientBrandStoreId, selectedFeatures);

        return data.result;
    };
import { Button, Card, Box, Grid, MenuItem } from '@mui/material';
import { DatePicker, RangePicker } from 'components/date-picker';
import { DidYouKnow } from 'components/didYouKnow';
import { Select } from 'components/select';
import { useFormikContext } from 'formik';
import { useSelector } from 'hooks';
import { compares, periods } from 'mocks/marketing-analyzer';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import generatePDF, { Options } from 'react-to-pdf';
import { dashboardsSelectors, dashboardsSlice } from 'store/entities/dashboards';
import { get7DaysAgoDate } from 'utils/getSevenDayAgoDate';

import { datePickerFiltersStyles as S } from './styles';

export const ShopifyDashboardFiltersForm: FC = () => {
  const { submitForm, values } = useFormikContext();
  const options: Options = {
    filename: 'shopify-dashboard.pdf',
    page: {
      margin: 20,
    },
  };

  const date = useSelector(dashboardsSelectors.date);
  const endDate = useSelector(dashboardsSelectors.endDate);
  const compareStartDate = useSelector(dashboardsSelectors.compareStartDate);
  const compareEndDate = useSelector(dashboardsSelectors.compareEndDate);

  const sevenDaysAgoDate: Date = get7DaysAgoDate();
  const dispatch = useDispatch();

  const [compareValue, setCompareValue] = useState('');

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: date,
    endDate: endDate,
  });

  const [selectedCompareDateRange, setSelectedCompareDateRange] = useState({
    startDate: compareStartDate,
    endDate: compareEndDate,
  });

  const onChange = (
    startDate: Date,
    endDate: Date,
    compareStartDate: Date,
    compEndDate: Date,
    compareValue: string,
  ) => {
    setSelectedDateRange({ startDate: startDate, endDate: endDate });
    setSelectedCompareDateRange({ startDate: compareStartDate, endDate: compEndDate });
    setCompareValue(compareValue);
  };

  const submitWrapper = () => {
    dispatch(dashboardsSlice.actions.setDashboardsDate(selectedDateRange.startDate));
    dispatch(dashboardsSlice.actions.setDashboardsEndDate(selectedDateRange.endDate));
    dispatch(dashboardsSlice.actions.setDashboardsCompareStartDate(selectedCompareDateRange.startDate));
    dispatch(dashboardsSlice.actions.setDashboardsCompareEndDateDate(selectedCompareDateRange.endDate));

    // Call submitForm with the necessary parameters
    submitForm();
  };

  const getTargetElement = () => document.getElementById('shopify-container');

  const downloadPdf = (event: any) => {
    event.target.style.display = 'none';
    const main = document.getElementById('shopify-container');

    const divContainer = document.createElement('div');
    divContainer.style.marginTop = '1em';
    divContainer.style.textAlign = 'center';

    const img = document.createElement('img');
    img.style.width = '300px';
    img.src = '/static/images/PoweredByVennflow.svg';

    divContainer.appendChild(img);
    main.appendChild(divContainer);

    generatePDF(getTargetElement, options);

    divContainer.removeChild(img);
    main.removeChild(divContainer);

    event.target.style.display = 'block';
  };
  return (
    <Card style={{ border: '2px solid #f0f1f6' }}>
      <S.FiltersGroup display="flex" justifyContent="end">
        <Button onClick={downloadPdf}>Download PDF</Button>
      </S.FiltersGroup>
      <Grid item xs={12} lg={12} display="flex" justifyContent="flex-end" style={{ paddingBottom: '1.5em' }}>
        <Grid item xs={4} sx={{ display:'flex', flexDirection:'column', alignItems:'stretch'}}>
          <DidYouKnow />
        </Grid>
        <Grid item xs={7} display="flex" direction="column" justifyContent="flex-end" alignContent="flex-end">
          <RangePicker onChange={onChange} showCompareTo />
        </Grid>
        <Grid item xs={1} display="flex" direction="column" justifyContent="flex-end">
          <Button onClick={submitWrapper} style={{ paddingBottom: '0' }}>
            Apply
          </Button>
        </Grid>
        {/* </S.FiltersGroup> */}
      </Grid>
    </Card>
  );
};

import { Grid, Box, Typography } from '@mui/material';
import { LineChart } from 'components/charts';
import { StatisticsCard } from 'components/statistics-card';
import { useSelector } from 'hooks';
import _, { values } from 'lodash';
import { FC } from 'react';
import { userSelectors } from 'store/entities/user';
import { Statistic } from 'types/shopify/shopify';
import { concatShopifyChartDataSets } from 'utils/concat-chart-data-sets';
import { formatNumber, currencyLabel } from 'utils/numbers';

interface Props {
  data: Statistic;
}
interface LinaChartData {
  period: string;
  name?: string;
  value: number;
}

export const StatisticsSection: FC<Props> = ({ data }) => {
  const { revenue, cancelled_orders, digitalAdSpend, roas, aov, upt } = data;

  const user = useSelector(userSelectors.user);

  const renderNoData = () => (
    <Box minHeight="120px" height="120px" borderColor="red" borderBottom="1" borderTop="1">
      <Typography variant="h6" height="100%" mt={4}>
        No Data
      </Typography>
    </Box>
  );
  const renderLineChart = (data: LinaChartData[], tooltipSymbol: string = '', tooltipBeforeSymbol: string = '') => (
    <LineChart
      data={data || []}
      xDataKey="period"
      yDataKey="value"
      groupBy="name"
      dimensions={{ height: 120, width: '100%' }}
      hideLegend
      hideYAxis
      dashArray={['4 4', null]}
      tooltipBeforeSymbol={tooltipBeforeSymbol}
      tooltipAfterSymbol={tooltipSymbol}
      showDate={false}
    />
  );
  return (
    <Grid container item lg={12} xs={12} spacing={2}>
      <Grid item xs={1} />
      <Grid item lg={2} xs={2}>
        <StatisticsCard
          name={'Sales'}
          tooltip="Sales order value including Tax after Discounts are applied"
          value={`${currencyLabel(user.brandStoreList[0].StoreCurrency)}${formatNumber(revenue?.value, 0)}`}
          description={`Compare To: ${currencyLabel(user.brandStoreList[0].StoreCurrency)}${formatNumber(
            revenue?.prevValue,
            0,
          )}\n(${formatNumber(revenue?.prevValueCompare ?? 0, 1)}%)`}
          variant={revenue?.prevValueCompare ?? 0 >= 0 ? 'positive' : 'negative'}
          customChart={
            revenue?.prevDataset != null && revenue?.dataSet != null
              ? renderLineChart(
                  concatShopifyChartDataSets(
                    revenue?.prevDataset ?? null,
                    revenue?.dataSet?.map(a => ({ date: a?.date ?? '', value: a?.value ?? 0 })),
                  ),
                )
              : renderNoData()
          }
        />
      </Grid>

      <Grid item lg={2} xs={2}>
        <StatisticsCard
          name={'AOV'}
          tooltip="Average Order Value."
          value={`${currencyLabel(user.brandStoreList[0].StoreCurrency)}${formatNumber(aov?.value, 0)}`}
          description={`Compare To: ${currencyLabel(user.brandStoreList[0].StoreCurrency)}${formatNumber(
            aov?.prevValue,
            0,
          )}\n(${formatNumber(aov?.prevValueCompare, 1)}%)`}
          variant={aov?.prevValueCompare >= 0 ? 'positive' : 'negative'}
          customChart={
            aov?.prevDataset != null && aov?.dataSet != null
              ? renderLineChart(concatShopifyChartDataSets(aov?.prevDataset ?? null, aov?.dataSet ?? null))
              : renderNoData()
          }
        />
      </Grid>

      <Grid item lg={2} xs={2}>
        <StatisticsCard
          name={'Cancelled Orders'}
          tooltip="The number of sales orders that were cancelled"
          value={`${formatNumber(cancelled_orders?.value, 0)}`}
          description={`Compare To: ${formatNumber(cancelled_orders?.prevValue, 0)}\n(${formatNumber(
            cancelled_orders?.prevValueCompare,
            1,
          )}%)`}
          variant={cancelled_orders?.prevValueCompare >= 0 ? 'positive' : 'negative'}
          customChart={
            cancelled_orders?.prevDataset != null && cancelled_orders?.dataSet != null
              ? renderLineChart(
                  concatShopifyChartDataSets(cancelled_orders?.prevDataset ?? null, cancelled_orders?.dataSet ?? null),
                )
              : renderNoData()
          }
        />
      </Grid>

      <Grid item lg={2} xs={2}>
        <StatisticsCard
          name={'Ad Spend'}
          tooltip="The value spent on Ads"
          value={`${currencyLabel(user.brandStoreList[0].StoreCurrency)}${formatNumber(digitalAdSpend?.value, 0)}`}
          description={`Compare To: ${currencyLabel(user.brandStoreList[0].StoreCurrency)}${formatNumber(
            digitalAdSpend?.prevValue,
            0,
          )}\n(${formatNumber(digitalAdSpend?.prevValueCompare, 1)}%)`}
          variant={digitalAdSpend?.prevValueCompare >= 0 ? 'positive' : 'negative'}
          customChart={
            digitalAdSpend?.prevDataset != null && digitalAdSpend?.dataSet != null
              ? renderLineChart(
                  concatShopifyChartDataSets(digitalAdSpend?.prevDataset ?? null, digitalAdSpend?.dataSet ?? null),
                )
              : renderNoData()
          }
        />
      </Grid>
      {/* 
      <Grid item lg={2} xs={2}>
        <StatisticsCard
          name={'MER'}
          tooltip='Marketing Efficiency Ratio'
          value={`${formatNumber(roas?.value, 1)}`}
          description={`Compare To: ${formatNumber(roas?.prevValue, 1)}\n(${formatNumber(roas?.prevValueCompare, 1)}%)`}
          variant={roas?.prevValueCompare >= 0 ? 'positive' : 'negative'}
          customChart={
            roas?.prevDataset != null && roas?.dataSet != null
              ? renderLineChart(concatShopifyChartDataSets(roas?.prevDataset ?? null, roas?.dataSet ?? null))
              : renderNoData()
          }
        />
      </Grid> */}

      <Grid item lg={2} xs={2}>
        <StatisticsCard
          name={'UPT'}
          tooltip="The average number of items per Sales Order"
          value={`${formatNumber(upt?.value, 1)}`}
          description={`Compare To: ${formatNumber(upt?.prevValue, 1)}\n(${formatNumber(upt?.prevValueCompare, 1)}%)`}
          variant={upt?.prevValueCompare >= 0 ? 'positive' : 'negative'}
          customChart={
            upt?.prevDataset != null && upt?.dataSet != null
              ? renderLineChart(concatShopifyChartDataSets(upt?.prevDataset ?? null, upt?.dataSet ?? null))
              : renderNoData()
          }
        />
      </Grid>
    </Grid>
  );
};

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Drawer, alpha, Divider, useTheme, lighten, darken, IconButton } from '@mui/material';
import { Logotype } from 'components/logotype';
import { Scrollbars } from 'components/scrollbars';
import { useDispatch, useSelector } from 'hooks';
import { sidebarSelectors, sidebarSlice } from 'store/entities/sidebar';
import { userSelectors } from 'store/entities/user';

import { SidebarFooter } from './SidebarFooter/SidebarFooter';
import { SidebarMenu } from './SidebarMenu/SidebarMenu';
import { SidebarWrapper, SizeButtonGroup } from './styles';

export const Sidebar = () => {
  const theme = useTheme();

  const opened = useSelector(sidebarSelectors.opened);
  const maximized = useSelector(sidebarSelectors.maximized);
  
  const dispatch = useDispatch();

  const handleCloseSidebar = () => dispatch(sidebarSlice.actions.close());

  const handleToggleSidebarSize = () => dispatch(sidebarSlice.actions.toggleSize());

  const user = useSelector(userSelectors.user);
  return (
    <>
      <SidebarWrapper
        width={theme => (maximized ? theme.sidebar.width.maximized : theme.sidebar.width.minimized)}
        minWidth={theme => (maximized ? theme.sidebar.width.maximized : theme.sidebar.width.minimized)}
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.sidebar.background, 0.1), 0.5)
              : theme.sidebar.background,
          boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
        }}
      >
        <Box position="relative">
          <Box
            p={2}
            sx={{
              width: 52,
            }}
          >
            <Logotype maximized={maximized} />
            <SizeButtonGroup>
              <IconButton color="primary" onClick={handleToggleSidebarSize}>
                {maximized ? (
                  <KeyboardArrowLeftIcon fontSize="inherit" />
                ) : (
                  <KeyboardArrowRightIcon fontSize="inherit" />
                )}
              </IconButton>
            </SizeButtonGroup>
          </Box>
        </Box>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <Scrollbars>

          <SidebarMenu ismarketing={user?.isNonRetail} maximized={maximized} companyName={user?.CompanyName} />
        </Scrollbars>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <SidebarFooter />
      </SidebarWrapper>

      {/* Mobile Menu Drawer */}
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={opened}
        onClose={handleCloseSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          width={theme => (maximized ? theme.sidebar.width.maximized : theme.sidebar.width.minimized)}
          minWidth={theme => (maximized ? theme.sidebar.width.maximized : theme.sidebar.width.minimized)}
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbars>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                <Logotype maximized />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu maximized ismarketing={user?.isNonRetail} companyName={user?.CompanyName}/>
          </Scrollbars>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

import { marketingoverviewApi } from 'api/marketing-overview/marketing';
import { AppDispatch } from 'store/store';

import { pyramidloaddataSlice } from './slice';

export const getPyramidUserData = (userId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await marketingoverviewApi.getPyramidUserData(userId);
    dispatch(pyramidloaddataSlice.actions.getloadDataSuccess(data.result));

    if (data?.result?.length > 0) {
      if (data.result[0].accessToken) {
        localStorage.setItem('pyramidToken', data.result[0].accessToken);
        localStorage.setItem('embedPyramidToken', data.result[0].embeddedUserAT);
      }
    }
  } catch {
    dispatch(pyramidloaddataSlice.actions.getloadDataFailure());

    onFailure();
  }
};

//getCLientloadStateData

import { isValid } from 'date-fns';

export const isDateString = (value: string) => {
  // new Date('123456') is valid date, new Date('1234567') - invalid
  if (value.length < 7 && !isNaN(+value)) {
    return false;
  }

  return isValid(new Date(value));
};

import { CSSProperties } from 'react';

const axisLabelStyles: CSSProperties = {
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontSize: '14px',
  fontWeight: '700',
};

const yAxisLabel: CSSProperties = {
  ...axisLabelStyles,
  textAnchor: 'middle'
};

export const chartStyles = {
  xAxisLabel: axisLabelStyles,
  yAxisLabel,
};

import { createSlice } from '@reduxjs/toolkit';

import { SidebarSlice } from './types';

const initialState: SidebarSlice = {
  opened: false,
  maximized: true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    open: state => {
      state.opened = true;
    },
    close: state => {
      state.opened = false;
    },
    toggleVisibility: state => {
      state.opened = !state.opened;
    },
    minimize: state => {
      state.maximized = false;
    },
    maximize: state => {
      state.maximized = true;
    },
    toggleSize: state => {
      state.maximized = !state.maximized;
    },
  },
});

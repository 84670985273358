import { FC } from 'react';

interface Props {
  value: number;
  hiddenLabel: boolean;
}

export const GeoCircle: FC<Props> = ({ value, hiddenLabel }) => (
  <>
    <circle fill="#00b2ff4c" stroke="#18a4e0" strokeWidth="3" r={value} />
    <text textAnchor="middle" stroke="#0000003" strokeWidth="5px" dy=".3em">
      {hiddenLabel ? '' : value + 'k'}
    </text>
  </>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { brainPollRtn, brainAssistantRtn, brainMessage } from 'types/brain/brain';

import { BrainPollAssistantSlice, BrainAssistantSlice } from './types';

const initialPollAssistantState: BrainPollAssistantSlice = {
    loading: true,
    waitingOnAssistant: true,
    brainAssistantPollData : null,    
  };

  const initialAssistantState: BrainAssistantSlice = {
    loading: true,
    brainAssistantData: null,
    brainMessages: [],
    lastMsgid:''
  };


export const brainPollAssistantSlice = createSlice({
    name: 'brainPollAssistant',
    initialState: initialPollAssistantState,
    reducers: {
      startProcess: state => {
        state.loading = true;
      },
      getPollAssistantDataSuccess: (state, action: PayloadAction<brainPollRtn>) => {
       // state. = action.payload;
        state.brainAssistantPollData = action.payload;
        state.waitingOnAssistant = action.payload.isLoading;
        state.loading = false;
      },
      getPollAssistantDataFailure: state => {
        state.loading = false;
      },
      cleansePollAssistantData: state => {
        state.brainAssistantPollData = null;
        state.waitingOnAssistant = false;
      },
    }
  });

  export const brainAssistantSlice = createSlice({
    name: 'brainAssistant',
    initialState: initialAssistantState,
    reducers: {
      startProcess: state => {
        state.loading = true;
      },
      getAssistantDataSuccess: (state, action: PayloadAction<brainAssistantRtn>) => {
       // state. = action.payload;
       state.brainAssistantData = action.payload;
        state.loading = false;
      },
      getAssistantDataFailure: state => {
        state.loading = false;
      },
      cleanseAssistantData: state => {
        state.brainAssistantData = null;
      },
      setBrainMessages: (state, action: PayloadAction<brainMessage[]>) => {
        state.brainMessages = action.payload;
      },
      cleanseBrainMessage:(state)=>{
        state.brainMessages = [];
        state.lastMsgid = '';
      },
      setBrainLastMsgId:(state, action: PayloadAction<string>) =>{
        state.lastMsgid = action.payload;
      }
    }
  });
import { format } from 'date-fns';
import { ChartData } from 'types/overview/overview';

export const concatChartDataSets = (
  firstDataSet: ChartData[],
  secondDataSet: ChartData[],
  prevSetName: string = 'Compare To',
  currentSetName: string = 'Current Period',
  minusedDays: number = 7,
) => {
  // Push the cloned object into an array
  const tempArray: ChartData[] = [];

  //if prev dataset exist
  if (firstDataSet) {
    //iterate in prev dataset add values in new array with dates of current
    firstDataSet.forEach((prevItem, index) => {
      let dtCurrent = '';

      //set date of curr
      if (secondDataSet[index]) {
        dtCurrent = secondDataSet[index].date;
      }
      tempArray.push({
        value: prevItem.value,
        date: dtCurrent,
      });
    });
  }

  const secondMappedDataSet =
    secondDataSet === null
      ? []
      : secondDataSet?.map(d => ({
          value: d.value,
          date: d.date ? format(new Date(d.date), 'dd-MMM') : '',
          originalDate: d.date ? format(new Date(d.date), 'dd-MMM') : '',

          name: currentSetName,
        }));

  const tempMappedPrvDataSet =
    tempArray != null
      ? tempArray.map(d => ({
          value: d.value,

          date: d.date ? format(new Date(d.date), 'dd-MMM') : '',
          originalDate: d.date ? format(new Date(d.date), 'dd-MMM') : '',

          name: prevSetName,
        }))
      : [];

  return [...tempMappedPrvDataSet, ...secondMappedDataSet];
};

export const concatShopifyChartDataSets = (
  firstDataSet?: ChartData[],
  secondDataSet?: ChartData[],
  prevSetName: string = 'Compare To',
  currentSetName: string = 'Current Period',
  minusedDays: number = 7,
) => {
  // Push the cloned object into an array
  const tempArray: ChartData[] = [];

  //if prev dataset exist
  if (firstDataSet) {
    //iterate in prev dataset add values in new array with dates of current
    firstDataSet.forEach((prevItem, index) => {
      let dtCurrent = '';

      //set date of curr
      if (secondDataSet[index]) {
        dtCurrent = secondDataSet[index].date;
      }
      tempArray.push({
        value: prevItem.value,
        date: dtCurrent,
      });
    });
  }

  const secondMappedDataSet =
    secondDataSet != null
      ? secondDataSet.map(d => ({
          value: d.value,

          period: d.date ? format(new Date(d.date), 'dd-MMM') : '',

          originalDate: d.date ? format(new Date(d.date), 'dd-MMM') : '',
          //period: format(new Date(d.date), 'dd-MMM'),
          name: currentSetName,
        }))
      : [];

  const tempMappedPrvDataSet =
    tempArray != null
      ? tempArray.map(d => ({
          value: d.value,

          period: d.date ? format(new Date(d.date), 'dd-MMM') : '',

          originalDate: d.date ? format(new Date(d.date), 'dd-MMM') : '',
          //period: format(new Date(d.date), 'dd-MMM'),
          name: prevSetName,
        }))
      : [];

  return [...tempMappedPrvDataSet, ...secondMappedDataSet];
};

export const concatOverviewChartDataSets = (
  firstDataSet?: ChartData[],
  secondDataSet?: ChartData[],
  prevSetName: string = 'Last',
  currentSetName: string = 'Current',
  minusedDays: number = 7,
) => {
  const firstMappedDataSet =
    firstDataSet != null
      ? firstDataSet.map(d => ({
          value: d.value,
          date: d.date ? format(new Date(d.date), 'dd-MMM') : '',
          name: prevSetName,
        }))
      : [];

  const secondMappedDataSet =
    secondDataSet != null
      ? secondDataSet.map(d => ({
          value: d.value,
          date: d.date
            ? format(
                new Date(new Date().setTime(new Date(d.date).getTime() - minusedDays * 24 * 60 * 60 * 1000)),
                'dd-MMM',
              )
            : '',
          //period: format(new Date(d.date), 'dd-MMM'),
          name: currentSetName,
        }))
      : [];

  return [...firstMappedDataSet, ...secondMappedDataSet];
};

import { TableContainer, Table, TableBody } from '@mui/material';
import { CompareTableHeader } from 'components/compare-table';
import { FC } from 'react';
import { TiktokLeadTableData } from 'types/Tiktok/Tiktok';

import { TiktokLeadTableRow } from './tiktok-table-row';
import { TiktokTableTotalRow } from './tiktok-table-total-row';

interface Props {
  currencyLabel:string;
  data: TiktokLeadTableData[];
  columns: string[];
  onClick?: (name: string) => void;

}

export const TiktokLeadTable: FC<Props> = ({ currencyLabel, data, columns,onClick }) => (
  <TableContainer>
    <Table size="small">
      <CompareTableHeader columns={columns} />
      <TableBody >
        {data.map((row, index) => (
          <TiktokLeadTableRow currencyLabel={currencyLabel} onClick={onClick} key={`tiktok-table-row-${index}`} row={row} data={data} />
        ))}

        <TiktokTableTotalRow  currencyLabel={currencyLabel} data={data} />
      </TableBody>
    </Table>
  </TableContainer>
);

import { Response } from 'types/api/api';
import { FeatureData, GetSystemAccessIdParams,LLOrganicTokenParams, DataReturn } from 'types/connectors/connectors';
import { SelectedFeatures, MetaOrganicMetadata } from 'types/connectors/facebook-data';

import { onboardingApi } from '../api';

const getInstallLink = (isForOrganic:boolean = false) =>
  onboardingApi.get(`FacebookConnector/AuthUrl?redirectUri=${(isForOrganic? process.env.REACT_APP_METAORGANIC_REDIRECT_URL : process.env.REACT_APP_FACEBOOK_REDIRECT_URL)}&isForOrganic=${isForOrganic}`);

const getAdAccount = (systemAccessId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(`facebookconnector/adAccounts/${systemAccessId}`);

const getSystemAccessId = (params: GetSystemAccessIdParams) =>
  onboardingApi.get('facebookconnector/exchangeAccessCodeForAccessToken', { params });

const saveSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeatures,
) =>
  onboardingApi.post<Response<FeatureData[]>>('facebookConnector/analyticsFeatures', {
    clientId,
    systemId: '1209606E-1F5A-4400-9CFA-59620E4D12C7',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'adAccountId',
        value: selectedFeatures.adAccount?.id,
      },
    ],
  });

  //TODO need to pass the params through, not doing it correctly at mo
//meta organic specific APIs
const processLLOrganicAT = (params: LLOrganicTokenParams)  =>
  onboardingApi.get(`FacebookConnector/ProcessLLOrganicToken?llac=${params.llac}&expiresAt=${params.expiresAt}&clientId=${params.clientId}&clientBrandId=${params.clientBrandId}&clientBrandStoreId=${params.clientBrandStoreId}`)

const getOrgnaicAccount = (systemAccessId: string) =>
  onboardingApi.get<Response<DataReturn[]>>(`facebookconnector/OrganicAccounts/${systemAccessId}`);

const SaveMetaOrganicFeatures =(
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: MetaOrganicMetadata,
) =>
  onboardingApi.post<Response<FeatureData[]>>('facebookConnector/analyticsFeatures', {
    clientId,
    systemId: '2C63E08F-EE99-48D3-A4BD-DCF5F55C304B',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'InstgramUserId',
        value: selectedFeatures.instagramUserId ?? '',
      },
      {
        id: 'InstagramUserName',
        value: selectedFeatures.instagramUsername  ?? '',
      },
      {
        id: 'FacebookPageId',
        value: selectedFeatures.facebookPageId ?? '',
      },
      {
        id: 'FacebookPageAccessToken',
        value: selectedFeatures.FacebookPageAccessToken ?? '',
      },
    ],
  });


export const facebookApi = {
  getInstallLink,
  getAdAccount,
  getSystemAccessId,
  saveSelection,
  processLLOrganicAT,
  getOrgnaicAccount,
  SaveMetaOrganicFeatures
};

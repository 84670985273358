export const dateFormmating = (date:Date, isStartDate:boolean = false,  customTime:boolean = false) => { 
    let rtnDate = date.getFullYear() +'-'+addZero((date.getMonth()+1)) +'-'+addZero(date.getDate());

    if(customTime)
        rtnDate = rtnDate +'T'+addZero(date.getHours())+':'+addZero(date.getMinutes())+':'+ addZero(date.getSeconds())+'Z';
    else 
        if(isStartDate)
            rtnDate = rtnDate + 'T00:00:00.000Z';
        else
            rtnDate = rtnDate + 'T23:59:59.000Z';

    return rtnDate;
};

export const dateFormmatingExport = (date:Date) => { 
    let rtnDate = date.getFullYear() +'-'+addZero((date.getMonth()+1)) +'-'+addZero(date.getDate());

    rtnDate = rtnDate +'_'+addZero(date.getHours())+':'+addZero(date.getMinutes())+':'+ addZero(date.getSeconds())+'';
    
    return rtnDate;
};

export const dateFormatForDisplay = (date:Date) => { 
    date = new Date(date);
    const rtnDate = date.getFullYear() +'-' + addZero((date.getMonth()+1)) +'-'+addZero(date.getDate()) + ' ' + addZero(date.getHours())+':'+addZero(date.getMinutes())+':'+ addZero(date.getSeconds());
    return rtnDate;
};



const addZero = (i:number) => {
    return i< 10 ? '0'+i.toString() : i.toString();
}

import { useSelector } from 'hooks';
import { FC } from 'react';
import { routes } from 'router/routes';
import { userSelectors } from 'store/entities/user';

import { logotypeStyles as S } from './styles';

const logo = {
  maximized: '/static/images/vennflow-logo.svg',
  minimized: '/static/images/vennflow-logo-minimized.svg',
};

interface Props {
  maximized?: boolean;
}
export const Logotype: FC<Props> = ({ maximized = false }) => (
  <S.LogoLink to={useSelector(userSelectors.isMarketingOnly) ? routes.marketingoverview.url : routes.overview.url}>
    <S.LogoGroup>
      <img src={maximized ? logo.maximized : logo.minimized} alt="Vennflow Logo" />
    </S.LogoGroup>
  </S.LogoLink>
);

import { MarketingAnalyzerState } from './types';

const brands = (state: MarketingAnalyzerState) => state.marketingAnalyzer.brands;

const brandsLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.brandsLoading;

const markets = (state: MarketingAnalyzerState) => state.marketingAnalyzer.markets;

const marketsLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.marketsLoading;

const objectives = (state: MarketingAnalyzerState) => state.marketingAnalyzer.objectives;

const objectivesLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.objectivesLoading;

const channels = (state: MarketingAnalyzerState) => state.marketingAnalyzer.channels;

const channelsLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.channelsLoading;

const campaigns = (state: MarketingAnalyzerState) => state.marketingAnalyzer.campaigns;

const campaignsLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.campaignsLoading;

const campaign = (state: MarketingAnalyzerState) => state.marketingAnalyzer.campaign;

const campaignLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.campaignLoading;

const filters = (state: MarketingAnalyzerState) => state.marketingAnalyzer.filters;

const selectedCampaign = (state: MarketingAnalyzerState) => state.marketingAnalyzer.selectedCampaign;

const selectedAd = (state:MarketingAnalyzerState) => state.marketingAnalyzer.selectedAd;

const ads = (state: MarketingAnalyzerState) => state.marketingAnalyzer.ads;

const ad = (state: MarketingAnalyzerState) => state.marketingAnalyzer.ad;

const adsLoading = (state: MarketingAnalyzerState) => state.marketingAnalyzer.adsLoading;



export const marketingAnalyzerSelectors = {
  brands,
  brandsLoading,
  markets,
  marketsLoading,
  objectives,
  objectivesLoading,
  channels,
  channelsLoading,
  campaigns,
  campaignsLoading,
  campaign,
  campaignLoading,
  filters,
  selectedCampaign,
  ad,
  ads,
  adsLoading,
  selectedAd,
};

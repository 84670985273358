import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Console } from 'console';
import { PriorityLandingPageDataObj,PriorityLandingPageDataRtn, PriorityLandingPageSlice } from 'types/priorityLandingPage/priorityLandingPage'

const initialState: PriorityLandingPageSlice = {
    priority_landing_pages_data: null,
    loading: true
  };
    
export const priorityLandingPagesSlice = createSlice({
    name: 'priority-landing-pages',
    initialState,
    reducers: {
      processGetPriorityLandingPagesData: state => {
        state.loading = true;
      },
      getPriorityLandingPagesDataSuccess: (state, action: PayloadAction<PriorityLandingPageDataRtn>) => {
        state.priority_landing_pages_data = action.payload;
        state.loading = false;
      },
      getPriorityLandingPagesDataFailure: state => {
        state.loading = false;
      },
    },
  });
import { createSlice } from '@reduxjs/toolkit';

import { TiktokSlice } from './types';

const initialState: TiktokSlice = {};

export const tiktokSlice = createSlice({
  name: 'tiktok',
  initialState,
  reducers: {},
});

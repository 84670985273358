import { overallTrendApi } from 'api/overall-trend';
import { AppDispatch } from 'store/store';
import { GetOverallTrendPerformanceRequest } from 'types/overall-trend-performance/overall-trend-performance';

import { overallTrendSlice } from './slice';

export const getOverallTrendPerformanceData =
  (params: GetOverallTrendPerformanceRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(overallTrendSlice.actions.processGetOverallTrendData());
    try {
      const { data } = await overallTrendApi.getOverallTrendPerformance(params);

      dispatch(overallTrendSlice.actions.getOverallTrendDataSucces(data.overallTrendPerformance));

      return data;
    } catch {
      dispatch(overallTrendSlice.actions.getOverallTrendDataFailure());

      onFailure();
    }
  };

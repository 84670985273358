import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { formatCamelCase } from 'utils/formatCamelCase';
import { formatNumber } from 'utils/numbers';

import { tickFormatter as formatDate } from '../helpers/tick-formatter';

interface PayloadMapper{
  value: string,
  color:string,
}

interface Props extends TooltipProps<ValueType, NameType> {
  tooltipBeforeSymbol?: string[] | string;
  tooltipAfterSymbol?: string[] | string;
  customYAxisLabel?: string;
  showDate: boolean;
  customPayload?:PayloadMapper[];
}

export const CustomTooltip: FC<Props> = ({
  active,
  payload,
  label,
  tooltipBeforeSymbol = '',
  tooltipAfterSymbol = '',
  customYAxisLabel: customYAxisLabel = '',
  showDate = true,
  customPayload,
}) => {
  if (active && payload && payload.length) {
    const tooltipBeforeSymbols = Array.isArray(tooltipBeforeSymbol)
      ? tooltipBeforeSymbol
      : new Array(payload.length).fill(tooltipBeforeSymbol);

    const tooltipAfterSymbols = Array.isArray(tooltipAfterSymbol)
      ? tooltipAfterSymbol
      : new Array(payload.length).fill(tooltipAfterSymbol);
    return (
      <Box bgcolor="white" p="0.5rem" border="1px solid black">
        <Typography color="primary">
          {/*handle removal of date from spike line tooltips  */
            showDate === false && ( payload[0].payload.originalDate !== undefined ? formatDate(payload[0].payload.originalDate) : formatDate(payload[0].payload.period))
          }
          
          {showDate === true && ((payload[0].payload.date !== undefined ? formatDate(payload[0].payload.date) : formatDate(payload[0].payload.period)) ||
            label ||
            ((customYAxisLabel == '' || !customYAxisLabel) ? (showDate ? payload[0].payload.name : '') : (showDate ? customYAxisLabel:'')))}
        </Typography>
        {payload.map((d: any, index) => (
          <Typography key={d.name} color={d.color}>
            {`${(customYAxisLabel == '' || !customYAxisLabel) ? ((customPayload !== undefined) ? customPayload[index].value: formatCamelCase(d?.name)) : customYAxisLabel} : ${
              tooltipBeforeSymbols[index]==undefined?'': tooltipBeforeSymbols[index]
            }${formatNumber(d?.value)} ${tooltipAfterSymbols[index]}`}
          </Typography>
        ))}
      </Box>
    );
  }

  return null;
};

import { overviewApi } from 'api/overview';
import { profitabilityApi } from 'api/profitability';
import { AppDispatch } from 'store/store';
import { GetOverviewRequest } from 'types/overview/overview';

import { overviewSlice } from './slice';

export const getOverviewData = (params: GetOverviewRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(overviewSlice.actions.processGetOverviewData());

  try {
    const { data } = await overviewApi.getOverviewData(params);

    dispatch(overviewSlice.actions.getOverviewDataSuccess(data.overview));
  } catch {
    dispatch(overviewSlice.actions.getOverviewDataFailure());

    onFailure();
  }
};

export const getProductData = (clientId: string, onFailure: () => void) => async () => {
  try {
    const { data } = await profitabilityApi.getProduct(clientId);
    return data;
  } catch {
    onFailure();
  }
};

export const getBreakeven =
  (Product: string, fromDate: string, toDate: string, clientId: string, onFailure: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await profitabilityApi.getBreakeven(Product, fromDate, toDate, clientId);
      return data;
    } catch {
      onFailure();
    }
  };

//Breakeven/GetBreakeven

//profitabilityApi

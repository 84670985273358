import { Response } from 'types/api/api';
import { FeatureData } from 'types/connectors/connectors';
import { SelectedFeatures } from 'types/connectors/facebook-data';
import { GetSystemAccessIdTiktokParams } from 'types/connectors/tiktok';
import { AudienceOverview, AudienceOverviewResponseData, TiktokDashboardRequest } from 'types/Tiktok/Tiktok';

import { onboardingApi, vennFlowApi } from '../api';

const getInstallLink = () =>
  onboardingApi.get(`TikTokConnector/AuthUrl?redirectUrl=${process.env.REACT_APP_TIKTOK_REDIRECT_URL}`);

const getSystemAccessId = (params: GetSystemAccessIdTiktokParams) =>
  onboardingApi.get(`TikTokConnector/ExchangeAccessCodeForAccessToken/${params.accessCode}`, {
    params: {
      clientBrandId: params.clientBrandId,
      clientBrandStoreId: params.clientBrandStoreId,
      redirectUrl: params.redirectUrl,
      clientId: params.clientId,
    },
  });

const getAdAccount = (systemAccessId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(`TikTokConnector/adAccounts/${systemAccessId}`);

// TODO update types below
const saveSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeatures,
) =>
  onboardingApi.post<Response<FeatureData[]>>('TikTokConnector/AnalyticsFeatures', {
    clientId,
    systemId: '0E393BDF-7D35-435B-9BE4-ED9B89F8E35A',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'advertiserId',
        value: selectedFeatures.adAccount?.id,
      },
    ],
  });

// Get Audience Overview from endpoint
const getTiktokDashboard = (params: TiktokDashboardRequest) =>
  //vennFlowApi.get<Response<AudienceOverviewResponseData[]>>(`TiktokDashboard/TiktokDashboard?DaysFilter=${daysFilter}&Date=${new Date(date).toISOString().split('T')[0]}`);
  vennFlowApi.get<{ audienceOverview: AudienceOverview }>('TiktokDashboard/TiktokDashboard', { params });

//
export const tiktokApi = {
  getInstallLink,
  getAdAccount,
  getSystemAccessId,
  saveSelection,
  getTiktokDashboard,
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AudienceOverview, AudienceOverviewResponseData } from 'types/Tiktok/Tiktok';

import { TiktokSlice } from './types';

const initialState: TiktokSlice = {
  tiktokdashboardData: null,
  tiktokdashboardLoading: false
};

export const tiktokDashboardSlice = createSlice({
  name: 'tiktok-dashboard',
  initialState,
  reducers: {
    processGettiktokdashboardData: state => {
      state.tiktokdashboardLoading = true;
    },
    tiktokdataSuccess: (state, action: PayloadAction<AudienceOverview>) => {
      state.tiktokdashboardData = action.payload;
      state.tiktokdashboardLoading = false;
    },
    tiktokdataFailure: state => {
      state.tiktokdashboardLoading = false;
    },
  },
});

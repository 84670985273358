import { QuestionMark, Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { FC } from 'react';

type Variant = 'question' | 'info';

type Size = 'small' | 'medium' | 'large';

interface Props {
  text: string;
  variant?: Variant;
  size?: Size;
}

export const Hint: FC<Props> = ({ text, variant = 'question', size = 'medium' }) => (
  <Tooltip title={text}>{variant === 'question' ? <QuestionMark fontSize={size} /> : <Info fontSize={size} />}</Tooltip>
);

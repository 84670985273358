import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RevenueSpendData, RevenueSpendFilters } from 'types/revenue-spend/revenue-spend';

import { RevenueSpendSlice } from './types';

const initialState: RevenueSpendSlice = {
  revenueSpendData: null,
  loading: true,
  filters: {
    channel: '',
  },
};

export const revenueSpendSlice = createSlice({
  name: 'revenueSpend',
  initialState,
  reducers: {
    processGetRevenueSpendData: state => {
      state.loading = true;
    },
    getRevenueSpendDataSuccess: (state, action: PayloadAction<RevenueSpendData>) => {
      state.revenueSpendData = action.payload;
      state.loading = false;
    },
    getRevenueSpendDataFailure: state => {
      state.loading = false;
    },

    setFilters: (state, action: PayloadAction<RevenueSpendFilters>) => {
      state.filters = action.payload;
    },
  },
});

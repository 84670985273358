import { useSelector } from 'hooks';
import Login from 'pages/auth/Login';
import Unauthorized from 'pages/auth/unauthorized';
import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { routes } from 'router/routes';
import { userSelectors } from 'store/entities/user';

interface Props {
  children: ReactNode;
}

export const PrivateRoute: FC<Props> = ({ children }) => {
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);
  const location = useLocation();
  const dataLoaded: boolean = useSelector(userSelectors.user)?.isDataLoad ?? false ? true : false;
  const isMarketing: boolean = useSelector(userSelectors?.isMarketingOnly) ?? false ? true : false;
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    //return <Login />;

    return <Unauthorized />;
  }

  //check for is allowed path name when data is loading
  let validPathName = false;
  let isRedirectUrl = false;
  let isMangementPath = false;

  //Verify if its a redirect route
  if (location.pathname.includes('Redirect')) isRedirectUrl = true;

  //Verify if its a Management route
  if (
    location.pathname == routes.settings.url ||
    location.pathname == routes.dataExport.url ||
    location.pathname == routes.userManagement.url ||
    location.pathname == routes.support.url
  )
    isMangementPath = true;

  if (location.pathname == routes.dataloading.url || isMangementPath || isRedirectUrl) validPathName = true;

  if (!validPathName && !dataLoaded) return <Navigate to={routes.dataloading.url} />;

  //is a client trying to access data loading page when they have data loaded? if so push them to overview
  if (location.pathname == routes.dataloading.url && dataLoaded) {
    return <Navigate to={isMarketing ? routes.marketingoverview.url : routes.overview.url} />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Brand,
  Market,
  CampaignsData,
  AdsData,
  AdsBubbleChartData,
  ChannelsData,
  Objective,
  CampaignData,
  MarketingAnalyzerFilters,
} from 'types/marketing-analyzer/marketing-analyzer';

import { MarketingAnalyzerSlice } from './types';

const initialState: MarketingAnalyzerSlice = {
  brands: [],
  brandsLoading: false,
  markets: [],
  marketsLoading: false,
  objectives: [],
  objectivesLoading: false,
  channels: null,
  channelsLoading: false,
  campaigns: null,
  campaignsLoading: false,
  campaign: null,
  campaignLoading: false,
  filters: {
    brands: [],
    markets: [],
    campaignObjectives: [],
    date: null,
    endDate: null ,
    compareStartDate: null,
    compareEndDate: null,
    period: 'Week to Date',
    compareTo: 'Last Period',
  },
  selectedCampaign: '',
  ads:null,
  ad:null,
  adsLoading:false,
  selectedAd: '',
  selectedCampaignByIdName: '',
};

export const marketingAnalyzerSlice = createSlice({
  name: 'marketing-analyzer',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<MarketingAnalyzerFilters>) => {
      state.filters = action.payload;
    },
    processGetBrands: state => {
      state.brandsLoading = true;
    },
    getBrandsSuccess: (state, action: PayloadAction<Brand[]>) => {
      state.brands = action.payload;
      state.brandsLoading = false;
    },
    getBrandsFailure: state => {
      state.brandsLoading = false;
    },

    processGetMarkets: state => {
      state.marketsLoading = true;
    },
    getMarketsSuccess: (state, action: PayloadAction<Market[]>) => {
      state.markets = action.payload;
      state.marketsLoading = false;
    },
    getMarketsFailure: state => {
      state.marketsLoading = false;
    },

    processGetObjectives: state => {
      state.objectivesLoading = true;
    },
    getObjectivesSuccess: (state, action: PayloadAction<Objective[]>) => {
      state.objectives = action.payload;
      state.objectivesLoading = false;
    },
    getObjectivesFailure: state => {
      state.objectivesLoading = false;
    },

    processGetChannels: state => {
      state.channelsLoading = true;
    },
    geChannelsSuccess: (state, action: PayloadAction<ChannelsData>) => {
      state.channels = action.payload;
      state.channelsLoading = false;
    },
    getChannelsFailure: state => {
      state.channelsLoading = false;
    },

    processGetCampaigns: state => {
      state.campaignsLoading = true;
    },
    geCampaignsSuccess: (state, action: PayloadAction<CampaignsData>) => {
      state.campaigns = action.payload;
      state.campaignsLoading = false;
    },
    getCampaignsFailure: state => {
      state.campaignsLoading = false;
    },

    processGetCampaign: state => {
      state.campaignLoading = true;
    },
    geCampaignSuccess: (state, action: PayloadAction<CampaignData>) => {
      state.campaign = action.payload;
      state.campaignLoading = false;
    },
    getCampaignFailure: state => {
      state.campaignLoading = false;
    },
    setSelectedCampaign: (state, action: PayloadAction<string>) => {
      state.selectedCampaign = action.payload;
    },
   
    processGetAds: state => {
      state.adsLoading = true;
    },
    geAdsSuccess: (state, action: PayloadAction<AdsData>) => {
      state.ads = action.payload;
      state.adsLoading = false;
    },
    getAdsFailure: state => {
      state.adsLoading = false;
    },
    geAdSuccess: (state, action: PayloadAction<AdsBubbleChartData>) =>{
        state.ad = action.payload;
        state.adsLoading = false;
    },
    setSelectAdsByCampaignIdName:(state, action: PayloadAction<string>) => {
      state.selectedCampaignByIdName = action.payload;
    },

    setSelectedAd: (state, action: PayloadAction<string>) => {
      state.selectedAd = action.payload;
    },
  },
});

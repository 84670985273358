import { DatePicker,PickersDay, PickersDayProps} from '@mui/lab';
import { styled } from '@mui/material';

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isSelectedDay:boolean,
  isCurrentWeekMemberDate:boolean,
  isPrevWeekMemberDate:boolean,
  isStartDateForCurrentWeek:boolean,
  isEndDateForCurrentWeek:boolean,
  isStartDateForPrevWeek:boolean,
  isEndDateForPrevWeek:boolean,
}

//need to style 

const datePickerSelectPeriod = styled(PickersDay,{
  shouldForwardProp: prop => prop !== 'isCurrentWeekMemberDate' && prop !== 'isPrevWeekMemberDate' &&  prop !== 'isStartDateForPrevWeek'
  &&  prop !== 'isEndDateForPrevWeek'
  &&  prop !== 'isStartDateForCurrentWeek'
  &&  prop !== 'isEndDateForCurrentWeek'
  &&  prop !== 'isSelectedDay'

  ,
})<CustomPickerDayProps>
(
  ({isSelectedDay,isCurrentWeekMemberDate, isPrevWeekMemberDate,isStartDateForCurrentWeek,isEndDateForCurrentWeek,isStartDateForPrevWeek,isEndDateForPrevWeek}) =>({
    ...(isCurrentWeekMemberDate && {
      borderRadius:0,
      backgroundColor:'#f7b500',
      color:'white !important'
    }),
    ...(isSelectedDay &&{
      backgroundColor:'#f7b500 !important',
      fontWeight:'normal !important',
      color:'white !important'
    }
    ),
    ...(isPrevWeekMemberDate && {
      borderRadius:0,
      backgroundColor:'#00b2ff',
      color:'white !important'
    }),
    ...((isStartDateForCurrentWeek || isStartDateForPrevWeek) && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...((isEndDateForCurrentWeek || isEndDateForPrevWeek) && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    })
  })
) as React.ComponentType<CustomPickerDayProps>;

export const datePickerSelectStyles = {
    datePickerSelectPeriod,
};

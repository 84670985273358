import { paidMarketingApi } from 'api/paid-marketing/paid-marketing';
import { AppDispatch } from 'store/store';
import {
  GetDailyDrillDownRequest,
  GetPaidMarketingDataRequest,
  GetPaidMarketingOverviewDataRequest
} from 'types/paid-marketing-performance/paid-marketing-performance';

import { paidMarketingSlice } from './slice';

export const getCountries = (onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(paidMarketingSlice.actions.processGetData());
  try {
    const { data } = await paidMarketingApi.getCountries();

    dispatch(paidMarketingSlice.actions.getCountriesSuccess(data.countries));

    return data;
  } catch {
    dispatch(paidMarketingSlice.actions.getDataFailure());

    onFailure();
  }
};

export const getPaidMarketingData =
  (params: GetPaidMarketingDataRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(paidMarketingSlice.actions.processGetData());
    try {
      const { data } = await paidMarketingApi.getPaidMarketingData(params);

      dispatch(paidMarketingSlice.actions.getPaidMarketingDataSuccess(data.paidMarketingPerformance));

      return data;
    } catch {
      dispatch(paidMarketingSlice.actions.getDataFailure());

      onFailure();
    }
  };

export const getPaidMarketingOverviewData =
  (params: GetPaidMarketingOverviewDataRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(paidMarketingSlice.actions.processGetData());
    try {
      const { data } = await paidMarketingApi.getPaidMarketingOverviewData(params);
      

      dispatch(paidMarketingSlice.actions.getPaidMarketingOnlyPiadMarketingDataSuccess(data));

      return data;
    } catch {
      dispatch(paidMarketingSlice.actions.getDataFailure());

      onFailure();
    }
  };

export const getChannelTableData =
  (params: GetDailyDrillDownRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(paidMarketingSlice.actions.processGetData());
    dispatch(paidMarketingSlice.actions.getChannelTableDataSuccess([]));
    try {
      const { data } = await paidMarketingApi.getChannelTableData(params);

      dispatch(paidMarketingSlice.actions.getChannelTableDataSuccess(data.dailyDrilldown.channelTableData));

      return data;
    } catch {
      dispatch(paidMarketingSlice.actions.getDataFailure());

      onFailure();
    }
  };

import max from 'lodash/max';
import min from 'lodash/min';

interface ReadyAreaScatterData extends Record<string, any> {
  max: number | null;
  min: number | null;
}

export const mapAreaScatterData = <T extends Record<string, any>>(data: T[], dataKey: keyof T) => {
  const value = data.map(d => d[dataKey]);
  const minValue = min(value);
  const maxValue = max(value);

  return data.reduce((dataset, d) => {
    const record = {
      ...d,
      [dataKey]: d[dataKey],
      min: d[dataKey] === minValue ? minValue : null,
      max: d[dataKey] === maxValue ? maxValue : null,
    };

    return [...dataset, record];
  }, [] as ReadyAreaScatterData[]);
};

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { brainPollAssistantSlice, brainAssistantSlice } from './entities/brain/brainAssistantSlice';
import { brainSlice } from './entities/brain/slice';
import { sqlBrainSlice } from './entities/brainSql/slice';
import { customerMarketingOnlySlice } from './entities/campaign';
import { companySlice } from './entities/company';
import { customerJourneyMarketingOnlySlice } from './entities/customer-journey-marketing-only/slice';
import { dashboardsSlice, marketingoverviewdashboardsSlice } from './entities/dashboards';
import { dataexportSlice } from './entities/exportcsv/slice';
import { facebookSlice } from './entities/facebook';
import { googleAnalyticsSlice } from './entities/google-analytics';
import { marketingAnalyzerSlice } from './entities/marketing-analyzer';
import { marketingloaddataSlice } from './entities/marketing-loaddata/slice';
import { marketingOnlyCustomerJourneySlice } from './entities/marketing-only-customer-journey';
import { marketingonlydetailSlice, marketingoverviewSlice } from './entities/marketing-overview';
import { overallTrendSlice } from './entities/overall-trend';
import { overviewSlice } from './entities/overview';
import { paidMarketingSlice } from './entities/paid-marketing';
import { plansSlice } from './entities/plans';
import { revenueSpendSlice } from './entities/revenue-spend';
import { shopifySlice } from './entities/shopify';
import { shoppingJourneySlice } from './entities/shoping-journey';
import { shoppingJourneyFilterSlice } from './entities/shoping-journey/slice';
import { sidebarSlice } from './entities/sidebar';
import { snapchatSlice } from './entities/snapchat';
import { tiktokSlice } from './entities/tiktok';
import { tiktokDashboardSlice } from './entities/tiktok-dashboard/slice';
import { userSlice } from './entities/user/slice';
import { pyramidSlice } from './entities/pyramid';
import { brainSqlSlice, brainPollResponseSqlSlice } from './entities/brainSql/brainSqlSlice';
import { priorityLandingPagesSlice } from './entities/priority-landing-page/slice'
import { pyramidloaddataSlice } from './entities/pyramid-loaddata';
import { organicSocialSlice } from './entities/OrganicSocial/slice'

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['user.accessToken'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['accessToken'],
};

export const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice.reducer),
  sidebar: sidebarSlice.reducer,
  company: companySlice.reducer,
  dashboards: dashboardsSlice.reducer,
  pyramid: pyramidSlice.reducer,

  marketingdashboard: marketingoverviewdashboardsSlice.reducer,
  facebook: facebookSlice.reducer,
  snapchat: snapchatSlice.reducer,
  tiktok: tiktokSlice.reducer,
  googleAnalytics: googleAnalyticsSlice.reducer,
  marketingAnalyzer: marketingAnalyzerSlice.reducer,
  overallTrend: overallTrendSlice.reducer,
  paidMarketing: paidMarketingSlice.reducer,
  plans: plansSlice.reducer,
  revenueSpend: revenueSpendSlice.reducer,
  shoppingJourney: shoppingJourneySlice.reducer,
  shoppingJourneyFilter: shoppingJourneyFilterSlice.reducer,
  overview: overviewSlice.reducer,
  marketingoverviewSlice: marketingoverviewSlice.reducer,
  marketingonlydetailSlice: marketingonlydetailSlice.reducer,
  shopifySlice: shopifySlice.reducer,
  customerJourneyMarketingOnly: customerJourneyMarketingOnlySlice.reducer,
  marketingcustomerJourney: marketingOnlyCustomerJourneySlice.reducer,
  campaign: customerMarketingOnlySlice.reducer,
  tiktokDashboard: tiktokDashboardSlice.reducer,
  marketingloaddataSlice: marketingloaddataSlice.reducer,
  dataexportSlice: dataexportSlice.reducer,
  brainSlice: brainSlice.reducer,
  sqlBrainSlice: sqlBrainSlice.reducer,
  brainPollResponseSqlSlice: brainPollResponseSqlSlice.reducer,
  brainSqlSlice: brainSqlSlice.reducer,
  brainPollAssistantSlice: brainPollAssistantSlice.reducer,
  brainAssistantSlice: brainAssistantSlice.reducer,
  priorityLandingPageSlice: priorityLandingPagesSlice.reducer,
  pyramidloaddataSlice: pyramidloaddataSlice.reducer,
  organicSocialSlice: organicSocialSlice.reducer
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

import { onboardingApi, vennFlowApi } from 'api/api';
import { Response } from 'types/api/api';

import {
  ExchangeAccessCodeRequest,
  CreateStoreRequest,
  EcommDashboardResponse,
  GetShopifyEcomDataRequest,
} from '../../types/shopify/shopify';

const getInstallLink = (storeName: string) =>
  onboardingApi.get<Response<{ url: string }>>(`ShopifyConnector/AuthUrl/${storeName}`);

const createStore = (data: CreateStoreRequest) => onboardingApi.post<Response<string>>('shops/clientbrandstore', data);

const exchangeAccessCode = (params: ExchangeAccessCodeRequest, code: string) =>
  onboardingApi.get<Response<string>>(`shopifyConnector/exchangeAccessCodeForAccessToken/${code}`, { params });

const getDashboardData = (params: GetShopifyEcomDataRequest) =>
  vennFlowApi.get<EcommDashboardResponse>('EcommSales/EcommSales', { params });

export const shopifyApi = {
  getInstallLink,
  createStore,
  exchangeAccessCode,
  getDashboardData,
};

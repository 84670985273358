import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  Card,
  TextField,
  FormControl,
  FormControlLabel,
  Container,
  FormGroup,
  Checkbox,
  Grid,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch, useSelector } from 'hooks';
import { useToast } from 'hooks/use-toast';
import { FC, useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet-async';
import { companySelectors } from 'store/entities/company';
import { getUsersByCompanyId } from 'store/entities/company/actions';
import { getFactAdsPerformance, getFactRetailSales, getPowerBiExport } from 'store/entities/exportcsv/action';
import { userSelectors } from 'store/entities/user';
import { dateFormmating, dateFormmatingExport } from 'utils/dateFormatting';

import { dataExportStyles } from './styles';

interface Props {
  label: string;
  variant?: 'default' | 'week' | 'month';
}
interface CheckedItems {
  [key: string]: boolean;
}

const DataExport = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useToast();
  const csvLink = useRef();
  // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  ///const exportFiles = useSelector(state=>state.dataexportSlice.files);

  const getDate = (days: number) => {
    const currentDate = new Date();

    const oneDayPrevious = new Date(currentDate);
    oneDayPrevious.setDate(currentDate.getDate() - days);
    return oneDayPrevious.toDateString();
  };

  const [startDate, setStartDate] = useState<unknown>(getDate(1));
  const [endDate, setEndDate] = useState<unknown>(getDate(0));

  const loading = useSelector(companySelectors.usersLoading);
  const { clientId, brandList, brandStoreList } = useSelector(userSelectors.user);
  const user = useSelector(userSelectors.user);

  useEffect(() => {
    dispatch(getUsersByCompanyId(clientId, errorToast));
  }, []);

  function getFileNameFromUrl(url: string) {
    // Split the URL by '/'
    const parts = url.split('/');

    // Get the last part of the URL (which should be the file name)
    const lastPart = parts[parts.length - 1];

    // Split the file name by '?' in case there are query parameters
    const fileName = lastPart.split('?')[0];

    return fileName;
  }

  const download = async (URLPATH: string) => {
    try {
      const response = await fetch(URLPATH, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
        },
      });
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = getFileNameFromUrl(URLPATH);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

const handlepowerbiclick = async () => {
  const checkeditem = Object.entries(checkedItems)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key]) => ({ ...acc, [key]: true }), {});

  if (checkeditem) {
    //generate retail sales and ad performance -- after extract of both of them move onto next step
    const csvFiles = new Array();
    
    //pbix template file - setup with a SAS token that expires in 2060 -- if this file is updated this link  will need updating (take out into a env variable really)
    csvFiles.push('https://vfstgshareduks.blob.core.windows.net/pbix-templates/pbitemplate.pbix?sv=2023-01-03&st=2023-12-12T11%3A59%3A41Z&se=2060-12-13T11%3A59%3A00Z&sr=b&sp=r&sig=E%2BjDnclhw%2FQGrqcNF757vhqeHaZ1SPRJWVWnWoiXPZs%3D');

    const passStartDate: Date = new Date(startDate as string);
    const passEndDate: Date = new Date(endDate as string);

    let retailExtracted:boolean = false;
    let adsPerfExtracted:boolean = false;
     dispatch(
      getFactRetailSales(
        {
          tenantGUID: user.clientIdData,
          brandGUID: brandList[0].Id,
          storeID: '00000000-0000-0000-0000-000000000000',//pass empty guid and the api treats it as null to pass through to the sproc. Eventually this needs to send through storeIdentifier
          startDatetime: dateFormmating(passStartDate, false, true),
          endDatetime: dateFormmating(passEndDate,  false, true),
        },

        errorToast,
      ),
    ).then(res => {
      retailExtracted = true;
      
      if(res.data !== null)
        csvFiles.push(res.data);
      else
        errorToast('No Retail Sales Data to Export!');

      if(retailExtracted && adsPerfExtracted)
        downloadBIExport(csvFiles);
      
    }).catch(error => {
      // Handle errors here
      console.error('Error:', error);
    });

    dispatch(
      getFactAdsPerformance(
        {
          tenantGUID: user.clientIdData, 
          brandGUID: brandList[0].Id, 
          storeID: '00000000-0000-0000-0000-000000000000',//pass empty guid and the api treats it as null to pass through to the sproc. Eventually this needs to send through storeIdentifier
          startDatetime: dateFormmating(passStartDate,  false, true),
          endDatetime: dateFormmating(passEndDate,  false, true),
        },

        errorToast,
      ),
    ).then(res => {
      adsPerfExtracted= true;
      
      if(res.data !== null)
        csvFiles.push(res.data);
      else
        errorToast('No Ads Performance Data to Export!');

      if(retailExtracted && adsPerfExtracted)
      {
        downloadBIExport(csvFiles);
      }

    }).catch(error => {
      // Handle errors here
      console.error('Error:', error);
    });
    
  successToast('Your Power BI Export is being downloaded');
}

  const downloadBIExport = async (csvFiles:any[]) => {
    if(csvFiles.length > 0)
    {
      dispatch(getPowerBiExport({
        csvFiles
      },errorToast)).then(async res => {
        const blob = new Blob([res], {type:'application/zip'})

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'PowerBIExport-'+dateFormmatingExport(new Date())+'.zip';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }

  }

  const handlecapplylick = async () => {
    const checkeditem = Object.entries(checkedItems)
      .filter(([key, value]) => value === true)
      .reduce((acc, [key]) => ({ ...acc, [key]: true }), {});

    if (checkeditem) {

      const passStartDate: Date = new Date(startDate as string);
      const passEndDate: Date = new Date(endDate as string);

      if ('Retail Sales' in checkeditem) {
        dispatch(
          getFactRetailSales(
            {
              tenantGUID: user.clientIdData,
              brandGUID: brandList[0].Id,
              storeID: '00000000-0000-0000-0000-000000000000',//pass empty guid and the api treats it as null to pass through to the sproc. Eventually this needs to send through storeIdentifier
              startDatetime: dateFormmating(passStartDate, false,  true),
              endDatetime: dateFormmating(passEndDate,  false, true),
            },

            errorToast,
          ),
        )
          .then(result => {
            // Handle the result here
            if (result.data) {
              window.open(result.data, '_self');
              download(result.data);

              successToast('Retail Sales file has been downloaded');
            } else {
              errorToast('No Data to download for Retail Sales');
            }
          })
          .catch(error => {
            // Handle errors here
            console.error('Error:', error);
          });
      }
      if ('Ad Performance' in checkeditem) {
        dispatch(
          getFactAdsPerformance(
            {
              tenantGUID: user.clientIdData,
              brandGUID: brandList[0].Id,
              storeID: '00000000-0000-0000-0000-000000000000',//pass empty guid and the api treats it as null to pass through to the sproc. Eventually this needs to send through storeIdentifier
              startDatetime: dateFormmating(passStartDate as Date, false,  true),
              endDatetime: dateFormmating(passEndDate as Date,  false, true),
            },

            errorToast,
          ),
        )
          .then(result => {
            // Handle the result here
            if (result.data) {
              download(result.data);
              
              window.open(result.data, '_self');

              successToast('Ads Performance file has been downloaded');
            } else {
              errorToast('No Data to download for Ads Performance');
            }
          })
          .catch(error => {
            // Handle errors here
            console.error('Error:', error);
          });
      }

      successToast('Your file is being downloaded');
    }
  };

  // State to keep track of checked items
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});

  // Function to handle checkbox change
  const handleCheckboxChange = (value: string) => {
    setCheckedItems(prev => ({
      ...prev,
      [value]: !prev[value], // Toggle the value
    }));
  };
  // Effect to run when the state changes
  useEffect(() => {}, [checkedItems]);

  return (
    <>
      <Helmet>
        <title>Data Export</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid item container direction="row" justifyContent="start" alignItems="stretch" spacing={2} lg={12}>
          <Grid item xs={12} lg={12}>
            <Typography variant="h1">Data Export</Typography>
          </Grid>
          <Grid item justifyContent="center" xs={8} lg={8}>
            <Card style={{ minHeight: '600px' }}>
              <h3 style={dataExportStyles.InsightHeader}>Select Your Dates...</h3>
              <Grid container xs={12} lg={12}>
                <Grid xs={1} lg={1} />
                <Grid lg={3} xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <dataExportStyles.datePicker
                      minDate={new Date().setFullYear(2021)}
                      maxDate={new Date().setFullYear(new Date().getFullYear())}
                      label="Start Date"
                      value={startDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={date => date && setStartDate(date)}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={1} lg={1} />
                <Grid lg={3} xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <dataExportStyles.datePicker
                      minDate={new Date().setFullYear(2021)}
                      maxDate={new Date().setFullYear(new Date().getFullYear())}
                      label="End Date"
                      value={endDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={date => setEndDate(date)}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <h3 style={dataExportStyles.InsightHeader}>Select Your Extracts...</h3>
              <FormControl style={{ marginTop: '-3em' }}>
                <FormGroup style={{ marginLeft: '6em' }}>
                  <FormControlLabel
                    value="Retail Sales"
                    label="Retail Sales"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={checkedItems['Retail Sales'] || false}
                        onChange={() => handleCheckboxChange('Retail Sales')}
                      />
                    }
                  />
                  <FormControlLabel
                    value="Ad Performance"
                    label="Ad Performance"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={checkedItems['Ad Performance'] || false}
                        onChange={() => handleCheckboxChange('Ad Performance')}
                      />
                    }
                  />
                  {/*<FormControlLabel
                    value="Customer Journey"
                    label="Customer Journey"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={checkedItems['Customer Journey'] || false}
                        onChange={() => handleCheckboxChange('Customer Journey')}
                      />
                    }
                  />
                  <FormControlLabel
                    value="Shopping Journey"
                    label="Shopping Journey"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={checkedItems['Shopping Journey'] || false}
                        onChange={() => handleCheckboxChange('Shopping Journey')}
                      />
                    }
                  />*/}
                  
                </FormGroup>
              </FormControl>
              <Grid container xs={12} lg={12} sx={{marginTop: '2em'}}>
                  <Grid lg={1} xs={1}/>
                  <Grid lg={7} xs={7}>
                        <dataExportStyles.exportButton onClick={handlecapplylick} value={'Apply'}>
                        CSV Export
                      </dataExportStyles.exportButton>
                    </Grid>
                    <Grid lg={4} xs={4}>
                        <dataExportStyles.exportButton onClick={handlepowerbiclick} value={'Apply'}>
                        Power BI Export
                        </dataExportStyles.exportButton>
                    </Grid>
                </Grid>
                
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DataExport;

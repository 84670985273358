import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { brainInsight } from 'types/brain/brain';

import { BrainSlice} from './types';

const initialState: BrainSlice = {
  brainData: null,
  loading: true,
};

export const brainSlice = createSlice({
  name: 'brain',
  initialState,
  reducers: {
    processGetBrainData: state => {
      state.loading = true;
    },
    getBrainDataSuccess: (state, action: PayloadAction<brainInsight[]>) => {
      state.brainData = action.payload;
      state.loading = false;
    },
    getBrainDataFailure: state => {
      state.loading = false;
    },
  },
});


import { SuspenseLoader } from 'components/suspense-loader';
import DataExport from 'pages/data-export/data-export';
import { Suspense, lazy } from 'react';

import { routes } from './routes';

const Loader = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Settings = Loader(lazy(() => import('../pages/settings/settings')));

const UserManagement = Loader(lazy(() => import('../pages/user-management/user-management')));

const Support = Loader(lazy(() => import('../pages/support/support')));

export const managementRoutes = [
  {
    path: routes.settings.url,
    element: <Settings />,
  },
  {
    path: routes.dataExport.url,
    element: <DataExport />,
  },
  {
    path: routes.userManagement.url,
    element: <UserManagement />,
  },
  {
    path: routes.support.url,
    element: <Support />,
  },
];

import { Box, Button, Typography } from '@mui/material';

const Unauthorized = () => {
  const navigateToOnboarding = () => {
    window.location.replace(`${process.env.REACT_APP_ONBOARDING_URL}/Account/SignIn`);
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography mb="1rem" variant="h1">
        Unfortunately, you are not authorized
      </Typography>
      <Typography mb="1rem">Please contact the administrator for access or try login</Typography>
      <Button onClick={navigateToOnboarding} variant="contained">
        Login
      </Button>
    </Box>
  );
};

export default Unauthorized;

import { mapConnectedSystems } from './helper/map-connected-systems';
import { CompanyState } from './types';

const companyData = (state: CompanyState) => state.company.companyData;

const companyDataLoading = (state: CompanyState) => state.company.companyLoading;

const connectedSystems = (state: CompanyState) => mapConnectedSystems(state.company.allBrandData);

const connectedSystemsWIthMeta = (state: CompanyState) => state.company.allBrandData;


const brandDataLoading = (state: CompanyState) => state.company.brandLoading;

const users = (state: CompanyState) => state.company.users;

const usersLoading = (state: CompanyState) => state.company.usersLoading;

export const companySelectors = {
  companyData,
  companyDataLoading,
  connectedSystems,
  brandDataLoading,
  users,
  usersLoading,
  connectedSystemsWIthMeta
};

import { dataexportApi } from 'api/csvexport/export';
import { AppDispatch } from 'store/store';
import { ExportRequestData, PowerBIExport, CogsTemplateExport } from 'types/exportcsv/exportrequest';

import { dataexportSlice } from './slice';

export const getFactRetailSales =
  (params: ExportRequestData, onFailure: () => void) => async (dispatch: AppDispatch) => {
    //dispatch(dataexportSlice.actions.processGetExportData());

    try {
      const { data } = await dataexportApi.getFactRetailSales(params);

      //dispatch(dataexportSlice.actions.getExportDataSuccess(data));

      //   if (data) {
      //     downloadBlob(data);
      //   }
      return data;
    } catch {
      dispatch(dataexportSlice.actions.getExportDataFailure());

      onFailure();
    }
  };

export const getFactAdsPerformance =
  (params: ExportRequestData, onFailure: () => void) => async (dispatch: AppDispatch) => {
    // dispatch(dataexportSlice.actions.processGetExportData());

    try {
      const { data } = await dataexportApi.getFactAdsPerformance(params);

      // dispatch(dataexportSlice.actions.getExportDataSuccess(data));
      //   if (data) {
      //     downloadBlob(data);
      //   }
      return data;
    } catch {
      dispatch(dataexportSlice.actions.getExportDataFailure());

      onFailure();
    }
  };

  export const getPowerBiExport = 
  (body: PowerBIExport, onFailure: () => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await dataexportApi.getPowerBIExport(body);

      return data;
    } catch {
      dispatch(dataexportSlice.actions.getExportDataFailure());

      onFailure();
    }
  };

  export const getCogsTemplate = 
  (body: CogsTemplateExport, onFailure: () => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await dataexportApi.getCogsTemplateExport(body);

      return data;
    } catch {
      dispatch(dataexportSlice.actions.getExportDataFailure());

      onFailure();
    }

  };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerJourneyMarketingOnlyData, MarketingOnlyCustomerJourney } from 'types/customer-journey-marketing-only/customer-journey-marketing-only';

import { CustomerJourneyMarketingOnlySlice } from './types';
import { CustomerJourneyMarketingOnlyFilters } from '../../../types/customer-journey-marketing-only/customer-journey-marketing-only';

const initialState: CustomerJourneyMarketingOnlySlice = {
  filters: {
    period: 'Week to Date',
    compareTo: 'Last Period',
  },
  customerJourneyMarketingOnlyData: null,
  loading: true,
};




export const customerJourneyMarketingOnlySlice = createSlice({
  name: 'customer-journey-marketing-only',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<CustomerJourneyMarketingOnlyFilters>) => {
      state.filters = action.payload;
    },
    processGetOverviewData: state => {
      state.loading = true;
    },
    getOverviewDataSuccess: (state, action: PayloadAction<CustomerJourneyMarketingOnlyData>) => {
      state.customerJourneyMarketingOnlyData = action.payload;
      state.loading = false;
    },
    getOverviewDataFailure: state => {
      state.loading = false;
    },
  },
});




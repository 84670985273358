import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { brainInsight } from 'types/brain/brain';

import { BrainSlice }  from './types';

const initialState: BrainSlice = {
  loading: true,
};

export const sqlBrainSlice = createSlice({
  name: 'brain',
  initialState,
  reducers: {
    processGetBrainData: state => {
      state.loading = true;
    }
  },
});


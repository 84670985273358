import CancelIcon from '@mui/icons-material/Cancel';
import { Container, Grid, Card, CardHeader, IconButton, Button } from '@mui/material';
import { useEffect, useState, FC } from 'react';

import './styles.css';

interface Props {
    handleClose: ()=>void,
    videoUrl:string,
    title?:string
  }

export const VideoModal: FC<Props> = ({ title, videoUrl, handleClose }) => {
    const internalClose = () =>{
        document.removeEventListener('keydown', onkeypress, false);
        handleClose()
    }

    const onkeypress = (e:any) =>{
        if(e.key === 'Escape')
        {
            handleClose();
        }
    }
   
   useEffect(()=>{
        document.addEventListener('keydown', onkeypress, false);
   }, []);


    return (
        <>
            <div className='modalLayer' onClick={internalClose}>
                <Container className='containerHolder' >
                    <Grid container display='flex' justifyContent='center' alignItems='center' style={{height:'100vh'}}>
                        <Grid item>
                            <Card className='VideoModalContainer'>
                                <CardHeader className='cardHeader' action={
                                    <IconButton onClick={internalClose} aria-label='close'>
                                        <CancelIcon />
                                    </IconButton>
                                } />
                                <div className='videoBox'>
                                    <iframe id='videoElm' className='video' src={videoUrl} title={title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
                                        Not Supported
                                    </iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};
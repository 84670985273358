import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Avatar, Box, Button, Divider, Hidden, List, ListItem, ListItemText, Popover } from '@mui/material';
import { useDispatch, useModal, useSelector } from 'hooks';
import { useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from 'router/routes';
import { userSelectors, userSlice } from 'store/entities/user';

import { UserBoxButton, MenuUserBox, UserBoxText, UserBoxLabel, UserBoxDescription } from './styles';

export const HeaderUserbox = () => {
  // const user = {
  //   name: "Dora Explorer",
  //   avatar: "https://avatars.dicebear.com/api/avataaars/Dora Explorer.svg",
  //   jobtitle: "Business Owner",
  // };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(userSelectors.user);

  const ref = useRef<any>(null);
  const { isOpen, open, close } = useModal();

  const handleLogout = async () => {
    try {
      close();
      await dispatch(userSlice.actions.logout());
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_ONBOARDING_URL}/Account/SignIn`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={open}>
        {/* <Avatar variant="rounded" alt={user?.username} src={user?.avatar} /> */}
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.firstName} {user?.lastName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">{user?.contactEmail}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={close}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          {/* <Avatar variant="rounded" alt={user?.username} src={user?.avatar} /> */}
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.firstName} {user?.lastName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">{user?.contactEmail}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button component={NavLink} to={routes.profile.url} onClick={close}>
            <AccountBoxIcon fontSize="small" />
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button component={NavLink} to={routes.settings.url} onClick={close}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

import { ShoppingJourneyFilterState, ShoppingJourneyState } from './types';

const shoppingJourney = (state: ShoppingJourneyState) => state.shoppingJourney.shoppingJourney;

const shoppingJourneyLoading = (state: ShoppingJourneyState) => state.shoppingJourney.shoppingJourneyLoading;

const filters = (state: ShoppingJourneyState) => state.shoppingJourney.filters;

export const shoppingJourneySelectors = {
  shoppingJourney,
  shoppingJourneyLoading,
  filters,
};


const shoppingJourneyFilter = (state: ShoppingJourneyFilterState) => state.shoppingJourneyFilter.ShoppingJourneyFilter;

const shoppingJourneyFilterLoading = (state: ShoppingJourneyFilterState) => state.shoppingJourneyFilter.ShoppingJourneyFilterLoading;

const shoppingJourneyFilterFilters = (state: ShoppingJourneyFilterState) => state.shoppingJourneyFilter.filters;
 


export const ShoppingJourneyFiltersSelectors ={
  shoppingJourneyFilterFilters,
  shoppingJourneyFilterLoading,
  shoppingJourneyFilter
}
import { mock } from 'utils/axios';
import { JWT_EXPIRES_IN, JWT_SECRET, sign, decode } from 'utils/jwt';
import wait from 'utils/wait';

const users = [
  {
    tenantId: '1',
    username: 'admin',
    email: 'rakesh@camdenai.com',
    avatar: 'https://avatars.dicebear.com/api/avataaars/RakeshM.svg',
    name: 'Rakesh Mandal',
    password: '12345',
    companyName: 'Camden AI',
    selectedShop: 'caiTestStore',
    role: 'role1',
  },
  {
    tenantId: '2',
    username: 'mark',
    email: 'mark@camdenai.com',
    avatar: 'https://avatars.dicebear.com/api/avataaars/Mark.svg',
    name: 'Mark Davies',
    password: '12345',
    companyName: 'Camden AI',
    selectedShop: 'Cai Live Store',
    role: 'role2',
  },
];

// mock.onPost("/api/account/login").reply(async (config) => {
//   await wait(1000);

//   try {
//     const { email, password } = JSON.parse(config.data);

//     const user = users.find((_user) => _user.email === email);

//     if (!user || user.password !== password) {
//       return [
//         400,
//         { message: "Verify that your email and password are correct" },
//       ];
//     }

//     const accessToken = sign({ userId: user.id }, JWT_SECRET, {
//       expiresIn: JWT_EXPIRES_IN,
//     });

//     return [
//       200,
//       {
//         accessToken,
//         user: {
//           username: user.username,
//           email: user.email,
//           avatar: user.avatar,
//           name: user.name,
//           companyName: user.companyName,
//           selectedShop: user.selectedShop,
//           role: user.role,
//         },
//       },
//     ];
//   } catch (err) {
//     console.error("Error: ", err);
//     return [500, { message: "Encountered a server error" }];
//   }
// });

mock.onPost('/api/account/login').reply(async config => {
  await wait(1000);

  try {
    const { email, password } = JSON.parse(config.data);

    const user = users.find(_user => _user.email === email);

    if (!user || user.password !== password) {
      return [400, { message: 'Verify that your email and password are correct' }];
    }

    const accessToken = sign({ tenantId: user.tenantId }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN,
    });

    return [
      200,
      {
        accessToken,
        user: {
          tenantId: user.tenantId,
          username: user.username,
          email: user.email,
          avatar: user.avatar,
          name: user.name,
          companyName: user.companyName,
          selectedShop: user.selectedShop,
          role: user.role,
        },
      },
    ];
  } catch (err) {
    console.error('Error: ', err);
    return [500, { message: 'Encountered a server error' }];
  }
});

mock.onGet('/api/account/personal').reply(config => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Auth token is missing' }];
    }

    const accessToken = (Authorization as string).split(' ')[1];
    const { tenantId } = decode(accessToken) as any;
    const user = users.find(_user => _user.tenantId === tenantId);

    if (!user) {
      return [401, { message: 'Invalid auth token' }];
    }

    return [
      200,
      {
        user: {
          tenantId: user.tenantId,
          username: user.username,
          email: user.email,
          avatar: user.avatar,
          name: user.name,
          companyName: user.companyName,
          selectedShop: user.selectedShop,
          role: user.role,
        },
      },
    ];
  } catch (err) {
    console.error('Error: ', err);
    return [500, { message: 'Encountered a server error' }];
  }
});

// mock.onGet("/api/account/personal").reply((config) => {
//   try {
//     const { Authorization } = config.headers;

//     if (!Authorization) {
//       return [401, { message: "Auth token is missing" }];
//     }

//     const accessToken = (Authorization as string).split(" ")[1];
//     const { userId } = decode(accessToken) as any;
//     const user = users.find((_user) => _user.id === userId);

//     if (!user) {
//       return [401, { message: "Invalid auth token" }];
//     }

//     return [
//       200,
//       {
//         user: {
//           username: user.username,
//           email: user.email,
//           avatar: user.avatar,
//           name: user.name,
//           companyName: user.companyName,
//           selectedShop: user.selectedShop,
//           role: user.role,
//         },
//       },
//     ];
//   } catch (err) {
//     console.error("Error: ", err);
//     return [500, { message: "Encountered a server error" }];
//   }
// });

import { SuspenseLoader } from 'components/suspense-loader';
import { lazy, Suspense } from 'react';

import { routes } from './routes';

const Loader = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Profile = Loader(lazy(() => import('../pages/profile/profile')));

const Status404 = Loader(lazy(() => import('../pages/server-status/Status404')));

export const profileRoutes = [
  {
    path: routes.profile.url,
    element: <Profile />,
  },
  {
    path: '*',
    element: <Status404 />,
  },
];

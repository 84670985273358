import { RevenueSpendState } from './types';

const revenueSpendData = (state: RevenueSpendState) => state.revenueSpend.revenueSpendData;

const loading = (state: RevenueSpendState) => state.revenueSpend.loading;

const filters = (state: RevenueSpendState) => state.revenueSpend.filters;

export const revenueSpendSelectors = {
  revenueSpendData,
  loading,
  filters,
};

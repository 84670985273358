import { Response } from 'types/api/api';
import { GetCurrentPlanResult, Plan } from 'types/plans/plans';

import { onboardingApi } from '../api';

const getPlans = () => onboardingApi.get<Response<Plan[]>>('Plans/GetAllPlans/true');

const getCurrentPlan = (clientId: string) =>
  onboardingApi.get<Response<GetCurrentPlanResult[]>>(`ClientPlanMapper/GetClientSelectedPlanByClientId/${clientId}`);

export const plansApi = {
  getPlans,
  getCurrentPlan,
};

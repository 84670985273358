import { vennFlowApi } from 'api/api';
import {
  Brand,
  Market,
  CampaignsData,
  AdsData,
  ChannelsData,
  Objective,
  CampaignData,
  GetChannelsRequest,
  GetCampaignsRequest,
  GetCampaignRequest,
  GetAdRequest,
} from 'types/marketing-analyzer/marketing-analyzer';

const getBrands = () => vennFlowApi.get<Brand[]>('/filters/channels');

const getMarkets = () => vennFlowApi.get<Market[]>('/filters/markets');

const getObjectives = () => vennFlowApi.get<Objective[]>('/filters/objectives');

const getChannels = (params: GetChannelsRequest) =>
  vennFlowApi.get<ChannelsData>('/marketingAnalyzer/channels', { params });

const getCampaigns = (params: GetCampaignsRequest) =>
  vennFlowApi.get<CampaignsData>('/marketingAnalyzer/campaigns', { params });

const getAdsByCampaignIdName = (params: GetCampaignRequest) =>
  vennFlowApi.get<AdsData>('/marketingAnalyzer/ads-by-campaign-id-name', { params });

const getAds = (params: GetAdRequest) => vennFlowApi.get<AdsData>('/marketingAnalyzer/getAds', { params });

export const marketingAnalyzerApi = {
  getBrands,
  getObjectives,
  getMarkets,
  getChannels,
  getCampaigns,
  getAdsByCampaignIdName,
  getAds,
};

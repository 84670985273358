import { marketingAnalyzerApi } from 'api/marketing-analyzer';
import { AppDispatch } from 'store/store';
import {
  GetCampaignRequest,
  GetCampaignsRequest,
  GetAdRequest,
  GetChannelsRequest,
} from 'types/marketing-analyzer/marketing-analyzer';

import { marketingAnalyzerSlice } from './slice';

export const getBrands = () => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetBrands());

  try {
    const { data } = await marketingAnalyzerApi.getBrands();

    dispatch(marketingAnalyzerSlice.actions.getBrandsSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getBrandsFailure());
  }
};

export const getMarkets = () => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetMarkets());

  try {
    const { data } = await marketingAnalyzerApi.getMarkets();

    dispatch(marketingAnalyzerSlice.actions.getMarketsSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getMarketsFailure());
  }
};

export const getObjectives = () => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetObjectives());

  try {
    const { data } = await marketingAnalyzerApi.getObjectives();

    dispatch(marketingAnalyzerSlice.actions.getObjectivesSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getObjectivesFailure());
  }
};

export const getChannels = (params: GetChannelsRequest) => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetChannels());

  try {
    const { data } = await marketingAnalyzerApi.getChannels(params);

    dispatch(marketingAnalyzerSlice.actions.geChannelsSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getChannelsFailure());
  }
};

export const getCampaigns = (params: GetCampaignsRequest) => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetCampaigns());

  try {
    const { data } = await marketingAnalyzerApi.getCampaigns(params);

    dispatch(marketingAnalyzerSlice.actions.geCampaignsSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getCampaignsFailure());
  }
};

export const getAdsByCampaignIdName = (params: GetCampaignRequest) => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetAds());

  try {
    const { data } = await marketingAnalyzerApi.getAdsByCampaignIdName(params);

    dispatch(marketingAnalyzerSlice.actions.geAdsSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getAdsFailure());
  }
};

export const getAds = (params: GetCampaignRequest) => async (dispatch: AppDispatch) => {
  // dispatch(marketingAnalyzerSlice.actions.processGetCampaign());

  // try {
  //   const { data } = await marketingAnalyzerApi.getAds(params);

  //   dispatch(marketingAnalyzerSlice.actions.geCampaignSuccess(data));
  // } catch {
  //   dispatch(marketingAnalyzerSlice.actions.getCampaignFailure());
  // }
};


export const getAd = (params: GetAdRequest) => async (dispatch: AppDispatch) => {
  dispatch(marketingAnalyzerSlice.actions.processGetAds());

  try {
    const { data } = await marketingAnalyzerApi.getAds(params);

    dispatch(marketingAnalyzerSlice.actions.geAdsSuccess(data));
  } catch {
    dispatch(marketingAnalyzerSlice.actions.getAdsFailure());
  }
};

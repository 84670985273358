import { Box, Typography } from '@mui/material';
import { Hint } from 'components/hint';
import { FC } from 'react';

interface Props {
  hint?: string;
  children: string;
}

export const Heading: FC<Props> = ({ hint, children }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    pt={1}
    pb={1}
    pr={2}
    pl={2}
    bgcolor={theme => theme.palette.common.black}
    borderRadius={1}
    color={theme => theme.palette.common.white}
  >
    <Typography flex="1" variant="h4" textAlign="center" textTransform="capitalize" marginLeft={hint ? '20px' : 0}>
      {children}
    </Typography>

    {hint && <Hint text={hint} size="small" />}
  </Box>
);

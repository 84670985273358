import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CLientLoadStateData, PyramidUserMapper } from 'types/marketing/overview';

import { PyramidLoadDataSlice } from './types';


const initialState: PyramidLoadDataSlice = {
  loaddata: null,
  loading: true,
};

export const pyramidloaddataSlice = createSlice({
  name: 'pyramid-loaddata',
  initialState,
  reducers: {
    processData: state => {
      state.loading = true;
    },
    getloadDataSuccess: (state, action: PayloadAction<PyramidUserMapper[]>) => {
      state.loaddata = action.payload;
      state.loading = false;
    },
    getloadDataFailure: state => {
      state.loading = false;
    },
  },
});

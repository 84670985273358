import { Typography, TableRow, TableCell, Avatar } from '@mui/material';
import { ArrowIndicator } from 'components/arrow-indicator';
import { CompareTableProgressCell, calculateCellProgress } from 'components/compare-table';
import { FC } from 'react';
import { Label } from 'recharts';
import { ShopifLeadTableData } from 'types/marketing-analyzer/marketing-analyzer';
import { formatNumber} from 'utils/numbers';

interface Props {
  currencyLabel:string;
  row: ShopifLeadTableData;
  data: ShopifLeadTableData[];
}


export const ShopifyLeadTableRow: FC<Props> = ({ currencyLabel, row, data }) => (
  <TableRow hover>
    <TableCell>{row.product_name}</TableCell>

    <CompareTableProgressCell
      value={currencyLabel+formatNumber(row.revenue, 0)}
      label={row.revenueCompare}
      progress={calculateCellProgress({ row, data, key: 'revenueCompare' })}
      withProgress
    />

    <CompareTableProgressCell
      value={formatNumber(row.sales)}
      label={row.salesCompare}
      progress={calculateCellProgress({ row, data, key: 'sales' })}
      withProgress
    />

    <CompareTableProgressCell
      value={formatNumber(row.avg_sales_per_week,1)}
      label={null}
      progress={calculateCellProgress({ row, data, key: 'avg_sales_per_week' })}
      withProgress
    />

    <CompareTableProgressCell
      value={formatNumber(row.avg_sales_per_month)}
      label={null}
      progress={calculateCellProgress({ row, data, key: 'avg_sales_per_month' })}
      withProgress
    />

    <CompareTableProgressCell
      value={formatNumber(row.refunds)}
      label={row.refunds}
      progress={calculateCellProgress({ row, data, key: 'refunds' })}
      withProgress
    />

    <CompareTableProgressCell
      value={formatNumber(row.lifetime)}
      label={null}
      progress={calculateCellProgress({ row, data, key: 'lifetime' })}
      withProgress
    />
  </TableRow>
);

import { PrivateRoute } from 'components/routes';
import { BaseLayout } from 'layout/base-layout';
import { SidebarLayout } from 'layout/sidebar-layout';
import { useRoutes } from 'react-router-dom';

import { accountRoutes } from './account';
import { baseRoute } from './base';
import { dashboardsRoutes } from './dashboards';
import { managementRoutes } from './management';
import { profileRoutes } from './profile';
import { routes } from './routes';

export const Router = () => {
  const content = useRoutes([
    {
      path: routes.account.url,
      children: accountRoutes,
    },
    {
      path: '',
      element: (
        <PrivateRoute>
          <SidebarLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          children: dashboardsRoutes,
        },
        {
          path: '',
          children: managementRoutes,
        },
        {
          path: '',
          children: profileRoutes,
        },
      ],
    },
    {
      path: '*',
      element: <BaseLayout />, // Without sidebar and header
      children: baseRoute,
    },
  ]);

  return content;
};

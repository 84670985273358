import { format } from 'date-fns';
import { isDateString } from 'utils/isDateString';

export const tickFormatter = (value: string) => {
  if (!value) {
    return;
  }

  if (isDateString(value.toString())) {
    return format(new Date(value), 'dd MMM');
  }

  return value;
};

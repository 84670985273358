import { vennFlowApi, brainApiBase } from 'api/api';
import { brainInsight,brainInsightsFilter, brainCaller, brainAssistantRtn,brainPollRtn} from 'types/brain/brain';

const getInsights = (params: brainInsightsFilter) =>
vennFlowApi.get<brainInsight[]>('/Brain/InsightCalculations', { params });

const interactAssistantAction = (params: brainCaller) =>
brainApiBase.post<brainAssistantRtn>('/brain-initiate-assistant', params);

const pollAssistant = (params: brainCaller) =>
brainApiBase.post<brainPollRtn>('/brain-poll-assistant-messages', params);

export const brainApi = {
    getInsights,
    interactAssistantAction,
    pollAssistant
};
  
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plan } from 'types/plans/plans';

import { PlansSlice } from './types';

const initialState: PlansSlice = {
  plans: [],
  loading: true,
  defaultSelectedPlanId: null,
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    processGetPlans: state => {
      state.loading = true;
    },
    getPlansSuccess: (state, action: PayloadAction<Plan[]>) => {
      state.plans = action.payload;

      const defaultSelectedPlan = state.plans.find(plan => plan.isDefaultSelected);
      state.defaultSelectedPlanId = defaultSelectedPlan.Id;
    },
    getPlanSuccess: (state, action: PayloadAction<string>) => {
      state.defaultSelectedPlanId = action.payload;

      state.loading = false;
    },
    getPlansFailure: state => {
      state.loading = false;
    },
  },
});

import { Box, Card, Select, styled } from '@mui/material';

const StatisticsGroup = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

const FiltersGroup = styled(Box)`
  grid-column-gap: 12px;
`;

const SelectStyled = styled(Select)`
  max-height: 120px;
`;

export const revenueSpendStyles = {
  StatisticsGroup,
  SelectStyled,
  FiltersGroup,
};

import { onboardingApi, vennFlowApi } from 'api/api';
import {
  GetOverallTrendPerformanceRequest,
  OverallTrendPerformanceData,
} from 'types/overall-trend-performance/overall-trend-performance';

const getOverallTrendPerformance = (params: GetOverallTrendPerformanceRequest) =>
  vennFlowApi.get<{ overallTrendPerformance: OverallTrendPerformanceData }>('OverallTrend/OverallTrendPerformance', {
    params,
  });

export const overallTrendApi = {
  getOverallTrendPerformance,
};

import { AllBrandInfo } from 'types/companies/companies';
import { BooleanLiteral } from 'typescript';
import { mock } from 'utils/axios';

export interface ConnectedSystems {
  google: boolean;
  facebook: boolean;
  shopify: boolean;
  tiktok: boolean;
  snapchat: boolean;
  googlega4: boolean;
  leadsquared:boolean;
  metaorganic:boolean;
}

const systems = {
  'Google Analytics UA': 'google',
  Facebook: 'facebook',
  Shopify: 'shopify',
  TikTok: 'tiktok',
  Snapchat: 'snapchat',
  'Google Analytics 4': 'googlega4',
  Leadsquared: 'leadsquared',
  'Meta Organic': 'metaorganic'
} as const;

export const mapConnectedSystems = (brandData: AllBrandInfo[]): Record<string, ConnectedSystems> => {
  const isarray = Array.isArray(brandData);
  if (isarray) {
    const obj = brandData?.reduce((acc, store) => {
      const system = store;
      const keyName = systems[store.systemName];
      const metadata = store.metaData;
      return { ...acc, [store.clientBrandStoreId]: { ...acc[store.clientBrandStoreId], [keyName]: true } };
    }, {} as Record<string, ConnectedSystems>);
    return obj;
  }
};

import { SidebarState } from './types';

const opened = (state: SidebarState) => state.sidebar.opened;

const maximized = (state: SidebarState) => state.sidebar.maximized;

export const sidebarSelectors = {
  opened,
  maximized,
};

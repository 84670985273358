import { Button, Card, Box, Select, MenuItem, SelectChangeEvent, Grid } from '@mui/material';
import { AutocompleteField } from 'components/autocomplete-field';
import { RangePicker } from 'components/date-picker/date-range-picker';
import { useField, useFormikContext } from 'formik';
import { useDispatch, useSelector, useToast } from 'hooks';
import moment from 'moment';
import React, { FC, useState, useEffect } from 'react';
import generatePDF, { Options } from 'react-to-pdf';
import { dashboardsSelectors, dashboardsSlice } from 'store/entities/dashboards';
import { marketingAnalyzerSelectors } from 'store/entities/marketing-analyzer';
import { getProductData } from 'store/entities/overview/actions';
import { userSelectors } from 'store/entities/user';
import { dateFormatForDisplay } from 'utils/dateFormatting';
import { get7DaysAgoDate } from 'utils/getSevenDayAgoDate';

import { datePickerFiltersStyles as S } from './styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

interface Props {
  label: string;
  variant?: 'default' | 'week' | 'month';
  applyClick?: (fromdate: Date, todate: Date, product: string) => void;
}
interface Product {
  id: string;
  name: string;
}


export const WeeklyOverviewFiltersForm: FC<Props> = ({ label, variant = 'default', applyClick }) => {
  const [product, setProduct] = useState('');
  const [productsData, setProductsData] = useState<Product[]>([]);

  const { clientId } = useSelector(userSelectors.user);
  const { errorToast } = useToast();
  const [{ value }, , { setValue }] = useField('products');


  const date = useSelector(dashboardsSelectors.date);
  const endDate = useSelector(dashboardsSelectors.endDate);
  const compareStartDate = useSelector(dashboardsSelectors.compareStartDate);
  const compareEndDate = useSelector(dashboardsSelectors.compareEndDate);


  //defaultProduct

  const handleChange = (event: SelectChangeEvent) => {
    const prodName = document.getElementById('productname') as HTMLInputElement;
    setProduct(prodName?.value as string);
    console.log(prodName);
  };

  const handleApply = (event: any, date: any) => {
    const prodName = document.getElementById('productname') as HTMLInputElement;
    setProduct(prodName?.value as string);
    console.log(prodName?.value);

    const st = new Date(date.startDate._d);
    const end = new Date(date.endDate._d);
    setSelectedDateRange({ startDate: st, endDate: end });
  };

  const handleFilterClick = () => {
    // const formattedStDate = dateFormatForDisplay(selectedDateRange?.startDate);
    // const formattedEndDate = dateFormatForDisplay(selectedDateRange?.endDate);

    
    const prodName = document.getElementById('products') as HTMLInputElement;
    setProduct(prodName?.value as string);

    if (value) {
      if (value.length == 0) {
        applyClick(date, endDate, '');
      } else {
        //if only all selected then set empty
        const allSelected = value.some((a: { id: string }) => a.id === '00000000-0000-0000-0000-000000000000');

        applyClick(date, endDate, allSelected ? '' : value);
      }
    } else {
      applyClick(date, endDate, '');
    }
  };

  const dedupeList = (arr: any[]) => {
    if (arr === null || arr === undefined) return [];

    const uniqueVals = arr
      // .map(i => i.productNameOriginal)
      .map((item, index) => ({
        id: item.productId,
        name: item.productNameOriginal,
      }))
      .filter((value, index, currentVal) => currentVal.indexOf(value) === index);

    return uniqueVals.sort();
  };

  const sevenDaysAgoDate: Date = get7DaysAgoDate();
  const dispatch = useDispatch();

  const [compareValue, setCompareValue] = useState('');

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: date,
    endDate: endDate,
  });

  const [selectedCompareDateRange, setSelectedCompareDateRange] = useState({
    startDate: compareStartDate,
    endDate: compareEndDate,
  });

  const [selectedOptions, setSelectedOptions] = useState(null);

  const onChange = (
    startDate: Date,
    endDate: Date,
    compareStartDate: Date,
    compEndDate: Date,
    compareValue: string,
  ) => {
    setSelectedDateRange({ startDate: startDate, endDate: endDate });
    setSelectedCompareDateRange({ startDate: compareStartDate, endDate: compEndDate });
    setCompareValue(compareValue);
    dispatch(dashboardsSlice.actions.setDashboardsDate(startDate));
    dispatch(dashboardsSlice.actions.setDashboardsEndDate(endDate));
    dispatch(dashboardsSlice.actions.setDashboardsCompareStartDate(compareStartDate));
    dispatch(dashboardsSlice.actions.setDashboardsCompareEndDateDate(compEndDate));
  };

  const submitWrapper = () => {
    dispatch(dashboardsSlice.actions.setDashboardsDate(selectedDateRange.startDate));
    dispatch(dashboardsSlice.actions.setDashboardsEndDate(selectedDateRange.endDate));
    dispatch(dashboardsSlice.actions.setDashboardsCompareStartDate(selectedCompareDateRange.startDate));
    dispatch(dashboardsSlice.actions.setDashboardsCompareEndDateDate(selectedCompareDateRange.endDate));
    // Call submitForm with the necessary parameters
    handleFilterClick();
  };

  useEffect(() => {
    handleFilterClick();
  }, []);

  const getTargetElement = () => document.getElementById('productprof-container');

  const options: Options = {
    filename: 'product-profitability-dashboard.pdf',
    page: {
      margin: 20,
    },
  };

  const downloadPdf = (event: any) => {
    event.target.style.display = 'none';

    const main = document.getElementById('productprof-container');

    const divContainer = document.createElement('div');
    divContainer.style.marginTop = '1em';
    divContainer.style.textAlign = 'center';

    const img = document.createElement('img');
    img.style.width = '300px';
    img.src = '/static/images/PoweredByVennflow.svg';

    divContainer.appendChild(img);
    main.appendChild(divContainer);

    generatePDF(getTargetElement, options);

    divContainer.removeChild(img);
    main.removeChild(divContainer);

    event.target.style.display = 'block';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getProductData(clientId, errorToast));

        if (productsData == null || productsData.length === 0) {
          //form unique product list based on name
          let productList = dedupeList(response || []);

          productList = removeDuplicatesFromArray(productList);
          const uniqueProducts: Product[] = [];

          productList.forEach(product => {
            if (!uniqueProducts.some(p => p.id === product.id)) {
              uniqueProducts.push(product);
            }
          });
          uniqueProducts.unshift(uniqueProducts[0]); //'All Products');
          const convertedProductList: Product[] = uniqueProducts.map(item => ({ id: item.id, name: item.name }));

          setProductsData(convertedProductList || []);
        }

        // const formattedStDate = selectedDateRange?.startDate.toISOString().split('T')[0];
        // const formattedEndDate = selectedDateRange?.endDate.toISOString().split('T')[0];
        applyClick(
          date,
          endDate,
          '',
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means the effect runs once after the initial render

  const removeDuplicatesFromArray = (obj: Product[]): Product[] => {
    const uniqueArray: Product[] = [];
    const idTracker: Record<string, boolean> = {}; // Tracks unique ids
    const nameTracker: Record<string, number> = {}; // Tracks the count of each name

    obj.forEach(product => {
      if (!idTracker[product.id]) {
        idTracker[product.id] = true;
        let uniqueName = product.name;
        // Check if the name has been encountered before
        if (nameTracker[product.name]) {
          // If yes, append a number to make it unique
          const count = nameTracker[product.name] + 1;
          uniqueName = `${product.name} (${count})`;
        }
        nameTracker[product.name] = nameTracker[product.name] ? nameTracker[product.name] + 1 : 1;
        uniqueArray.push({ ...product, name: uniqueName });
      }
    });

    return uniqueArray;
  };

  return (
    <Card style={{ border: '2px solid #f0f1f6' }}>
      <S.FiltersGroup display="flex" justifyContent="flex-end">
        <Button onClick={downloadPdf}>Download PDF</Button>
      </S.FiltersGroup>
      <Grid container spacing={0} style={{ paddingBottom: '1.5em', paddingLeft: '1em' }}>
        <Grid item xs={12} lg={12} gap={1} display="flex" justifyContent="flex-start">
          <Grid item xs={4} display="flex" direction="column" justifyContent="flex-end" alignContent="flex-end">
            <AutocompleteField
              disableCloseOnSelect
              sx={{
                '.MuiInputBase-inputAdornedEnd': {
                  display: value?.length ? 'none' : 'inline',
                },
              }}
              multiple
              fullWidth
              name="products"
              label="Product"
              options={removeDuplicatesFromArray(productsData)}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              withManageActions
            />
          </Grid>
          <Grid item xs={5} />
          <Grid item lg={2} xs={2} display="flex" direction="column" justifyContent="flex-end" alignContent="flex-end">
            <RangePicker onChange={onChange} />
          </Grid>
          <Grid item lg={1} xs={1} display="flex" direction="column" justifyContent="flex-end">
            <Button onClick={submitWrapper} style={{ paddingBottom: '0' }}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

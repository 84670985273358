import { shopifyApi } from 'api/shopify';
import { AppDispatch } from 'store/store';
import { ExchangeAccessCodeRequest, CreateStoreRequest, GetShopifyEcomDataRequest } from 'types/shopify/shopify';

import { shopifySlice } from './slice';

export const getInstallLink = (storeName: string) => async () => {
  const { data } = await shopifyApi.getInstallLink(storeName);

  return data.result.url;
};

export const createStore = (storeData: CreateStoreRequest) => async () => {
  const { data } = await shopifyApi.createStore(storeData);

  return data.result;
};

export const exchangeAccessCode = (params: ExchangeAccessCodeRequest, code: string) => async () => {
  await shopifyApi.exchangeAccessCode(params, code);
};





//EcommDashboardResponse
export const getShopifyDashBoardData =
  (params: GetShopifyEcomDataRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(shopifySlice.actions.processGetOverviewData());

    try {
      const { data } = await shopifyApi.getDashboardData(params);

      dispatch(shopifySlice.actions.getOverviewDataSuccess(data));
    } catch {
      dispatch(shopifySlice.actions.getOverviewDataFailure());

      onFailure();
    }//
  };


//GetShopifyEcomDataRequest
import { Typography, TableRow, TableCell } from '@mui/material';
import { calculateTotal, CompareTableProgressCell } from 'components/compare-table';
import { FC } from 'react';
import { ShopifLeadTableData } from 'types/marketing-analyzer/marketing-analyzer';
import { formatNumber } from 'utils/numbers';

interface Props {
  currencyLabel:string;
  data: ShopifLeadTableData[] ;
}

export const ShopifyTableTotalRow: FC<Props> = ({currencyLabel, data }) => (
  <TableRow hover>
    <TableCell>
      <Typography noWrap variant="h5">
        Total
      </Typography>
    </TableCell>
    <CompareTableProgressCell
      value={currencyLabel+formatNumber(calculateTotal({ key: 'revenue', data }),0)}
      label={''}
     
    />
    <CompareTableProgressCell
      value={formatNumber(calculateTotal({ key: 'sales', data }))}
      label={''}
    />
    <CompareTableProgressCell
      value={formatNumber(calculateTotal({ key: 'avg_sales_per_week', data }))}
      label={''}
    />
    <CompareTableProgressCell
      value={formatNumber(calculateTotal({ key: 'avg_sales_per_month', data }))}
      label={''}
    />


<CompareTableProgressCell
      value={formatNumber(calculateTotal({ key: 'refunds', data }))}
      label={''}
    />


<CompareTableProgressCell
      value={formatNumber(calculateTotal({ key: 'lifetime', data }))}
      label={''}
    />


  </TableRow>
);

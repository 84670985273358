import { vennFlowApi } from 'api/api';
import { Response } from 'types/api/api';
import { GetRevenueSpendData, RevenueSpendData } from 'types/revenue-spend/revenue-spend';

const getRevenueSpendData = (params: GetRevenueSpendData) =>
  vennFlowApi.get<{ revenueSpend: RevenueSpendData }>('RevenueSpend/revenue-spend', { params });

export const revenueSpendApi = {
  getRevenueSpendData,
};

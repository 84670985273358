import {Card, styled, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const StatisticsGroup = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

const datePicker = styled(DatePicker)`
  
  margin-left:2em;
`;

const StyleTF = {
  padding:'20px',
  width:'95%',
  height:'150px',
};

const exportButton = styled(Button)`
  text-decoration:none;
  font-size: 18px;
  margin-top: 1em;
  padding:1em;
  border-radius:20px;
  background-color: #002339;
  color:#ffffff;
  min-width: 180px;

  &:hover:{
    background-color: #024169 !important;
  }
`;


const InsightHeader = {
  paddingTop:'.25em',
  paddingLeft:'1em',
  paddingBottom:'1em',
  fontSize:'25px'
};

export const dataExportStyles = {
  StatisticsGroup,
  datePicker,
  InsightHeader,
  StyleTF,
  exportButton
};

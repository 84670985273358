import { snapchatApi } from 'api/snapchat';
import { GetSystemAccessIdParams } from 'types/connectors/connectors';
import { SelectedFeatures } from 'types/connectors/facebook-data';

export const getInstallLink = () => async () => {
  const { data } = await snapchatApi.getInstallLink();

  return data.result;
};

export const getSystemAccessId = (params: GetSystemAccessIdParams) => async () => {
  const { data } = await snapchatApi.getSystemAccessId(params);

  return data.result;
};

export const getOrganizations = (systemAccessId: string) => async () => {
  const { data } = await snapchatApi.getOrganizations(systemAccessId);

  return data.result;
};

export const getAdAccount = (systemAccessId: string, organizationId: string) => async () => {
  const { data } = await snapchatApi.getAdAccount(systemAccessId, organizationId);

  return data.result;
};

export const saveSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeatures) =>
  async () => {
    const { data } = await snapchatApi.saveSelection(clientId, clientBrandId, clientBrandStoreId, selectedFeatures);

    return data.result;
  };

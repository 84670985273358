import { Autocomplete, Button, List, TextField } from '@mui/material';
import { useField } from 'formik';
import { useOutsideClick } from 'hooks';
import { ElementType, LegacyRef, forwardRef, useEffect, useRef, useState } from 'react';
import { deleteDuplicateMarkets } from 'utils/delete-duplicate-markets';

interface Props<T> {
  name: string;
  label: string;
  options: T[];
  sx?: object;
  getOptionLabel: (option: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  disableCloseOnSelect?: boolean;
  withManageActions?: boolean;
}

export const AutocompleteField = <T extends object>({
  name,
  options,
  label,
  sx = {},
  getOptionLabel,
  isOptionEqualToValue,
  multiple,
  fullWidth,
  disableCloseOnSelect,
  withManageActions,
}: Props<T>) => {
  const [{ value }, , { setValue }] = useField({ name });
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const dropDownref = useRef(null);
  const autocompleteRef = useRef(null);

  useOutsideClick(dropDownref, () => setDropDownOpen(false), true, autocompleteRef);

  const CustomListboxComponent = forwardRef(function CustomListboxComponent(
    props: any,
    ref: LegacyRef<HTMLDivElement>,
  ) {
    const mappedChildren = props.children.map((item: any) => {
      if (value.some((el: any) => el.name === item.key)) {
        return { ...item, props: { ...item.props, 'aria-selected': true } };
      }

      return { ...item, props: { ...item.props, 'aria-selected': false } };
    });

    const mappedProps = { ...props, children: mappedChildren };

    return (
      <div ref={dropDownref}>
        <div ref={ref}>
          <Button
            size="small"
            sx={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}
            onClick={() => {
              setValue(options);
            }}
            variant="text"
            color="primary"
          >
            Select All
          </Button>
          <Button
            size="small"
            sx={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}
            onClick={() => {
              setValue([]);
            }}
            variant="text"
            color="error"
          >
            Unselect All
          </Button>

          <List sx={{ pt: 0, pb: 0 }} component="nav" aria-label="options" {...mappedProps} />
        </div>
      </div>
    );
  });

  const listboxComponent: ElementType = CustomListboxComponent;

  return (
    <>
      {/* {console.log('render auto comp')} */}
      <Autocomplete
        ref={autocompleteRef}
        open={isDropDownOpen}
        onOpen={() => setDropDownOpen(true)}
        onClose={() => setDropDownOpen(false)}
        disableCloseOnSelect={disableCloseOnSelect}
        ListboxComponent={withManageActions ? listboxComponent : null}
        fullWidth={fullWidth}
        disableClearable
        limitTags={1}
        sx={sx}
        id={name}
        options={options}
        isOptionEqualToValue={isOptionEqualToValue}
        // isOptionEqualToValue={(option, value) => option.id == value.id}
        value={value ?? null}
        onChange={(event: any, newValue) => {
          setValue(Array.isArray(newValue) ? (deleteDuplicateMarkets(newValue) as object) : newValue);
        }}
        multiple={multiple}
        getOptionLabel={getOptionLabel}
        renderInput={params => (
          <TextField
            {...params}
            margin={multiple ? 'none' : 'normal'}
            variant={multiple ? 'outlined' : 'filled'}
            fullWidth
            required
            label={label}
            id={name}
          />
        )}
      />
    </>
  );
};

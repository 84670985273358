import { Response } from 'types/api/api';
import { FeatureData, GetSystemAccessIdParams } from 'types/connectors/connectors';
import { SelectedFeatures } from 'types/connectors/facebook-data';

import { onboardingApi } from '../api';

const getInstallLink = () =>
  onboardingApi.get(`Snapchat/AuthUrl?redirectUri=${process.env.REACT_APP_SNAPCHAT_REDIRECT_URL}`);

const getSystemAccessId = (params: GetSystemAccessIdParams) =>
  onboardingApi.get('Snapchat/ExchangeAccessCodeForAccessToken', { params });

const getOrganizations = (systemAccessId: string) => onboardingApi.get(`Snapchat/GetOrganizations/${systemAccessId}`);

const getAdAccount = (systemAccessId: string, organizationId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(`Snapchat/GetAdAccounts/${systemAccessId}/${organizationId}`);

const saveSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeatures,
) =>
  onboardingApi.post<Response<FeatureData[]>>('Snapchat/AnalyticsFeatures', {
    clientId,
    systemId: '4DF6C924-71CD-474B-8FAC-9AF9C1E2D1D2',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'advertiserId',
        value: selectedFeatures.adAccount?.id,
      },
    ],
  });

export const snapchatApi = {
  getInstallLink,
  getAdAccount,
  getSystemAccessId,
  getOrganizations,
  saveSelection,
};

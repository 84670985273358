import { styled, Box } from '@mui/material';

const SIZE_BUTTON_GROUP_SIZE = 30;

export const SidebarWrapper = styled(Box)`
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 68px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${({ theme }) => theme.colors.alpha.trueWhite[70]};
`;

export const SizeButtonGroup = styled('div')`
  position: absolute;
  z-index: 10;
  top: 30px;
  left: calc(100% - ${SIZE_BUTTON_GROUP_SIZE / 2}px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIZE_BUTTON_GROUP_SIZE}px;
  height: ${SIZE_BUTTON_GROUP_SIZE}px;
  border-radius: 50%;
  box-shadow: 0px 10px 36px 5px rgba(1, 49, 75, 0.05);
  background: #fdfdfe;
  overflow: hidden;
`;

import { Box, useTheme } from '@mui/material';
import { ResponsiveContainer, ComposedChart, Area, Scatter, ZAxis, Tooltip } from 'recharts';
import { Dimensions } from 'types/common/charts';

import { mapAreaScatterData } from './helpers/adapters';
import { CustomTooltip } from '../custom-tooltip/custom-tooltip';


export interface Props<T> {
  data: T[];
  dataKey: string;
  dimensions: Dimensions;
  tooltipBeforeSymbol?: string;
}

export function AreaScatterChart<T extends Record<string, any>>({
  data,
  dimensions,
  dataKey,
  tooltipBeforeSymbol,
}: Props<T>) {
  const readyData = mapAreaScatterData(data, dataKey);
  const theme = useTheme();

  return (
    <Box width={dimensions.width} height={dimensions.height} minHeight={dimensions.height}>
      <ResponsiveContainer width="100%" aspect={0}>
        <ComposedChart data={readyData}>
          <Tooltip
            isAnimationActive={false}
            content={props => <CustomTooltip {...props} tooltipBeforeSymbol={tooltipBeforeSymbol} showDate />}
          />
          <defs>
            <linearGradient id="gradientColorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.colors.warning.main} stopOpacity={1} />
              <stop offset="95%" stopColor={theme.colors.warning.lighter} stopOpacity={0.0} />
            </linearGradient>
          </defs>

          <Area
            type="monotone"
            dataKey={dataKey}
            fill="url(#gradientColorValue)"
            stroke={theme.colors.warning.main}
            strokeWidth={4}
          />

          <ZAxis type="number" range={[75]} />

          <Scatter dataKey="max" fill={theme.colors.success.main} />
          <Scatter dataKey="min" fill={theme.colors.error.main} />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

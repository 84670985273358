import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChannelTableWeekData,
  Country,
  MarketingOnlyPaidMarketingData,
  PaidMarketingData,
} from 'types/paid-marketing-performance/paid-marketing-performance';

import { PaidMarketingSlice } from './types';

const initialState: PaidMarketingSlice = {
  loading: false,
  paidMarketingData: null,
  paidMarketingOnlyData: null,

  channelTableData: null,
  countries: [],
};

export const paidMarketingSlice = createSlice({
  name: 'paidMarketing',
  initialState,
  reducers: {
    processGetData: state => {
      state.loading = true;
    },
    getDataFailure: state => {
      state.loading = false;
    },

    getCountriesSuccess: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
      state.loading = false;
    },
    getPaidMarketingDataSuccess: (state, action: PayloadAction<PaidMarketingData>) => {
      state.paidMarketingData = action.payload;
      state.loading = false;
    },
    getChannelTableDataSuccess: (state, action: PayloadAction<ChannelTableWeekData[]>) => {
      state.channelTableData = action.payload;
      state.loading = false;
    },

    getPaidMarketingOnlyPiadMarketingDataSuccess: (state, action: PayloadAction<MarketingOnlyPaidMarketingData>) => {
      state.paidMarketingOnlyData = action.payload;
      state.loading = false;
    },
  },
});

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, alpha, Stack, lighten, Divider, IconButton, Tooltip, useTheme, Typography } from '@mui/material';
import { AutocompleteField } from 'components/autocomplete-field';
import { useDispatch, useSelector, useToast } from 'hooks';
import { useEffect, useState } from 'react';
import { getCLientloadStateData } from 'store/entities/marketing-loaddata/actions';
import { sidebarSelectors, sidebarSlice } from 'store/entities/sidebar';
import { userSelectors } from 'store/entities/user';

import { ShopName, HeaderButtons } from './Buttons';
import { HeaderDropdown } from './Dropdown/Dropdown';
import { HeaderWrapper } from './styles';
import { HeaderUserbox } from './Userbox/Userbox';
import { getPyramidUserData } from 'store/entities/pyramid-loaddata/actions';

export const Header = () => {
  const theme = useTheme();

  const opened = useSelector(sidebarSelectors.opened);
  const maximized = useSelector(sidebarSelectors.maximized);

  const dispatch = useDispatch();
  const { errorToast } = useToast();

  const toggleSidebarVisibility = () => dispatch(sidebarSlice.actions.toggleVisibility());
  const loaddata = useSelector(state => state.marketingloaddataSlice.loaddata);
  const user = useSelector(userSelectors.user);

  useEffect(() => {
    dispatch(
      getCLientloadStateData(
        {
          //clientId: '7A6776A6-5FDF-4164-A8A1-52BA7CD54733', //user.clientIdData,
          clientId: user.clientId,
        },
        errorToast,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    const handleFailure = () => {
      // Handle the failure (e.g., show an error message)
      console.error('Failed to fetch pyramid user data');
    };

    if (user.externalUserId) {
      localStorage.removeItem('pyramidToken');
      localStorage.removeItem('embedPyramidToken');
      dispatch(getPyramidUserData(user.externalUserId, handleFailure));
    }
  }, [dispatch, user.externalUserId]);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sidebarWidth={maximized ? theme.sidebar.width.maximized : theme.sidebar.width.minimized}
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15,
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1,
              )}`,
      }}
    >
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} alignItems="center" spacing={2}>
        {/* <ShopName /> */}
      </Stack>
      <Box display="flex" alignItems="center">
        {loaddata ? <HeaderDropdown data={loaddata} /> : ''}

        <HeaderButtons />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebarVisibility}>
              {opened ? <CloseTwoToneIcon fontSize="small" /> : <MenuTwoToneIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

import { PaidMarketingState, PaidMarketingONlyOverviewState } from './types';

const paidMarketingData = (state: PaidMarketingState) => state.paidMarketing.paidMarketingData;

const paidMarketingONlyOverviewData = (state: PaidMarketingONlyOverviewState) => state.paidMarketingonlyOverview.paidMarketingOnlyData;

//const paidMarketingData = useSelector(paidMarketingSelectors.paidMarketingData);

const channelTableData = (state: PaidMarketingState) => state.paidMarketing.channelTableData;

const countries = (state: PaidMarketingState) => state.paidMarketing.countries;

const loading = (state: PaidMarketingState) => state.paidMarketing.loading;


//const paidmarketingonlyloading = (state: PaidMarketingONlyOverviewState) => state.paidMarketingonlyOverview.loading;


export const paidMarketingSelectors = {
  paidMarketingData,
  paidMarketingONlyOverviewData,
  channelTableData,
  countries,
  loading,
 // paidmarketingonlyloading
};

import { Market } from 'types/marketing-analyzer/marketing-analyzer';

// [{2}, {3}, {3}] => [{2}]
export const deleteDuplicateMarkets = (markets: Market[]) => {
  const duplicatesValues = markets.reduce((acc, value, index, self) => {
    if (index !== self.findIndex(obj => obj.id === value.id)) {
      return [...acc, value];
    }
    return acc;
  }, []);

  return markets.filter(market => !duplicatesValues.some(duplicate => duplicate.id === market.id));
};

import { shoppingJourneyApi } from 'api/shoping-journey';
import { AppDispatch } from 'store/store';
import { GetShoppingJourneyRequest } from 'types/shopping-journey/shopping-journey';

import { shoppingJourneyFilterSlice, shoppingJourneySlice } from './slice';

export const getShoppingJourney = (params: GetShoppingJourneyRequest) => async (dispatch: AppDispatch) => {
  dispatch(shoppingJourneySlice.actions.processGetShoppingJourney());

  try {
    const { data } = await shoppingJourneyApi.shoppingJourneyGet(params);

    dispatch(shoppingJourneySlice.actions.shoppingJourneySuccess(data));
  } catch {
    dispatch(shoppingJourneySlice.actions.shoppingJourneyFailure());
  }
};



export const shoppingJourneyFilterGet = (params: GetShoppingJourneyRequest) => async (dispatch: AppDispatch) => {
  dispatch(shoppingJourneyFilterSlice.actions.processGetShoppingFilterJourney());

  try {
    const { data } = await shoppingJourneyApi.shoppingJourneyFilterGet(params);

    dispatch(shoppingJourneyFilterSlice.actions.shoppingJourneyFilterSuccess(data));
  } catch {
    dispatch(shoppingJourneyFilterSlice.actions.shoppingJourneyFilterFailure());
  }
};

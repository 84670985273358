import { SuspenseLoader } from 'components/suspense-loader';
import { Suspense, lazy } from 'react';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Fallback page for 404
export const Status404 = Loader(lazy(() => import('../pages/server-status/Status404')));

export const baseRoute = [
  {
    path: '*',
    element: <Status404 />,
  },
];

import { tiktokApi } from 'api/tiktok';
import { SelectedFeatures } from 'types/connectors/facebook-data';
import { GetSystemAccessIdTiktokParams } from 'types/connectors/tiktok';

export const getInstallLink = () => async () => {
  const { data } = await tiktokApi.getInstallLink();

  return data.result;
};

export const getAdAccount = (systemAccessId: string) => async () => {
  const { data } = await tiktokApi.getAdAccount(systemAccessId);

  return data.result.map((d: any) => ({ id: d.id, value: d.name }));
};

export const getSystemAccessId = (params: GetSystemAccessIdTiktokParams) => async () => {
  const { data } = await tiktokApi.getSystemAccessId(params);

  return data.result;
};

export const saveSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeatures) =>
  async () => {
    const { data } = await tiktokApi.saveSelection(clientId, clientBrandId, clientBrandStoreId, selectedFeatures);

    return data.result;
  };

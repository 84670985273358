import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllBrandInfo, Company, User } from 'types/companies/companies';

import { CompanySlice } from './types';

const initialState: CompanySlice = {
  companyData: null,
  companyLoading: true,
  brandLoading: true,
  allBrandData: [],
  users: [],
  usersLoading: true,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    processGetCompanyData: state => {
      state.companyLoading = true;
    },
    companyDataSuccess: (state, action: PayloadAction<Company>) => {
      state.companyData = action.payload;
      state.companyLoading = false;
    },
    companyDataFailure: state => {
      state.companyLoading = false;
    },

    processGetBrandData: state => {
      state.brandLoading = true;
    },
    getBrandDataSuccess: (state, action: PayloadAction<AllBrandInfo[]>) => {
      state.allBrandData = action.payload;
      state.brandLoading = false;
    },
    getBrandDataFailure: state => {
      state.brandLoading = false;
    },

    processUsers: state => {
      state.usersLoading = true;
    },
    getUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
      state.usersLoading = false;
    },
    usersFailure: state => {
      state.usersLoading = false;
    },
  },
});

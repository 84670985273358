import { Card, styled } from '@mui/material';

const StatisticsGroup = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 100%;
  text-align: center;
  padding-bottom: 0;
  padding-top: 1.5rem;
`;

export const statisticsCardStyles = {
  StatisticsGroup,
};

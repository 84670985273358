import { brainApi } from 'api/brain/brain';
import { AppDispatch } from 'store/store';
import { brainInsightsFilter, brainCaller } from 'types/brain/brain';

import { brainPollAssistantSlice, brainAssistantSlice } from './brainAssistantSlice';
import { brainSlice } from './slice';


  
export const getBrainData =
(params: brainInsightsFilter, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(brainSlice.actions.processGetBrainData());

  try {
    const { data } = await brainApi.getInsights(params);

    dispatch(brainSlice.actions.getBrainDataSuccess(data));
  } catch {
    dispatch(brainSlice.actions.getBrainDataFailure());

    onFailure();
  }
};

export const pollBrainAssistant = 
(params: brainCaller, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(brainPollAssistantSlice.actions.startProcess());

  try{
    const { data } = await brainApi.pollAssistant(params);

    dispatch(brainPollAssistantSlice.actions.getPollAssistantDataSuccess(data));

  }
  catch{
    dispatch(brainPollAssistantSlice.actions.getPollAssistantDataFailure());
    onFailure();
  }

};

export const interactWithBrainAssistant = (params: brainCaller, onFailure: () => void) => async (dispatch: AppDispatch) => {
  if(params.Message === '')
    dispatch(brainAssistantSlice.actions.startProcess());

  try{
    const { data } = await brainApi.interactAssistantAction(params);

    dispatch(brainAssistantSlice.actions.getAssistantDataSuccess(data));

  }
  catch{
    dispatch(brainAssistantSlice.actions.getAssistantDataFailure());
    onFailure();
  }

};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pyramid, PyramidMenuItems } from 'types/pyramid/pyramid';

import { PyramidSlice } from './types';
import { stat } from 'fs';

const initialState: PyramidSlice = {
  pyramid: [],
  loading: true,
  defaultSelectedPyramidId: null,
};

export const pyramidSlice = createSlice({
  name: 'pyramid',
  initialState,
  reducers: {
    processGetPyramid: state => {
      state.loading = true;
    },
    getPyramidSuccess: (state, action: PayloadAction<PyramidMenuItems[]>) => {
      state.pyramid = action.payload;

      const defaultSelectedPyramid = state.pyramid.find(pyramid => pyramid.id);
    },
    getPyramidFailure: state => {
      state.loading = false;
    },
  },
});

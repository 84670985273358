import { OverviewState } from './types';

const overviewData = (state: OverviewState) => state.overview.overviewData;

const loading = (state: OverviewState) => state.overview.loading;

export const overviewSelectors = {
  overviewData,
  loading,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  MarketingOnlyCustomerJourney } from 'types/customer-journey-marketing-only/customer-journey-marketing-only';

import {  MarketingOnlyCustomerJourneyOnlySlice } from './types';
import { CustomerJourneyMarketingOnlyFilters } from '../../../types/customer-journey-marketing-only/customer-journey-marketing-only';




const initialState: MarketingOnlyCustomerJourneyOnlySlice = {
  filters: {
    period: 'Week to Date',
    compareTo: 'Last Period',
  },
  marketingcustomerJourneyData: null,
  loading: true,
};





export const marketingOnlyCustomerJourneySlice = createSlice({
  name: 'marketing-only-customer-journey',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<CustomerJourneyMarketingOnlyFilters>) => {
      state.filters = action.payload;
    },
    processGetOverviewData: state => {
      state.loading = true;
    },
    getOverviewDataSuccess: (state, action: PayloadAction<MarketingOnlyCustomerJourney>) => {
      state.marketingcustomerJourneyData = action.payload;
      state.loading = false;
    },
    getOverviewDataFailure: state => {
      state.loading = false;
    },
  },
});

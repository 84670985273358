import { Box, Typography } from '@mui/material';
import { useSelector } from 'hooks';
import { userSelectors } from 'store/entities/user';

import { HeaderNotifications } from './Notifications/Notifications';

export const HeaderButtons = () => {

    return (
    <Box sx={{ mr: 1, mt:1.5 }}>
       <Box sx={{ mx: 0.5 }} component="span">
        {/* <HeaderNotifications /> */}
      </Box> 
    </Box>
  );
};
